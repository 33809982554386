<template>
	<div class="operateClass">
		<div style="position: fixed;right: 6px;top: 6px;z-index: 100;color: #FFFFFF;font-size: 25px;" @click="close"><i class="el-icon-error"></i></div>
		<div class="headerBg"></div>
		<el-row type="flex" justify="space-between" style="color: #FFFFFF;">
			<el-col :span="8">
				<div class="bgBox">
					<div class="titleBg">
						<el-row type="flex" align="middle">
							<div class="titleBox"></div>
							<div class="pl-1">安全风险详解-安全培训</div>
						</el-row>
					</div>
					<el-row type="flex" align="middle" style="margin-top: 8px;">
						<div class="navBg">
							<div>
								<div style="color: #00BFF5;font-size: 26px;font-weight: 500;">{{completeName ? completeName :'无'}}</div>
								<div style="color: #477EDF;padding-top: 10px;">学习情况</div>
							</div>
						</div>
						<div class="navBg">
							<div>
								<div style="color: #00BFF5;font-size: 26px;font-weight: 500;">{{countTime}}分钟</div>
								<div style="color: #477EDF;padding-top: 10px;">学习时长</div>
							</div>
						</div>
					</el-row>
					<div class="bgBoxCont1">
						<div v-for="(item,index) in topicData" :key="index">
							<div style="color: #69F1F6;padding-top: 10px;padding-left: 20px;">本月安全培训【{{item.courseName}}】{{item.isDone == 1 ? '未' : ''}}按时完成学习及考核</div>
							<div v-for="(items,indexs) in item.safeTopicVos" :key="indexs">
								<div style="color: #69F1F6;padding-left: 20px;padding-top: 5px;">本月考核中【{{items.topicName}}】答错次数超过{{items.wrongNum}}次,相关安全知识需要加强</div>
							</div>
						</div>
						<div v-if="topicData.length < 1" class="noData"></div>
					</div>
					<div style="padding-top: 10px;"></div>
				</div>
				<div class="bgBox">
					<div class="titleBg">
						<el-row type="flex" align="middle">
							<div class="titleBox"></div>
							<div class="pl-1">安全风险详解-驾驶行为</div>
						</el-row>
					</div>
					<el-row type="flex" align="middle" justify="center" style="margin: 10px 0;">
						<div class="ziquan">
							<span style="font-size: 30px;">0</span>
							<span>次</span>
						</div>
						<div style="padding-left: 30px;color: #CA42FC;">本月驾驶行为报警 0 次数</div>
					</el-row>
				</div>
				<div class="bgBox">
					<div class="titleBg">
						<el-row type="flex" align="middle">
							<div class="titleBox"></div>
							<div class="pl-1">安全风险详解-违法违规</div>
						</el-row>
					</div>
					<el-row type="flex" align="middle" style="margin: 8px 0;">
						<div class="navBg">
							<div>
								<div style="color: #00BFF5;">
									<span style="font-size: 26px;font-weight: 500;">{{violateCount}}</span>
									<span>次</span>
								</div>
								<div style="color: #477EDF;padding-top: 10px;">本月违法违规次数</div>
							</div>
						</div>
						<div class="navBg">
							<div>
								<div style="color: #00BFF5;">
									<span style="font-size: 26px;font-weight: 500;">{{deductSafeKilometerSum}}</span>
									<span>公里</span>
								</div>
								<div style="color: #477EDF;padding-top: 10px;">本月扣除安全公里数</div>
							</div>
						</div>
					</el-row>
					<el-row type="flex" align="middle" style="text-align: center;background-color: #0E426C;height: 35px;color: #69F1F6;margin-bottom: 5px;">
						<el-col :span="6">时间</el-col>
						<el-col :span="6">类型</el-col>
						<el-col :span="12">事件</el-col>
					</el-row>
					<div class="tableBox" @mouseenter="clearAryTime" @mouseleave="changeArr">
						<div v-if="showNow" v-for="(item,index) in tableData1" :key="index">
							<el-row type="flex" align="middle" style="text-align: center;min-height: 35px;background-color: #0E426C;" v-if="index%2 != 0">
								<el-col :span="6">{{item.violateTime}}</el-col>
								<el-col :span="6">{{item.violateTypeName}}</el-col>
								<el-col :span="12">
									<div style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis;" @mouseover="mouseoverNow(index)">{{item.violateEvent}}</div>
									<div v-if="testShowNum === index" @mouseout="changeArr" class="testShowClass">{{item.violateEvent}}</div>
								</el-col>
							</el-row>
							<el-row type="flex" align="middle" style="text-align: center;min-height: 35px;" v-if="index%2 == 0">
								<el-col :span="6">{{item.violateTime}}</el-col>
								<el-col :span="6">{{item.violateTypeName}}</el-col>
								<el-col :span="12">
									<div style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis;" @mouseover="mouseoverNow(index)">{{item.violateEvent}}</div>
									<div v-if="testShowNum === index" @mouseout="changeArr" class="testShowClass">{{item.violateEvent}}</div>
								</el-col>
							</el-row>
						</div>
						<div v-if="!showNow" v-for="(item,index) in tableData1" :key="index">
							<el-row type="flex" align="middle" style="text-align: center;min-height: 35px;" v-if="index%2 != 0">
								<el-col :span="6">{{item.violateTime}}</el-col>
								<el-col :span="6">{{item.violateTypeName}}</el-col>
								<el-col :span="12">
									<div style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis;" @mouseover="mouseoverNow(index)">{{item.violateEvent}}</div>
									<div v-if="testShowNum === index" @mouseout="changeArr" class="testShowClass">{{item.violateEvent}}</div>
								</el-col>
							</el-row>
							<el-row type="flex" align="middle" style="text-align: center;min-height: 35px;background-color: #0E426C;" v-if="index%2 == 0">
								<el-col :span="6">{{item.violateTime}}</el-col>
								<el-col :span="6">{{item.violateTypeName}}</el-col>
								<el-col :span="12">
									<div style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis;" @mouseover="mouseoverNow(index)">{{item.violateEvent}}</div>
									<div v-if="testShowNum === index" @mouseout="changeArr" class="testShowClass">{{item.violateEvent}}</div>
								</el-col>
							</el-row>
						</div>
						<div v-if="tableData1.length < 1" class="noData"></div>
					</div>
				</div>
			</el-col>
			<el-col :span="8">
				<div class="centTopBox">
					<el-row type="flex" justify="space-between" align="middle" style="padding: 30px 0;">
						<el-col :span="6" style="color: yellow;font-size: 18px;"></el-col>
						<el-col :span="12">
							<span>时间：</span>
							<el-date-picker style="width: 150px;display: inline-block;" v-model="dateTime" type="month" :clearable="false"
							 :picker-options="pickerOptions" @change="changeCondition" placeholder="选择月" value-format="yyyy-MM">
							</el-date-picker>
						</el-col>
						<el-col :span="6"></el-col>
					</el-row>
					<el-row type="flex" justify="space-between" align="middle" style="font-size: 16px;">
						<el-col :span="8">
							<div style="background-color: #1B386C;width: 100px;height: 110px;margin: 0 auto;">
								<div style="font-size: 30px;color: #00BFF5;padding-top: 20px;">{{drivingExperience}}年</div>
								<div style="color: #477EDF;padding-top: 10px;">驾龄</div>
							</div>
						</el-col>
						<el-col :span="8">
							<div v-if="headUrl" class="centTopBoxImg"><img :src="$fileUrl + headUrl"></div>
							<div v-if="!headUrl" class="centTopBoxImg"><img src="../../../../assets/user.png"></div>
							<div style="font-size: 25px;color: #00BFF5;font-weight: 530;padding-top: 20px;">{{realName}}</div>
						</el-col>
						<el-col :span="8">
							<div style="background-color: #1B386C;width: 100px;height: 110px;margin: 0 auto;">
								<div style="font-size: 30px;color: #00BFF5;padding-top: 20px;">{{busDriver}}年</div>
								<div style="color: #477EDF;padding-top: 10px;">公交驾龄</div>
							</div>
						</el-col>
					</el-row>
					<el-row type="flex" justify="space-between" align="middle" style="padding: 40px 0 0;font-size: 16px;">
						<el-col :span="8">
							<span style="color: #477EDF;">部门：</span>
							<span style="color: #00BFF5;font-weight: 600;">{{departName ? departName : '--'}}</span>
						</el-col>
						<el-col :span="8">
							<span style="color: #477EDF;font-size: 16px;">电话：</span>
							<span style="color: #00BFF5;font-weight: 600;">{{phone ? phone : '--'}}</span>
						</el-col>
						<el-col :span="8">
							<span style="color: #477EDF;font-size: 16px;">岗位：</span>
							<span style="color: #00BFF5;font-weight: 600;">{{positionName ? positionName : '--'}}</span>
						</el-col>
					</el-row>
				</div>
				<div class="bgBox1">
					<el-row type="flex" justify="space-between" align="middle" style="font-size: 14px;">
						<el-col :span="18">
							<div class="titleBg">
								<el-row type="flex" align="middle">
									<div class="titleBox"></div>
									<div class="pl-1">信用评价表图</div>
								</el-row>
							</div>
							<div id="main" style="width: 230px;height: 230px;display: block;margin: 0 auto;"></div>
							<!-- <div style="text-align: center;color: #00FCFF;">奖惩情况：{{punishments}}分</div> -->
						</el-col>
						<el-col :span="8">
							<div class="navBg1">
								<div>
									<div style="color: #00BFF5;font-size: 24px;font-weight: 500;">{{totalScore}}分</div>
									<div style="color: #477EDF;padding-top: 10px;">总信用评分</div>
								</div>
							</div>
							<div class="navBg1" style="margin: 6px 0;">
								<div>
									<div style="color: #00BFF5;font-size: 24px;font-weight: 500;">{{level}}</div>
									<div style="color: #477EDF;padding-top: 10px;">信用等级</div>
								</div>
							</div>
							<div class="navBg1">
								<div>
									<div style="color: #00BFF5;font-size: 24px;font-weight: 500;">
										<div style="color: yellow;font-size: 18px;" v-if="driverLever == 1">★</div>
										<div style="color: yellow;font-size: 18px;" v-if="driverLever == 2">★★</div>
										<div style="color: yellow;font-size: 18px;" v-if="driverLever == 3">★★★</div>
										<div style="color: yellow;font-size: 18px;" v-if="driverLever == 4">★★★★</div>
										<div style="color: yellow;font-size: 18px;" v-if="driverLever == 5">★★★★★</div>
									</div>
									<div style="color: #477EDF;padding-top: 10px;">星级</div>
								</div>
							</div>
						</el-col>
					</el-row>
				</div>
				<div class="bgBox1">
					<div class="titleBg">
						<el-row type="flex" align="middle">
							<div class="titleBox"></div>
							<div class="pl-1">管理建议</div>
						</el-row>
					</div>
					<div style="padding: 10px;color: #69F1F6;line-height: 18px;">该员工综合信用评价等级为{{level}}级，安全风险{{risk}}</div>
					<div class="bgBoxCont">{{suggest}}</div>
				</div>
			</el-col>
			<el-col :span="8">
				<div class="bgBox2">
					<div class="titleBg">
						<el-row type="flex" align="middle">
							<div class="titleBox"></div>
							<div class="pl-1">安全风险详解-状态测评</div>
						</el-row>
					</div>
					<div class="rowFlex" style="width: 100%;background-color: rgba(55,162,216,0.2);height: 40px;margin-top: 5px;">
						<div style="color: #477EDF;font-size: 16px;width: 30%;padding-left: 20px;">本月测试版块</div>
						<div style="color: #00BFF5;font-size: 28px;">{{mentalTypeName}}</div>
					</div>
					<div class="rowFlex" style="width: 100%;background-color: rgba(55,162,216,0.2);height: 40px;margin-top: 5px;">
						<div style="color: #477EDF;font-size: 16px;width: 20%;padding-left: 20px;">测试结论</div>
						<div style="color: #F0BB2B;font-size: 16px;font-weight: 550;">{{feedbackResults}}</div>
					</div>
					<div class="rowFlex" style="width: 100%;margin-top: 5px;">
						<div style="width: 20%;text-align: center;color: #477EDF;">详解</div>
						<div class="bgBoxCont1" style="width: 78%;">
							<div v-for="(item, index) in tableData2" :key="index">
								<div style="color: #FFFFFF;line-height: 24px;">
									<span style="color: #F0BB2B;">【{{item.dimensionName}}】</span>
									{{item.feedbackScheme}}
								</div>
							</div>
							<div v-if="tableData2.length < 1" class="noData"></div>
						</div>
					</div>
					<div style="padding-top: 10px;"></div>
				</div>
				<div class="bgBox2">
					<div class="titleBg">
						<el-row type="flex" align="middle">
							<div class="titleBox"></div>
							<div class="pl-1">安全风险详解-服务质量</div>
						</el-row>
					</div>
					<el-row type="flex" align="middle" justify="center" style="margin: 20px 0;">
						<div class="huangquan">
							<span style="font-size: 30px;">{{complaintCount}}</span>
							<span>次</span>
						</div>
						<div style="padding-left: 30px;color: #F0DC57;">本月服务质量投诉 {{complaintCount}} 次数</div>
					</el-row>
				</div>
				<div class="bgBox2">
					<div class="titleBg">
						<el-row type="flex" align="middle">
							<div class="titleBox"></div>
							<div class="pl-1">安全风险详解-奖惩情况</div>
						</el-row>
					</div>
					<el-row type="flex" align="middle" style="margin: 8px 0;">
						<div class="navBg">
							<div>
								<div style="color: #00BFF5;">
									<span style="font-size: 26px;font-weight: 500;">{{totalPunishment}}</span>
									<span>次</span>
								</div>
								<div style="color: #477EDF;padding-top: 10px;">本月处罚情况</div>
							</div>
						</div>
						<div class="navBg">
							<div>
								<div style="color: #00BFF5;">
									<span style="font-size: 26px;font-weight: 500;">{{totalReward}}</span>
									<span>次</span>
								</div>
								<div style="color: #477EDF;padding-top: 10px;">本月奖励情况</div>
							</div>
						</div>
					</el-row>
					<el-row type="flex" align="middle" style="text-align: center;background-color: #0E426C;height: 35px;color: #69F1F6;margin-bottom: 5px;">
						<el-col :span="6">时间</el-col>
						<el-col :span="6">类型</el-col>
						<el-col :span="12">事件</el-col>
					</el-row>
					<div class="tableBox" @mouseenter="clearAryTime1" @mouseleave="changeArr1">
						<div v-if="showNow1" v-for="(item,index) in tableData3" :key="index">
							<el-row type="flex" align="middle" style="text-align: center;min-height: 35px;background-color: #0E426C;" v-if="index%2 != 0">
								<el-col :span="6">{{item.rewardPunishmentTime}}</el-col>
								<el-col :span="6">{{item.rewardPunishmentTypeName}}</el-col>
								<el-col :span="12">
									<div style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis;" @mouseover="mouseoverNow1(index)">{{item.event}}</div>
									<div v-if="testShowNum1 === index" @mouseout="changeArr1" class="testShowClass">{{item.event}}</div>
								</el-col>
							</el-row>
							<el-row type="flex" align="middle" style="text-align: center;min-height: 35px;" v-if="index%2 == 0">
								<el-col :span="6">{{item.rewardPunishmentTime}}</el-col>
								<el-col :span="6">{{item.rewardPunishmentTypeName}}</el-col>
								<el-col :span="12">
									<div style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis;" @mouseover="mouseoverNow1(index)">{{item.event}}</div>
									<div v-if="testShowNum1 === index" @mouseout="changeArr1" class="testShowClass">{{item.event}}</div>
								</el-col>
							</el-row>
						</div>
						<div v-if="!showNow1" v-for="(item,index) in tableData3" :key="index">
							<el-row type="flex" align="middle" style="text-align: center;min-height: 35px;" v-if="index%2 != 0">
								<el-col :span="6">{{item.rewardPunishmentTime}}</el-col>
								<el-col :span="6">{{item.rewardPunishmentTypeName}}</el-col>
								<el-col :span="12">
									<div style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis;" @mouseover="mouseoverNow1(index)">{{item.event}}</div>
									<div v-if="testShowNum1 === index" @mouseout="changeArr1" class="testShowClass">{{item.event}}</div>
								</el-col>
							</el-row>
							<el-row type="flex" align="middle" style="text-align: center;min-height: 35px;background-color: #0E426C;" v-if="index%2 == 0">
								<el-col :span="6">{{item.rewardPunishmentTime}}</el-col>
								<el-col :span="6">{{item.rewardPunishmentTypeName}}</el-col>
								<el-col :span="12">
									<div style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis;" @mouseover="mouseoverNow1(index)">{{item.event}}</div>
									<div v-if="testShowNum1 === index" @mouseout="changeArr1" class="testShowClass">{{item.event}}</div>
								</el-col>
							</el-row>
						</div>
						<div v-if="tableData3.length < 1" class="noData"></div>
					</div>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	const echarts = require('echarts/lib/echarts');
	require('echarts/lib/chart/radar');
	require('echarts/lib/component/tooltip');
	require('echarts/lib/component/title');
	require('echarts/lib/component/dataZoom');
	import {
		company,
	} from '@/api'
	import {
		getYear,
		getMonth
	} from '@/utils/getDate'
	export default {
		name: 'Operate',
		props: {
			row: {
				type: Object,
				default: () => {}
			}
		},
		data() {
			return {
				pickerOptions: {
					disabledDate(v) {
						return v.getTime() > new Date().getTime() - 86400000;
					}
				},
				dateTime: "",
				tableData3: [],
				tableData2: [],
				tableData1: [],
				tableData: [],
				topicData: [],
				level: '',
				risk: '',
				totalScore: '0',
				suggest: '',
				busDriver: '0',
				departName: '',
				driverLever: '',
				drivingExperience: '0',
				phone: '',
				headUrl: '',
				positionName: '',
				realName: '',
				complaintCount: '0',
				testShowNum: null,
				testShowNum1: null,
				totalPunishment: '0',
				totalReward: '0',
				complete: '',
				countTime: '0',
				completeName: '',
				punishments: '0',
				aryTime: null,
				showNow: true,
				aryTime1: null,
				showNow1: true,
				feedbackResults: '',
				mentalTypeName: '',
				deductSafeKilometerSum: '0',
				violateCount: '0',
			}
		},
		created() {
			const year = getYear();
			const month = getMonth();
			if (month > 0) {
			    this.dateTime = year + "-" + month;
			} else {
			    this.dateTime = year - 1 + "-12";
			}
		},
		mounted() {
			this.singleCreditEvaluate();
			this.getViolate();
			this.getCredit();
			this.getSafeTrainingTopic();
			this.getMonthlyMental();
			this.getCount();
			this.pageSingleMonthRewardPunishment();
		},
		methods: {
			mouseoverNow(num) {
				this.testShowNum = num;
			},
			mouseoverNow1(num) {
				this.testShowNum1 = num;
			},
			singleCreditEvaluate() {
				let listName = [];
				let listData = [];
				let that_ = this;
				this.$axiosReq('/archives/server/escort/web/creditEvaluate/singleCreditEvaluate', null, {
					userId: this.row.userId,
					date: this.dateTime,
				}, 'get').then(res => {
					this.totalScore = res.data.totalScore ? res.data.totalScore : '0';
					this.level = res.data.level ? res.data.level : '';
					this.risk = res.data.risk ? res.data.risk : '';
					this.suggest = res.data.suggest ? res.data.suggest : '';
					if (res.data.singleResult && res.data.singleResult.length > 0) {
						this.tableData = res.data.singleResult;
						this.tableData.forEach(item => {
							if (item.itemName == '安全培训' || item.itemName == '状态测评') {
								listName.push({
									name: item.itemName,
									max: 10
								});
							} else {
								listName.push({
									name: item.itemName,
									max: 20
								});
							}
							listData.push(item.score);
						})
						this.aaaaa(listName, listData)
					} else {
						this.aaaaa([], [])
					}
				})
			},
			getViolate() {
				this.$axiosReq('/archives/server/escort/web/violate/credit', null, {
					userId: this.row.userId,
					startMonth: this.dateTime,
					endMonth: this.dateTime,
					pageSize: 1000
				}, 'get').then(res => {
					this.deductSafeKilometerSum = res.data.deductSafeKilometerSum ? res.data.deductSafeKilometerSum : '0';
					this.violateCount = res.data.violateCount ? res.data.violateCount : '0';
					if (res.data.pageVo && res.data.pageVo.content && res.data.pageVo.content.length > 0) {
						this.tableData1 = res.data.pageVo.content;
						if (this.tableData1.length > 3) {
							this.changeArr();
						}
					}
				})
			},
			getCredit() {
				this.$axiosReq('/archives/server/escort/web/archives/credit/' + this.row.userId, null, null, 'get').then(res => {
					this.busDriver = res.data.busDriver ? res.data.busDriver : '0';
					this.departName = res.data.departName ? res.data.departName : '';
					this.driverLever = res.data.driverLever ? res.data.driverLever : '';
					this.drivingExperience = res.data.drivingExperience ? res.data.drivingExperience : '0';
					this.phone = res.data.phone ? res.data.phone : '';
					this.realName = res.data.realName ? res.data.realName : '';
					this.headUrl = res.data.headUrl ? res.data.headUrl : '';
					this.positionName = res.data.positionName ? res.data.positionName : '';
				})
			},
			getSafeTrainingTopic() {
				this.$axiosReq('/course/server/escort/web/courseUser/safeTrainingTopic', null, {
					userId: this.row.userId,
					queryDate: this.dateTime,
				}, 'get').then(res => {
					// console.log(JSON.stringify(res))
					this.complete = res.data.complete;
					this.completeName = res.data.completeName;
					this.countTime = res.data.countTime ? res.data.countTime : '0';
					if (res.data.wrongTopicVos && res.data.wrongTopicVos.length > 0) {
						this.topicData = res.data.wrongTopicVos;
					}
				})
			},
			pageSingleMonthRewardPunishment() {
				let that_ = this;
				this.$axiosReq('/archives/server/escort/web/rewardPunishment/pageSingleMonthRewardPunishment', null, {
					userId: this.row.userId,
					date: this.dateTime,
					pageSize: 1000
				}, 'get').then(res => {
					// console.log(JSON.stringify(res))
					this.totalPunishment = res.data.totalPunishment ? res.data.totalPunishment : '0';
					this.totalReward = res.data.totalReward ? res.data.totalReward : '0';
					if (res.data.baseRewardPunishments && res.data.baseRewardPunishments.content.length > 0) {
						this.tableData3 = res.data.baseRewardPunishments.content;
						if (that_.tableData3.length > 3) {
							that_.changeArr1();
						}
					}
				})
			},
			getMonthlyMental() {
				this.$axiosReq('/course/server/escort/web/mentalUser/getMonthlyMental', null, {
					userId: this.row.userId,
					startMonth: this.dateTime,
					endMonth: this.dateTime,
				}, 'get').then(res => {
					if (res.data && res.data.length > 0) {
						this.feedbackResults = res.data[0].feedbackResults;
						this.mentalTypeName = res.data[0].mentalTypeName;
						this.tableData2 = res.data[0].creditMentalDetailVos ? res.data[0].creditMentalDetailVos : [];
					}
				})
			},
			getCount() {
				this.$axiosReq('/archives/server/escort/web/serviceQuality/getCount', null, {
					userId: this.row.userId,
					startMonth: this.dateTime,
					endMonth: this.dateTime,
				}, 'get').then(res => {
					this.complaintCount = res.data.complaintCount ? res.data.complaintCount : '0';
				})
			},
			changeCondition(v) {
				this.tableData = [];
				this.tableData2 = [];
				this.tableData1 = [];
				this.topicData = [];
				this.singleCreditEvaluate();
				this.getViolate();
				this.getCredit();
				this.getSafeTrainingTopic();
				this.getMonthlyMental();
				this.getCount();
				this.pageSingleMonthRewardPunishment();
			},
			// 关闭模态框
			close() {
				let that = this;
				clearTimeout(that.aryTime);
				that.aryTime = null;
				clearTimeout(that.aryTime1);
				that.aryTime1 = null;
				this.$emit('refresh');
			},
			aaaaa(name, list) {
				var chartDom = document.getElementById('main');
				var myChart = echarts.init(chartDom);
				var option;

				option = {
					tooltip: {
						confine: true,
						enterable: true,
					},
					radar: {
						indicator: name,
						radius: 56,
					},
					series: [{
						type: 'radar',
						symbol: 'circle',
						symbolSize: 8,
						symbolRotate: 0,
						symbolOffset: [0, 0],
						cursor: "pointer",
						areaStyle: {
							normal: {
								color: '#0A68A6',
								opacity: 0.7,
							},
						},
						lineStyle: {
							width: 3,
							color: "#00E7FF"
						},
						data: [{
							value: list,
							name: "信用评价",
							itemStyle: {
								normal: {
									color: '#00E7FF',
								}
							},

						}]
					}]
				};

				option && myChart.setOption(option);
			},
			changeArr() {
				let that = this;
				that.testShowNum = null;
				if (that.tableData1.length < 3) {
					return false
				}
				that.showNow = !that.showNow;
				that.tableData1.push(
					that.tableData1.shift()
				);
				// console.log(that.record.bridgeWarnMessageVos);
				that.aryTime = setTimeout(() => {
					that.changeArr();
				}, 2000);
			},
			clearAryTime() {
				let that = this;
				clearTimeout(that.aryTime);
				that.aryTime = null;
			},
			changeArr1() {
				let that = this;
				that.testShowNum1 = null;
				if (that.tableData3.length < 3) {
					return false
				}
				that.showNow1 = !that.showNow1;
				that.tableData3.push(
					that.tableData3.shift()
				);
				// console.log(that.record.bridgeWarnMessageVos);
				that.aryTime1 = setTimeout(() => {
					that.changeArr1();
				}, 2000);
			},
			clearAryTime1() {
				let that = this;
				clearTimeout(that.aryTime1);
				that.aryTime1 = null;
			},
		}
	}
</script>

<style lang="scss" scoped>
	.bgBox {
		width: 95%;
		background-image: url(./allBg.png);
		background-size: 100% 100%;
		display: block;
		margin-left: 5%;
		padding: 10px;
		margin-bottom: 15px;
	}

	.bgBox1 {
		width: 95%;
		background-image: url(./allBg.png);
		background-size: 100% 100%;
		display: block;
		padding: 10px;
		margin: 15px auto 0;
	}

	.bgBox2 {
		width: 95%;
		background-image: url(./allBg.png);
		background-size: 100% 100%;
		display: block;
		margin-right: 5%;
		padding: 10px;
		margin-bottom: 15px;
	}

	.operateClass {
		width: 100vw;
		height: 100vh;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 99;
		background-image: linear-gradient(#18265c, #0c1d3d);
		font-size: 14px;
		overflow-y: scroll;
		overflow-x: hidden;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
	}

	.operateClass::-webkit-scrollbar {
		display: none;
	}

	.headerBg {
		width: 97%;
		height: 60px;
		margin: 20px auto;
		box-sizing: border-box;
		background-image: url(./header.png);
		background-size: 100% 100%;
	}

	.titleBg {
		min-width: 100px;
		height: 25px;
		background-color: rgba(105, 246, 175, 0.12);
		padding: 0 25px 0 15px;
		border-radius: 10px 0 20px 0;
		line-height: 25px;
		font-size: 15px;
		font-weight: 600;
		display: inline-block;
	}

	.titleBox {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: #00FF33;
	}

	.centTopBox {
		width: 95%;
		height: 360px;
		background-image: url(./user.png);
		background-size: 100% 100%;
		text-align: center;
		margin: 0 auto;
	}

	.centTopBoxImg {
		width: 110px;
		height: 110px;
		border-radius: 50%;
		margin: auto;
	}

	.centTopBoxImg img {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		display: block;
	}

	.tableBox {
		height: 128px;
		overflow-y: scroll;
		overflow-x: hidden;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
	}

	.tableBox::-webkit-scrollbar {
		display: none;
	}

	.tableBox1 {
		height: 317px;
		overflow-y: scroll;
		overflow-x: hidden;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
	}

	.tableBox1::-webkit-scrollbar {
		display: none;
	}

	.huangquan {
		width: 128px;
		height: 120px;
		background-image: url(./huangquan.png);
		background-size: 100% 100%;
		line-height: 120px;
		text-align: center;
		color: #F0DC57;
	}

	.ziquan {
		width: 128px;
		height: 120px;
		background-image: url(./ziquan.png);
		background-size: 100% 100%;
		line-height: 120px;
		text-align: center;
		color: #CA42FC;
	}

	.bgBoxCont {
		padding: 0 10px;
		color: #69F1F6;
		line-height: 18px;
		height: 80px;
		overflow-y: scroll;
		overflow-x: hidden;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
	}

	.bgBoxCont::-webkit-scrollbar {
		display: none;
	}

	.bgBoxCont1 {
		padding: 0 10px;
		color: #69F1F6;
		line-height: 18px;
		height: 130px;
		overflow-y: scroll;
		overflow-x: hidden;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
	}

	.bgBoxCont1::-webkit-scrollbar {
		display: none;
	}

	.testShowClass {
		position: absolute;
		left: 0;
		top: 0;
		background-color: #096A94;
		min-height: 35px;
		line-height: 35px;
		width: 100%;
		font-size: 10px;
		z-index: 999;
	}

	.navBg {
		width: 262px;
		height: 95px;
		background-image: url(./navBg.png);
		background-size: 100% 100%;
		margin-right: 10px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		text-align: center;
	}

	.navBg1 {
		width: 130px;
		height: 70px;
		background-color: rgba(55, 162, 216, 0.3);
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		text-align: center;
	}

	.rowFlex {
		-ms-flex-direction: row;
		flex-direction: row;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.noData {
		width: 200px;
		height: 130px;
		background-image: url(./noData.png);
		background-size: 100% 100%;
		margin: 0 auto;
	}
</style>
