<template>
    <div class="operateClassN">
        <div class="headerT">状态测评</div>
        <div class="rowCent rowCentRl mgt-10">
            <div style="width: 49%">
                <div class="rowCent rowCentRl">
                    <div class="navList">
                        <div>测评参与次数</div>
                        <div
                            style="
                                border-top: 2px solid rgba(255, 255, 255, 0.2);
                                margin-top: 20px;
                            "
                        ></div>
                        <div style="height: 190px" class="rowCent rowJCent">
                            <div style="position: relative">
                                <el-progress
                                    type="circle"
                                    :percentage="
                                        list.mentalNum ? list.mentalNum : 0
                                    "
                                    :stroke-width="12"
                                    color="#F9AC27"
                                    :show-text="false"
                                ></el-progress>
                                <div class="positionText">
                                    {{ list.mentalNum ? list.mentalNum : 0 }}次
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="navList">
                        <div>参与测评板块</div>
                        <div
                            style="
                                border-top: 2px solid rgba(255, 255, 255, 0.2);
                                margin-top: 20px;
                            "
                        ></div>
                        <div style="height: 190px" class="rowCent rowJCent">
                            <div style="position: relative">
                                <el-progress
                                    type="circle"
                                    :percentage="
                                        list.mentalTypeNum
                                            ? list.mentalTypeNum
                                            : 0
                                    "
                                    :stroke-width="12"
                                    color="#622AF2"
                                    :show-text="false"
                                ></el-progress>
                                <div class="positionText">
                                    {{
                                        list.mentalTypeNum
                                            ? list.mentalTypeNum
                                            : 0
                                    }}类
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="navList">
                        <div class="rowCent rowCentRl">
                            <div>测评月均参与率</div>
                            <div>
                                排名：<span class="col-00E0FD">{{
                                    list.ranking
                                }}</span>
								<span>/{{
								    list.rankingTotal
								}}</span>
                            </div>
                        </div>
                        <div
                            style="
                                border-top: 2px solid rgba(255, 255, 255, 0.2);
                                margin-top: 20px;
                            "
                        ></div>
                        <div style="height: 190px" class="rowCent rowCentRl">
                            <div style="position: relative">
                                <el-progress
                                    type="circle"
                                    :percentage="
                                        list.mentalCompletionRate
                                            ? list.mentalCompletionRate
                                            : 0
                                    "
                                    :stroke-width="12"
                                    color="#984FF8"
                                    :show-text="false"
                                ></el-progress>
                                <div class="positionText">
                                    {{
                                        list.mentalCompletionRate
                                            ? list.mentalCompletionRate
                                            : 0
                                    }}%
                                </div>
                            </div>
                            <div>
                                <div class="rowCent">
                                    <div>月环比</div>
                                    <div
                                        v-if="!list.monthlyMom"
                                        style="padding: 0 10px"
                                    >
                                        {{
                                            list.monthlyMom === 0 ? "0%" : "--"
                                        }}
                                    </div>
                                    <div
                                        v-if="list.monthlyMom > 0"
                                        style="color: #15dd8f"
                                    >
                                        <i
                                            class="el-icon-caret-top"
                                            style="padding: 0 5px"
                                        ></i
                                        >{{ list.monthlyMom }}%
                                    </div>
                                    <div
                                        v-if="list.monthlyMom < 0"
                                        style="color: #f7333d"
                                    >
                                        <i
                                            class="el-icon-caret-bottom"
                                            style="padding: 0 5px"
                                        ></i
                                        >{{ list.monthlyMom }}%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="leftD mgt-10">
                    <div class="titleBg">
                        <el-row type="flex" align="middle">
                            <div class="titleBox"></div>
                            <div class="pl-1">测评参与率走势</div>
                        </el-row>
                    </div>
                    <div
                        id="main10"
                        style="
                            width: 800px;
                            height: 400px;
                            display: block;
                            margin: 0 auto;
                        "
                    ></div>
                </div>
            </div>
            <div style="width: 50.4%">
                <div class="rightT">
                    <div class="titleBg">
                        <el-row type="flex" align="middle">
                            <div class="titleBox"></div>
                            <div class="pl-1">测评结果概述</div>
                        </el-row>
                    </div>
                    <div class="textOver col-00E0FD">
                        {{ dateTime }}年{{ row.departName }}共参与
                        <span style="font-size: 20px">{{
                            list.mentalNum ? list.mentalNum : 0
                        }}</span>
                        次测评，其中参与
                        <span
                            style="font-size: 20px"
                            v-for="(item, index) in mentalTypeCheckNumVos"
                            :key="index"
                        >
                            {{ item.mentalTypeName
                            }}{{
                                item.mentalTypeNum ? item.mentalTypeNum : 0
                            }}次
                        </span>
                        。在测评中，共有
                        <span style="font-size: 20px">{{
                            list.mentalFocusDriverNum
                                ? list.mentalFocusDriverNum
                                : 0
                        }}</span>
                        名驾驶员
                        <span style="font-size: 20px">至少有3次</span>
                        测试维度具有负面倾向，建议加强相关方面的了解。
                    </div>
                </div>
                <div class="rightD mgt-10">
                    <div class="titleBg">
                        <el-row type="flex" align="middle">
                            <div class="titleBox"></div>
                            <div class="pl-1">重点关注名单</div>
                        </el-row>
                    </div>
                    <div class="tableClass">
                        <div class="rowCent w100Bg mgt-10">
                            <div class="w30">姓名</div>
                            <div class="w30">电话</div>
                            <div class="w70">负面倾向维度</div>
                        </div>
                        <div class="tableOver">
                            <div
                                class="rowCent w100"
                                v-for="(item, index) in table.list"
                                :key="index"
                            >
                                <div class="w30">{{ item.userName }}</div>
                                <div class="w30">{{ item.phone }}</div>
                                <div class="w70">
                                    {{ item.negativeMentalType }}
                                </div>
                            </div>
                            <div
                                v-if="table.list.length < 1"
                                class="noData"
                            ></div>
                        </div>
                        <div style="height: 20px" />
                        <el-pagination
                            :current-page="table.currentPage"
                            :page-sizes="[5, 10, 15, 20, 50, 100]"
                            :page-size="table.pageSize"
                            layout="total, ->, prev, pager, next"
                            :total="table.total"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const echarts = require("echarts/lib/echarts");
require("echarts/lib/chart/pie");
require("echarts/lib/component/tooltip");
require("echarts/lib/component/title");
require("echarts/lib/component/dataZoom");
export default {
    name: "Operate",
    props: {
        row: {
            type: Object,
            default: () => {},
        },
        info: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            loading: false,
            dateTime: "",
            table: {
                list: [],
                total: 0,
                currentPage: 1,
                pageSize: 10,
            },
            list: {},
            mentalTypeCheckNumVos: [],
        };
    },
    created() {},
    mounted() {},
    methods: {
        handleParentClick(v) {
            this.dateTime = v;
            this.table.currentPage = 1;
            this.table.total = 0;
            this.table.list = [];
            this.mentalTypeCheckNumVos = [];
            this.yearMentalBaseInfo();
            this.yearMentalFocusDriver();
            this.yearMentalTrendChart();
        },
		yearMentalBaseInfo() {
		    let that_ = this;
		    this.$axiosReq(
		        "/course/server/escort/web/lineCreditMentalUser/yearMentalBaseInfo",
		        null,
		        {
		            date: this.dateTime + '-01',
		            companyId: this.info.companyId,
		            lineId: this.row.lineId,
		        },
		        "get"
		    ).then((res) => {
		        that_.list = res.data;
				if (
				    res.data.mentalTypeCheckNumVos &&
				    res.data.mentalTypeCheckNumVos.length > 0
				) {
				    that_.mentalTypeCheckNumVos =
				        res.data.mentalTypeCheckNumVos;
				}
		    });
		},
		yearMentalFocusDriver() {
		    let that_ = this;
		    this.$axiosReq(
		        "/course/server/escort/web/lineCreditMentalUser/yearMentalFocusDriver",
		        null,
		        {
		            pageSize: this.table.pageSize,
		            currentPage: this.table.currentPage,
					date: this.dateTime + '-01',
		            companyId: this.info.companyId,
		            lineId: this.row.lineId,
		        },
		        "get"
		    ).then((res) => {
		        if (
		            res.data.content &&
		            res.data.content.length > 0
		        ) {
		            that_.table.list = res.data.content;
		            that_.table.total = Number(
		                res.data.totalElements
		            );
		        }
		    });
		},
        yearMentalTrendChart() {
            let that_ = this;
            let arr = [];
            let arr1 = [];
            this.$axiosReq(
                "/course/server/escort/web/lineCreditMentalUser/yearMentalTrendChart",
                null,
                {
                    date: this.dateTime + '-01',
                    companyId: this.info.companyId,
                    lineId: this.row.lineId,
                },
                "get"
            ).then((res) => {
                // console.log(JSON.stringify(res))
                if (
                    res.data &&
                    res.data.length > 0
                ) {
                    res.data.forEach(function (item) {
                        arr.push(item.mentalCompletionRate);
                        arr1.push(item.month);
                    });
                    that_.echartsM(arr, arr1);
                } else {
                    that_.echartsM(arr, arr1);
                }
            });
        },
        handleSizeChange(pageSize) {
            this.table.pageSize = pageSize;
            this.table.currentPage = 1;
            this.yearMentalFocusDriver();
        },
        handleCurrentChange(currentPage) {
            this.table.currentPage = currentPage;
            this.yearMentalFocusDriver();
        },
        echartsM(list, name) {
            var chartDom = document.getElementById("main10");
            var myChart = echarts.init(chartDom);
            var option;

            option = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        crossStyle: {
                            color: "#fff",
                        },
                    },
                    formatter: function (params) {
                        let str =
                            params[0].name +
                            "<br />" +
                            params[0].marker +
                            params[0].value +
                            "%";
                        return str;
                    },
                },
                xAxis: {
                    name: "日期",
                    type: "category",
                    nameTextStyle: {
                        color: "#ffffff",
                    },
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: "#ffffff",
                        },
                    },
                    axisLine: {
                        lineStyle: {
                            color: "rgba(255,255,255,0.5)",
                        },
                    },
                    data: name,
                },
                yAxis: {
                    name: "百分比",
                    type: "value",
                    nameTextStyle: {
                        color: "#ffffff",
                    },
                    axisLine: {
                        lineStyle: {
                            color: "rgba(255,255,255,0.5)",
                        },
                    },
                    axisLabel: {
                    	textStyle: {
                    		color: "#ffffff",
                    	},
                    	formatter: '{value}%'
                    },
                },
                series: [
                    {
                        data: list,
                        type: "line",
                        stack: "Total",
                        smooth: true,
                        lineStyle: {
                            width: 5,
                            color: new echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [
                                    {
                                        offset: 0,
                                        color: "#0092EB",
                                    },
                                    {
                                        offset: 1,
                                        color: "#00E8FF",
                                    },
                                ]
                            ),
                        },
                        areaStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        {
                                            offset: 0,
                                            color: "#0092EB",
                                        },
                                        {
                                            offset: 1,
                                            color: "#00E8FF",
                                        },
                                    ]
                                ),
                            },
                        },
                        itemStyle: {
                            normal: {
                                label: {
                                    show: true,
                                },
                                color: "#ffffff",
                            },
                        },
                    },
                ],
            };

            option && myChart.setOption(option);
        },
    },
};
</script>

<style scoped>
.col-00E0FD {
    color: #00e0fd;
}

.col-42F3F9 {
    color: #42f3f9;
}

.operateClassN {
    width: 100%;
    height: 100%;
}

.headerT {
    color: #42f3f9;
    font-size: 20px;
    padding-left: 10px;
    border-left: 3px solid #42f3f9;
    margin-top: 10px;
}

.mgt-10 {
    margin-top: 10px;
}

.rowCent {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    box-sizing: border-box;
}

.rowCentRl {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.rowJCent {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.titleBg {
    min-width: 100px;
    height: 25px;
    background-color: rgba(105, 246, 175, 0.12);
    padding: 0 25px 0 15px;
    border-radius: 10px 0 20px 0;
    line-height: 25px;
    font-size: 15px;
    font-weight: 600;
    display: inline-block;
}

.titleBox {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #00ff33;
}

.textOver {
    height: 150px;
    padding-top: 10px;
    line-height: 25px;
    letter-spacing: 3px;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    box-sizing: border-box;
}

.textOver::-webkit-scrollbar {
    display: none;
}

.navList {
    width: 32.5%;
    height: 275px;
    background-image: url(../bgAll.png);
    background-size: 100% 100%;
    padding: 20px;
    box-sizing: border-box;
}

.positionText {
    position: absolute;
    left: 0;
    top: 49px;
    width: 100%;
    text-align: center;
    font-size: 22px;
}

.positionText1 {
    position: absolute;
    left: 0;
    top: 40px;
    width: 100%;
    text-align: center;
    font-size: 22px;
}

.timeBg {
    width: 59px;
    height: 59px;
    background-image: url(../timeBgNav.png);
    background-size: 100% 100%;
    margin-right: 30px;
}

.leftD {
    width: 100%;
    height: 500px;
    background-image: url(../bgAll.png);
    background-size: 100% 100%;
    padding: 20px;
    box-sizing: border-box;
}

.rightT {
    width: 100%;
    height: 210px;
    background-image: url(../bgAll.png);
    background-size: 100% 100%;
    padding: 20px;
    box-sizing: border-box;
}

.rightD {
    width: 100%;
    height: 565px;
    background-image: url(../bgAll.png);
    background-size: 100% 100%;
    padding: 20px;
    box-sizing: border-box;
}

.w100 {
    width: 100%;
    text-align: center;
    height: 35px;
}

.w100Bg {
    width: 100%;
    text-align: center;
    height: 35px;
    background-color: rgba(0, 232, 255, 0.08);
}

.w70 {
    width: 60%;
    height: 35px;
    line-height: 35px;
}

.w30 {
    width: 20%;
    height: 35px;
    line-height: 35px;
}

.tableOver {
    width: 100%;
    height: 360px;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    box-sizing: border-box;
}

.tableOver::-webkit-scrollbar {
    display: none;
}

.noData {
    width: 200px;
    height: 130px;
    background-image: url(../noData.png);
    background-size: 100% 100%;
    margin: 0 auto;
}
</style>
