<template>
	<div class="operateClassN">
		<div class="headerT">违法违规</div>
		<div class="rowCent rowjaround mgt-10" style="height: 210px;">
			<div class="navList rowCent rowCentRl">
				<div style="width: 50%;">
					<div class="col-00E0FD" style="font-size: 30px;">{{list.violateNum ? list.violateNum : '0'}}次</div>
					<div class="mt-1">本月违法违规次数</div>
				</div>
				<div style="background-color: rgba(255,255,255,0.1);width: 1px;height: 76px;"></div>
				<div>
					<div class="rowCent">
						<div>月同比</div>
						<div v-if="!list.violateNumYoy" style="padding: 0 10px;">{{list.violateNumYoy === 0 ? '0%' : '--'}}</div>
						<div v-if="list.violateNumYoy > 0" style="color: #15DD8F;"><i class="el-icon-caret-top" style="padding: 0 5px;"></i>{{list.violateNumYoy}}%</div>
						<div v-if="list.violateNumYoy < 0" style="color: #F7333D;"><i class="el-icon-caret-bottom" style="padding: 0 5px;"></i>{{list.violateNumYoy}}%</div>
					</div>
					<div style="margin-top: 20px;"></div>
					<div class="rowCent">
						<div>月环比</div>
						<div v-if="!list.violateNumMom" style="padding: 0 10px;">{{list.violateNumMom === 0 ? '0%' : '--'}}</div>
						<div v-if="list.violateNumMom > 0" style="color: #15DD8F;"><i class="el-icon-caret-top" style="padding: 0 5px;"></i>{{list.violateNumMom}}%</div>
						<div v-if="list.violateNumMom < 0" style="color: #F7333D;"><i class="el-icon-caret-bottom" style="padding: 0 5px;"></i>{{list.violateNumMom}}%</div>
					</div>
				</div>
			</div>
			<div class="navList rowCent rowCentRl">
				<div style="width: 50%;">
					<div class="col-00E0FD" style="font-size: 30px;">{{list.accidentNum ? list.accidentNum : '0'}}次</div>
					<div class="mt-1">本月发生事故数</div>
				</div>
				<div style="background-color: rgba(255,255,255,0.1);width: 1px;height: 76px;"></div>
				<div>
					<div class="rowCent">
						<div>月同比</div>
						<div v-if="!list.accidentNumYoy" style="padding: 0 10px;">{{list.accidentNumYoy === 0 ? '0%' : '--'}}</div>
						<div v-if="list.accidentNumYoy > 0" style="color: #15DD8F;"><i class="el-icon-caret-top" style="padding: 0 5px;"></i>{{list.accidentNumYoy}}%</div>
						<div v-if="list.accidentNumYoy < 0" style="color: #F7333D;"><i class="el-icon-caret-bottom" style="padding: 0 5px;"></i>{{list.accidentNumYoy}}%</div>
					</div>
					<div style="margin-top: 20px;"></div>
					<div class="rowCent">
						<div>月环比</div>
						<div v-if="!list.accidentNumMom" style="padding: 0 10px;">{{list.accidentNumMom === 0 ? '0%' : '--'}}</div>
						<div v-if="list.accidentNumMom > 0" style="color: #15DD8F;"><i class="el-icon-caret-top" style="padding: 0 5px;"></i>{{list.accidentNumMom}}%</div>
						<div v-if="list.accidentNumMom < 0" style="color: #F7333D;"><i class="el-icon-caret-bottom" style="padding: 0 5px;"></i>{{list.accidentNumMom}}%</div>
					</div>
				</div>
			</div>
			<div class="navList rowCent rowCentRl">
				<div style="width: 50%;">
					<div class="col-00E0FD" style="font-size: 30px;">{{list.violateScore ? list.violateScore : '0'}}分</div>
					<div class="mt-1">共记分</div>
				</div>
				<div style="background-color: rgba(255,255,255,0.1);width: 1px;height: 76px;"></div>
				<div>
					<div class="rowCent">
						<div>月同比</div>
						<div v-if="!list.violateScoreYoy" style="padding: 0 10px;">{{list.violateScoreYoy === 0 ? '0%' : '--'}}</div>
						<div v-if="list.violateScoreYoy > 0" style="color: #15DD8F;"><i class="el-icon-caret-top" style="padding: 0 5px;"></i>{{list.violateScoreYoy}}%</div>
						<div v-if="list.violateScoreYoy < 0" style="color: #F7333D;"><i class="el-icon-caret-bottom" style="padding: 0 5px;"></i>{{list.violateScoreYoy}}%</div>
					</div>
					<div style="margin-top: 20px;"></div>
					<div class="rowCent">
						<div>月环比</div>
						<div v-if="!list.violateScoreMom" style="padding: 0 10px;">{{list.violateScoreMom === 0 ? '0%' : '--'}}</div>
						<div v-if="list.violateScoreMom > 0" style="color: #15DD8F;"><i class="el-icon-caret-top" style="padding: 0 5px;"></i>{{list.violateScoreMom}}%</div>
						<div v-if="list.violateScoreMom < 0" style="color: #F7333D;"><i class="el-icon-caret-bottom" style="padding: 0 5px;"></i>{{list.violateScoreMom}}%</div>
					</div>
				</div>
			</div>
		</div>
		<div class="rowCent rowCentRl mgt-10">
			<div style="width: 59%;" class="follow">
				<div class="titleBg">
					<el-row type="flex" align="middle">
						<div class="titleBox"></div>
						<div class="pl-1">驾驶员违法违规情况</div>
					</el-row>
				</div>
				<div style="width: 1px;height: 15px;"></div>
				<div class="tableClass">
					<div class="rowCent w100Bg mgt-10">
						<div class="w30">时间</div>
						<div class="w30">类型</div>
						<div class="w30">人员</div>
						<div class="w70">事件</div>
					</div>
					<div class="tableOver" @mouseleave="changeArr">
						<div class="rowCent w100" v-for="(item,index) in table.list" :key="index">
							<div class="w30">{{item.violateTime}}</div>
							<div class="w30">{{item.violateType}}</div>
							<div class="w30">{{item.userName}}</div>
							<div class="w70" @mouseover="mouseoverNow(index)">{{item.violateEvent}}</div>
							<div v-if="testShowNum === index" @mouseout="changeArr" class="testShowClass">{{item.violateEvent}}</div>
						</div>
						<div v-if="table.list.length < 1" class="noData"></div>
					</div>
					<el-pagination :current-page="table.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]" :page-size="table.pageSize"
					 layout="total, ->, prev, pager, next" :total="table.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
				</div>
			</div>
			<div style="width: 40%;">
				<div class="grade">
					<div class="titleBg">
						<el-row type="flex" align="middle">
							<div class="titleBox"></div>
							<div class="pl-1">各类事故情况统计</div>
						</el-row>
					</div>
					<div id="main6" style="width: 600px;height: 500px;display: block;margin: 0 auto;"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	const echarts = require('echarts/lib/echarts');
	require('echarts/lib/chart/bar');
	require('echarts/lib/chart/line');
	require('echarts/lib/component/tooltip');
	require('echarts/lib/component/title');
	require('echarts/lib/component/dataZoom');
	require('echarts/lib/component/legend');
	export default {
		name: 'Operate',
		props: {
			row: {
				type: Object,
				default: () => {}
			},
			info: {
				type: Object,
				default: () => {}
			}
		},
		data() {
			return {
				loading: false,
				dateTime: "2021-10",
				table: {
					list: [],
					total: 0,
					currentPage: 1,
					pageSize: 10,
				},
				list: {},
				testShowNum: null,
			}
		},
		created() {},
		mounted() {
			// this.echartsMain('main6');
		},
		methods: {
			handleParentClick(v) {
				this.dateTime = v;
				this.table.currentPage = 1
				this.table.total = 0;
				this.table.list = [];
				this.monthAccidentStatistic();
				this.monthViolateBaseInfo();
				this.monthDriverViolate();
			},
			monthAccidentStatistic() {
			    let that_ = this;
			    let arr1 = [];
			    let arr2 = [];
			    this.$axiosReq(
			        "/archives/server/escort/web/departCreditViolate/monthAccidentStatistic",
			        null,
			        {
			            date: this.dateTime,
			            companyId: this.info.companyId,
			            departId: this.row.departId,
			        },
			        "get"
			    ).then((res) => {
					if (res.data && res.data.length > 0) {
						for (let i = 0; i < res.data.length; i++) {
							arr1.push({
								value: res.data[i].accidentNum,
								itemStyle: {
									normal: {
										color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
											offset: 0,
											color: '#37D1DF'
										}, {
											offset: 0.5,
											color: 'rgba(55,209,223,0.5)'
										}, {
											offset: 1,
											color: 'rgba(55,209,223,0)'
										}]),
									}
								},
								incrementMom: res.data[i].accidentMomIncrement,
								mom: res.data[i].accidentMom ? res.data[i].accidentMom + '%' : '--',
								incrementYoy: res.data[i].accidentYoyIncrement,
								yoy: res.data[i].accidentYoy ? res.data[i].accidentYoy + '%' : '--'
							});
							arr2.push(res.data[i].accidentClass);
						}
					
						this.echartsMain(arr1, arr2);
					} else {
						this.echartsMain([], []);
					}
			    });
			},
			monthViolateBaseInfo() {
			    let that_ = this;
			    this.$axiosReq(
			        "/archives/server/escort/web/departCreditViolate/monthViolateBaseInfo",
			        null,
			        {
			            date: this.dateTime,
			            companyId: this.info.companyId,
			            departId: this.row.departId,
			        },
			        "get"
			    ).then((res) => {
			        that_.list = res.data;
			    });
			},
			monthDriverViolate() {
			    let that_ = this;
			    this.$axiosReq(
			        "/archives/server/escort/web/departCreditViolate/monthDriverViolate",
			        null,
			        {
			            pageSize: this.table.pageSize,
			            currentPage: this.table.currentPage,
						date: this.dateTime,
			            companyId: this.info.companyId,
			            departId: this.row.departId,
			        },
			        "get"
			    ).then((res) => {
			        if (
			            res.data.content &&
			            res.data.content.length > 0
			        ) {
			            that_.table.list = res.data.content;
			            that_.table.total = Number(
			                res.data.totalElements
			            );
			        }
			    });
			},
			changeArr() {
				let that = this;
				that.testShowNum = null;
			},
			mouseoverNow(num) {
				this.testShowNum = num;
			},
			handleSizeChange(pageSize) {
				this.table.pageSize = pageSize
				this.table.currentPage = 1
				this.monthDriverViolate()
			},
			handleCurrentChange(currentPage) {
				this.table.currentPage = currentPage
				this.monthDriverViolate()
			},
			echartsMain(list, xname) {
				let chartDom = document.getElementById('main6');
				let myChart = echarts.init(chartDom);
				let option;
				let that_ = this;

				option = {
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						},
						formatter: function(params) {
							let str = params[0].marker + params[0].name + "<br />" + '同比增长率: ' + params[0].data.yoy + "<br />" + '同比增长量: ' +
								params[0].data
								.incrementYoy + "<br />" + '环比增长率: ' + params[0].data.mom + "<br />" + '环比增长量: ' + params[0].data.incrementMom;
							return str;
						},
					},
					grid: {
						left: '4%',
						right: '12%',
						bottom: '15%',
						containLabel: true
					},
					xAxis: [{
						name: '事故类别',
						type: 'category',
						data: xname,
						axisPointer: {
							type: 'shadow'
						},
						axisLine: {
							lineStyle: {
								color: 'rgba(255,255,255,0.5)'
							}
						},
					}],
					yAxis: {
						type: 'value',
						name: '次数',
						axisLabel: {
							formatter: '{value}'
						},
						axisLine: {
							lineStyle: {
								color: 'rgba(255,255,255,0.5)'
							}
						}
					},
					series: [{
							name: '事故类别',
							type: 'bar',
							data: list,
							barWidth: 30
						}]
				};

				option && myChart.setOption(option);
			},
		}
	}
</script>

<style scoped>
	.col-00E0FD {
		color: #00E0FD;
	}

	.operateClassN {
		width: 100%;
		height: 100%;
	}

	.headerT {
		color: #42F3F9;
		font-size: 20px;
		padding-left: 10px;
		border-left: 3px solid #42F3F9;
		margin-top: 10px;
	}

	.mgt-10 {
		margin-top: 10px;
	}

	.rowCent {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		box-sizing: border-box;
	}

	.rowjaround {
		justify-content: space-around;
	}

	.rowCentRl {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.navList {
		width: 22%;
		height: 136px;
		background-image: url(../borderBg.png);
		background-size: 100% 100%;
		padding: 20px;
		box-sizing: border-box;
		text-align: center;
	}

	.navListOne {
		width: 59px;
		height: 59px;
		background-image: url(../navListOne.png);
		background-size: 100% 100%;
	}

	.summary {
		width: 100%;
		height: 233px;
		background-image: url(../bgAll.png);
		background-size: 100% 100%;
		padding: 10px;
		box-sizing: border-box;
	}

	.grade {
		width: 100%;
		height: 600px;
		background-image: url(../bgAll.png);
		background-size: 100% 100%;
		padding: 10px;
		box-sizing: border-box;
	}

	.follow {
		width: 100%;
		height: 600px;
		background-image: url(../bgAll.png);
		background-size: 100% 100%;
		padding: 10px;
		box-sizing: border-box;
	}

	.titleBg {
		min-width: 100px;
		height: 25px;
		background-color: rgba(105, 246, 175, 0.12);
		padding: 0 25px 0 15px;
		border-radius: 10px 0 20px 0;
		line-height: 25px;
		font-size: 15px;
		font-weight: 600;
		display: inline-block;
	}

	.titleBox {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: #00FF33;
	}

	.textOver {
		height: 180px;
		padding-top: 10px;
		line-height: 20px;
		overflow-y: scroll;
		overflow-x: hidden;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
	}

	.textOver::-webkit-scrollbar {
		display: none;
	}

	.tableOver {
		width: 100%;
		height: 380px;
		overflow-y: scroll;
		overflow-x: hidden;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
		box-sizing: border-box;
	}

	.tableOver::-webkit-scrollbar {
		display: none;
	}

	.w70 {
		width: 40%;
		height: 35px;
		line-height: 35px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.w30 {
		width: 20%;
		height: 35px;
		line-height: 35px;
	}

	.w100 {
		width: 100%;
		text-align: center;
		height: 35px;
		position: relative;
	}

	.w100Bg {
		width: 100%;
		text-align: center;
		height: 35px;
		background-color: rgba(0, 232, 255, 0.08);
	}

	.testShowClass {
		position: absolute;
		left: 0;
		top: 0;
		background-color: #096A94;
		min-height: 35px;
		line-height: 35px;
		width: 100%;
		font-size: 10px;
		z-index: 999;
	}
	.noData {
		width: 200px;
		height: 130px;
		background-image: url(../noData.png);
		background-size: 100% 100%;
		margin: 0 auto;
	}
</style>
