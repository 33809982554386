<template>
    <div class="operateClassN">
        <div class="headerT">服务质量</div>
        <div class="rowCent rowCentRl mgt-10">
            <div style="width: 26%">
                <div class="rightD mgt-10">
                    <div class="titleBg">
                        <el-row type="flex" align="middle">
                            <div class="titleBox"></div>
                            <div class="pl-1">服务质量</div>
                        </el-row>
                    </div>
                    <div class="navList rowCent rowCentRl">
                        <div style="width: 50%">
                            <div class="col-00E0FD" style="font-size: 30px">
                                {{
                                    list.serviceQualityNum
                                        ? list.serviceQualityNum
                                        : "0"
                                }}次
                            </div>
                            <div class="mt-1">累计服务质量投诉次数</div>
                        </div>
                        <div
                            style="
                                background-color: rgba(255, 255, 255, 0.1);
                                width: 1px;
                                height: 76px;
                            "
                        ></div>
                        <div class="rowCent">
                            <div>年环比</div>
                            <div
                                v-if="!list.serviceQualityNumMom"
                                style="padding: 0 10px"
                            >
                                {{
                                    list.serviceQualityNumMom === 0
                                        ? "0%"
                                        : "--"
                                }}
                            </div>
                            <div
                                v-if="list.serviceQualityNumMom > 0"
                                style="color: #15dd8f"
                            >
                                <i
                                    class="el-icon-caret-top"
                                    style="padding: 0 5px"
                                ></i
                                >{{ list.serviceQualityNumMom }}%
                            </div>
                            <div
                                v-if="list.serviceQualityNumMom < 0"
                                style="color: #f7333d"
                            >
                                <i
                                    class="el-icon-caret-bottom"
                                    style="padding: 0 5px"
                                ></i
                                >{{ list.serviceQualityNumMom }}%
                            </div>
                        </div>
                    </div>
                    <div class="rowCent">
                        <div
                            id="main8"
                            style="
                                width: 300px;
                                height: 380px;
                                display: block;
                                margin: 0 auto;
                            "
                        ></div>
                    </div>
                </div>
            </div>
            <div style="width: 73.4%">
                <div class="rightT mgt-10">
                    <div class="titleBg">
                        <el-row type="flex" align="middle">
                            <div class="titleBox"></div>
                            <div class="pl-1">驾驶员服务质量投诉分布情况</div>
                        </el-row>
                    </div>
                    <div
                        id="main9"
                        style="
                            width: 1000px;
                            height: 310px;
                            display: block;
                            margin: 0 auto;
                        "
                    ></div>
                </div>
                <div class="rightT mgt-10">
                    <div class="titleBg">
                        <el-row type="flex" align="middle">
                            <div class="titleBox"></div>
                            <div class="pl-1">驾驶员服务质量投诉分布情况</div>
                        </el-row>
                    </div>
                    <div
                        id="main12"
                        style="
                            width: 1000px;
                            height: 310px;
                            display: block;
                            margin: 0 auto;
                        "
                    ></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const echarts = require("echarts/lib/echarts");
require("echarts/lib/chart/pie");
require("echarts/lib/chart/line");
require("echarts/lib/component/tooltip");
require("echarts/lib/component/title");
require("echarts/lib/component/dataZoom");
require("echarts/lib/component/legend");
export default {
    name: "Operate",
    props: {
        row: {
            type: Object,
            default: () => {},
        },
        info: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            loading: false,
            dateTime: "",
            list: {},
        };
    },
    created() {},
    mounted() {},
    methods: {
        handleParentClick(v) {
            this.dateTime = v;
            this.getCompanyYearServiceDetails2();
        },
        getCompanyYearServiceDetails2() {
            let that_ = this;
            let arr = [];
            let arr1 = [];
            let arr2 = [];
            let arr3 = [];
            let arr4 = [];
            let arr5 = [];
            let arr6 = [];
            let arr7 = [];
            let arr8 = [];
            let arr9 = [];
            let arr10 = [];
            this.$axiosReq(
                "/archives/server/escort/web/serviceQuality/getCompanyYearServiceDetails2",
                null,
                {
                    date: this.dateTime + '-01',
                    companyId: this.info.companyId,
                },
                "get"
            ).then((res) => {
                that_.list = res.data;
                if (res.data.ringDiagrams && res.data.ringDiagrams.length > 0) {
                    res.data.ringDiagrams.forEach(function (item) {
                        arr.push({
                            name: item.serviceQualityTypeName,
                            value: item.serviceQualityTypeNum,
                        });
                    });
                    that_.echartsMain("main8", arr);
                } else {
                    that_.echartsMain("main8", arr);
                }
                if (
                    res.data.departHistograms &&
                    res.data.departHistograms.length > 0
                ) {
                    res.data.departHistograms.forEach(function (item) {
                        arr1.push(item.departName);
                        if (item.ringDiagrams && item.ringDiagrams.length > 0) {
                            item.ringDiagrams.forEach(function (items) {
                                if (items.serviceQualityTypeCode == 3) {
                                    arr2.push(
                                        items.serviceQualityTypeNum
                                            ? items.serviceQualityTypeNum
                                            : 0
                                    );
                                } else if (items.serviceQualityTypeCode == 1) {
                                    arr3.push(
                                        items.serviceQualityTypeNum
                                            ? items.serviceQualityTypeNum
                                            : 0
                                    );
                                } else if (
                                    items.serviceQualityTypeCode === "0"
                                ) {
                                    arr4.push(
                                        items.serviceQualityTypeNum
                                            ? items.serviceQualityTypeNum
                                            : 0
                                    );
                                } else if (items.serviceQualityTypeCode == 2) {
                                    arr5.push(
                                        items.serviceQualityTypeNum
                                            ? items.serviceQualityTypeNum
                                            : 0
                                    );
                                }
                            });
                        } else {
                            arr2.push(0);
                            arr3.push(0);
                            arr4.push(0);
                            arr5.push(0);
                        }
                    });
                    that_.echartsMain1("main9", arr1, arr2, arr3, arr4, arr5);
                } else {
                    that_.echartsMain1("main9", arr1, arr2, arr3, arr4, arr5);
                }
                if (
                    res.data.yearHistograms &&
                    res.data.yearHistograms.length > 0
                ) {
                    res.data.yearHistograms.forEach(function (item) {
                        arr6.push(item.month);
                        if (item.ringDiagrams && item.ringDiagrams.length > 0) {
                            item.ringDiagrams.forEach(function (items) {
                                if (items.serviceQualityTypeCode == 3) {
                                    arr7.push(
                                        items.serviceQualityTypeNum
                                            ? items.serviceQualityTypeNum
                                            : 0
                                    );
                                } else if (items.serviceQualityTypeCode == 1) {
                                    arr8.push(
                                        items.serviceQualityTypeNum
                                            ? items.serviceQualityTypeNum
                                            : 0
                                    );
                                } else if (
                                    items.serviceQualityTypeCode === "0"
                                ) {
                                    arr9.push(
                                        items.serviceQualityTypeNum
                                            ? items.serviceQualityTypeNum
                                            : 0
                                    );
                                } else if (items.serviceQualityTypeCode == 2) {
                                    arr10.push(
                                        items.serviceQualityTypeNum
                                            ? items.serviceQualityTypeNum
                                            : 0
                                    );
                                }
                            });
                        } else {
                            arr7.push(0);
                            arr8.push(0);
                            arr9.push(0);
                            arr10.push(0);
                        }
                    });
                    that_.echartsM("main12", arr6, arr7, arr8, arr9, arr10);
                } else {
                    that_.echartsM("main12", arr6, arr7, arr8, arr9, arr10);
                }
            });
        },
        echartsM(name, arr1, arr2, arr3, arr4, arr5) {
            var chartDom = document.getElementById(name);
            var myChart = echarts.init(chartDom);
            var option;

            option = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                        crossStyle: {
                            color: "#fff",
                        },
                    },
                },
                legend: {
                    textStyle: {
                        fontSize: 14,
                        color: "#fff",
                    },
                },
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "3%",
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: "category",
                        name: "部门",
                        nameTextStyle: {
                            color: "#ffffff",
                        },
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: "#ffffff",
                            },
                        },
                        axisLine: {
                            lineStyle: {
                                color: "rgba(255,255,255,0.5)",
                            },
                        },
                        data: arr1,
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        name: "人数",
                        nameTextStyle: {
                            color: "#ffffff",
                        },
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: "#ffffff",
                            },
                        },
                        axisLine: {
                            lineStyle: {
                                color: "rgba(255,255,255,0.5)",
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: "技术类",
                        type: "bar",
                        stack: "Ad",
                        emphasis: {
                            focus: "series",
                        },
                        barWidth: 20,
                        data: arr2,
                        itemStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        {
                                            offset: 0,
                                            color: "#C30EF0",
                                        },
                                        {
                                            offset: 0.5,
                                            color: "rgba(195,14,240,0.5)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(195,14,240,0)",
                                        },
                                    ]
                                ),
                            },
                        },
                    },
                    {
                        name: "运营类",
                        type: "bar",
                        stack: "Ad",
                        emphasis: {
                            focus: "series",
                        },
                        data: arr3,
                        itemStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        {
                                            offset: 0,
                                            color: "#ECE528",
                                        },
                                        {
                                            offset: 0.4,
                                            color: "rgba(236, 229, 40, 0.5)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(236, 229, 40, 0)",
                                        },
                                    ]
                                ),
                            },
                        },
                    },
                    {
                        name: "服务类",
                        type: "bar",
                        stack: "Ad",
                        emphasis: {
                            focus: "series",
                        },
                        data: arr4,
                        itemStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        {
                                            offset: 0,
                                            color: "#4149F3",
                                        },
                                        {
                                            offset: 0.4,
                                            color: "rgba(65, 73, 243, 0.5)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(65, 73, 243, 0)",
                                        },
                                    ]
                                ),
                            },
                        },
                    },
                    {
                        name: "安全类",
                        type: "bar",
                        stack: "Ad",
                        emphasis: {
                            focus: "series",
                        },
                        barWidth: 20,
                        data: arr5,
                        itemStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        {
                                            offset: 0,
                                            color: "#37D1DF",
                                        },
                                        {
                                            offset: 0.5,
                                            color: "rgba(55,209,223,0.5)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(195,14,240,0)",
                                        },
                                    ]
                                ),
                            },
                        },
                    },
                ],
            };

            option && myChart.setOption(option);
        },
        echartsMain(name, list) {
            let chartDom = document.getElementById(name);
            let myChart = echarts.init(chartDom);
            let option;

            option = {
                legend: {
                    show: true,
                    bottom: 0,
                    x: "center",
                    icon: "circle",
                    textStyle: {
                        fontSize: 14,
                        color: "#fff",
                    },
                    formatter: function (name) {
                        let target = "";
                        for (var i = 0, l = list.length; i < l; i++) {
                            if (list[i].name == name) {
                                target = "     " + list[i].value + "次";
                            }
                        }
                        return name + target;
                    },
                },
                color: ["#2788F9", "#00E8FF", "#00C6FF", "#3434F9", "#f2637b"],
                series: [
                    {
                        type: "pie",
                        radius: ["55%", "70%"],
                        avoidLabelOverlap: false,
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: "18",
                                fontWeight: "bold",
                                color: "#fff",
                                position: "center",
                                formatter: function (params) {
                                    return (
                                        params.name +
                                        "\r\n" +
                                        params.value +
                                        "次"
                                    );
                                },
                            },
                        },
                        label: {
                            normal: {
                                show: false,
                                fontSize: 18,
                                fontWeight: "bold",
                                position: "center",
                            },
                        },
                        labelLine: {
                            show: false,
                        },
                        data: list,
                    },
                ],
            };

            option && myChart.setOption(option);
        },
        echartsMain1(name, arr1, arr2, arr3, arr4, arr5) {
            let chartDom = document.getElementById(name);
            let myChart = echarts.init(chartDom);
            let option;
            option = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                        crossStyle: {
                            color: "#fff",
                        },
                    },
                },
                legend: {
                    textStyle: {
                        fontSize: 14,
                        color: "#fff",
                    },
                },
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "3%",
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: "value",
                        name: "人数",
                        nameTextStyle: {
                            color: "#ffffff",
                        },
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: "#ffffff",
                            },
                        },
                        axisLine: {
                            lineStyle: {
                                color: "rgba(255,255,255,0.5)",
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        type: "category",
                        name: "部门",
                        nameTextStyle: {
                            color: "#ffffff",
                        },
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: "#ffffff",
                            },
                        },
                        axisLine: {
                            lineStyle: {
                                color: "rgba(255,255,255,0.5)",
                            },
                        },
                        data: arr1,
                    },
                ],
                series: [
                    {
                        name: "技术类",
                        type: "bar",
                        stack: "Ad",
                        emphasis: {
                            focus: "series",
                        },
                        barWidth: 20,
                        data: arr2,
                        itemStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        {
                                            offset: 0,
                                            color: "#C30EF0",
                                        },
                                        {
                                            offset: 0.5,
                                            color: "rgba(195,14,240,0.5)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(195,14,240,0)",
                                        },
                                    ]
                                ),
                            },
                        },
                    },
                    {
                        name: "运营类",
                        type: "bar",
                        stack: "Ad",
                        emphasis: {
                            focus: "series",
                        },
                        data: arr3,
                        itemStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        {
                                            offset: 0,
                                            color: "#ECE528",
                                        },
                                        {
                                            offset: 0.4,
                                            color: "rgba(236, 229, 40, 0.5)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(236, 229, 40, 0)",
                                        },
                                    ]
                                ),
                            },
                        },
                    },
                    {
                        name: "服务类",
                        type: "bar",
                        stack: "Ad",
                        emphasis: {
                            focus: "series",
                        },
                        data: arr4,
                        itemStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        {
                                            offset: 0,
                                            color: "#4149F3",
                                        },
                                        {
                                            offset: 0.4,
                                            color: "rgba(65, 73, 243, 0.5)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(65, 73, 243, 0)",
                                        },
                                    ]
                                ),
                            },
                        },
                    },
                    {
                        name: "安全类",
                        type: "bar",
                        stack: "Ad",
                        emphasis: {
                            focus: "series",
                        },
                        barWidth: 20,
                        data: arr5,
                        itemStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        {
                                            offset: 0,
                                            color: "#37D1DF",
                                        },
                                        {
                                            offset: 0.5,
                                            color: "rgba(55,209,223,0.5)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(195,14,240,0)",
                                        },
                                    ]
                                ),
                            },
                        },
                    },
                ],
            };
            option && myChart.setOption(option);
        },
    },
};
</script>

<style scoped>
.col-00E0FD {
    color: #00e0fd;
}

.col-42F3F9 {
    color: #42f3f9;
}

.operateClassN {
    width: 100%;
    height: 100%;
}

.headerT {
    color: #42f3f9;
    font-size: 20px;
    padding-left: 10px;
    border-left: 3px solid #42f3f9;
    margin-top: 10px;
}

.mgt-10 {
    margin-top: 10px;
}

.rowCent {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    box-sizing: border-box;
}

.rowCentRl {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.rowJCent {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.titleBg {
    min-width: 100px;
    height: 25px;
    background-color: rgba(105, 246, 175, 0.12);
    padding: 0 25px 0 15px;
    border-radius: 10px 0 20px 0;
    line-height: 25px;
    font-size: 15px;
    font-weight: 600;
    display: inline-block;
}

.titleBox {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #00ff33;
}

.textOver {
    height: 150px;
    padding-top: 10px;
    line-height: 20px;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    box-sizing: border-box;
}

.textOver::-webkit-scrollbar {
    display: none;
}

.navList {
    width: 80%;
    height: 136px;
    background-image: url(../borderBg.png);
    background-size: 100% 100%;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    margin: 90px auto 60px;
}

.navList1 {
    width: 65%;
    height: 220px;
    background-image: url(../bgAll.png);
    background-size: 100% 100%;
    padding: 20px;
    box-sizing: border-box;
}

.positionText {
    position: absolute;
    left: 0;
    top: 49px;
    width: 100%;
    text-align: center;
    font-size: 22px;
}

.positionText1 {
    position: absolute;
    left: 0;
    top: 40px;
    width: 100%;
    text-align: center;
    font-size: 22px;
}

.timeBg {
    width: 77px;
    height: 78px;
    background-image: url(../timeBg.png);
    background-size: 100% 100%;
    margin-right: 30px;
}

.leftZ {
    width: 100%;
    height: 290px;
    background-image: url(../bgAll.png);
    background-size: 100% 100%;
    padding: 20px;
    box-sizing: border-box;
}

.leftD {
    width: 100%;
    height: 255px;
    background-image: url(../bgAll.png);
    background-size: 100% 100%;
    padding: 20px;
    box-sizing: border-box;
}

.rightT {
    width: 100%;
    height: 355px;
    background-image: url(../bgAll.png);
    background-size: 100% 100%;
    padding: 20px;
    box-sizing: border-box;
}

.rightD {
    width: 100%;
    height: 720px;
    background-image: url(../bgAll.png);
    background-size: 100% 100%;
    padding: 20px;
    box-sizing: border-box;
}

.w100 {
    width: 100%;
    text-align: center;
    height: 30px;
}

.w100Bg {
    width: 100%;
    text-align: center;
    height: 30px;
    background-color: rgba(0, 232, 255, 0.08);
}

.w70 {
    width: 60%;
    height: 30px;
    line-height: 30px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.w30 {
    width: 20%;
    height: 30px;
    line-height: 30px;
}

.tableOver {
    width: 100%;
    height: 90px;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    box-sizing: border-box;
}

.tableOver::-webkit-scrollbar {
    display: none;
}

.testBox {
    padding-top: 10px;
    width: 100%;
    height: 155px;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    box-sizing: border-box;
}

.testBox::-webkit-scrollbar {
    display: none;
}

.testList {
    width: 386px;
    height: 27px;
    line-height: 27px;
    color: rgba(255, 255, 255, 0.2);
    border-radius: 14px;
    margin: 0 auto;
    text-align: center;
    padding: 0 10px;
    box-sizing: border-box;
}

.active {
    background-color: rgba(255, 255, 255, 0.05);
    color: #00e0fd;
    font-size: 18px;
}
</style>
