<template>
	<div class="dashboard-container">
		<Operate v-if="dialog.refresh" :row="dialog.row" :info="dialog.info" @refresh="refreshDialog" />
		<BatchExport v-if="dialog.refresh2" :row="dialog.row" :info="dialog.info" @refresh="refreshDialog" />
		<el-card>
			<el-row type="flex" justify="space-between">
				<el-col v-if="$admin" :span="6">
					<span class="text-primary text-pad-right">公司：</span>
					<el-select v-model="companyId" size="mini" class="input searchInput" filterable @change="changeCondition('2')">
						<el-option v-for="(v, i) in company" :key="i" :value="v.id" :label="v.companyName" />
					</el-select>
				</el-col>
				<el-col :span="6">
					<el-input style="width: 280px;" v-model="searchName" class="input searchInput" size="mini" suffix-icon="el-icon-search"
					 placeholder="搜索" @input="changeCondition('0')" />
				</el-col>
				<el-col :span="8"></el-col>
				<el-col :span="2">
					<!-- <vxe-button @click="getListNow">刷新</vxe-button> -->
				</el-col>
			</el-row>
		</el-card>
		<el-card>
			<el-row>
				<vxe-table ref="table" border resizable :auto-resize="true" :header-row-style="headerStyle" :row-style="rowStyle"
				 align="center" class="vxe-table-element" height="580" row-id="company_id" :sort-config="{ remote: true }"
				 :filter-config="{ remote: true }" :data="table.list" :loading="loading">
					<vxe-table-column type="seq" width="60" />
					<vxe-table-column field="departName" title="部门名称" />
					<vxe-table-column field="departManager" title="管理人员" />
					<!-- <vxe-table-column field="driverNum" title="驾驶员人数" /> -->
					<vxe-table-column title="月度报告">
						<template v-slot="{ row }">
							<el-link type="primary" @click="openDialog({
										title: '月度报告',
										addOr: 'add',
									}, row)">查看</el-link>
						</template>
					</vxe-table-column>
					<vxe-table-column title="年度报告">
						<template v-slot="{ row }">
							<el-link type="primary" @click="openExport({
										title: '年度报告',
										addOr: 'add',
									}, row)">查看</el-link>
						</template>
					</vxe-table-column>
				</vxe-table>
				<div style="height: 20px"></div>
				<el-pagination background :current-page="table.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]"
				 :page-size="table.pageSize" layout="total, ->, sizes, prev, pager, next, jumper" :total="table.total" @size-change="handleSizeChange"
				 @current-change="handleCurrentChange" />
			</el-row>
		</el-card>
	</div>
</template>

<script>
	import {
		bytesToFile
	} from "@/utils/handleByte";
	import {
		dropUserPosition,
		depart,
		company,
	} from '@/api'
	import {
		rowStyle,
		headerStyle
	} from "@/utils/tableStyleJs";
	import Operate from "./Operate";
	import BatchExport from "./BatchExport";
	export default {
		name: "Index",
		components: {
			Operate,
			BatchExport,
		},
		data() {
			return {
				loading: false,
				table: {
					list: [],
					total: 0,
					currentPage: 1,
					pageSize: 10,
					sortName: "",
					sortBy: "",
				},
				dialog: {
					row: Object,
					info: {},
					refresh: false, // 每次刷新DOM
					refresh2: false, // 每次刷新DOM
					refresh3: false, // 每次刷新DOM
					refreshRole: false, // 每次刷新DOM
					show: false,
					show2: false,
					show3: false,
					showRole: false,
				},
				dateTime: "",
				searchName: "",
				startTime: "",
				endTime: "",
				violateConfigIds: "",
				violateType: "",
				departId: "",
				companyId: this.$admin ? '' : this.$companyId,
				positionId: "",
				serviceQualityConfig: [],
				serviceType: [],
				company: [],
				position: [],
				depart: [],
				userIds: [],
			};
		},
		created() {
			// this.getParentCode('VIOLATE_TYPE');
			this.getCompany();
		},
		mounted() {},
		methods: {
			rowStyle,
			headerStyle,
			getListNow() {
				this.userIds = [];
				this.getList();
			},
			// 打开dialog
			openDialog(info, row) {
				this.dialog.refresh = true;
				this.dialog.show = true;
				info.companyId = this.companyId;
				this.dialog.info = info;
				this.dialog.row = row;
			},
			// 打开批量导入
			openExport(info, row) {
				this.dialog.refresh2 = true;
				this.dialog.show2 = true;
				info.companyId = this.companyId;
				this.dialog.info = info;
				this.dialog.row = row;
			},
			getCompany() {
				this.$axiosReq(company, null, {
					pageSize: 10000
				}, 'get').then(res => {
					this.company = res.data.content;
					if (this.company && this.company.length > 0) {
						this.companyId = this.company[0].id;
					}
					this.getList();
				})
			},
			getPosition() {
				this.$axiosReq(
					dropUserPosition,
					null, {
						companyId: this.companyId,
					},
					"get"
				).then((res) => {
					this.position = res.data;
				});
			},
			getDepart() {
				this.$axiosReq(
					depart,
					null, {
						companyId: this.companyId,
						type: 1,
						pageSize: 10000,
					},
					"get"
				).then((res) => {
					this.depart = res.data;
				});
			},
			changeCondition(num) {
				this.table.currentPage = 1
				if (num == 1) {
					this.serviceQualityConfig = [];
					this.violateConfigIds = '';
					if (this.violateType) {
						this.getViolateConfig();
					}
				} else if (num == 2) {
					this.position = [];
					this.depart = [];
					this.departId = "";
					this.positionId = "";
				} else if (num == 3) {
					this.startTime = '';
					this.endTime = '';
					if (this.dateTime) {
						this.startTime = this.dateTime[0];
						this.endTime = this.dateTime[1];
					}
				}
				this.getList();
			},
			getViolateConfig() {
				this.$axiosReq('/archives/server/escort/web/violateConfig', null, {
					pageSize: 1000,
					violateType: this.violateType,
				}, 'get').then(res => {
					this.serviceQualityConfig = res.data.content
				})
			},
			getList() {
				this.loading = true;
				this.$axiosReq(
						'/archives/server/escort/web/creditEvaluate/pageDepartCreditEvaluates',
						null, {
							currentPage: this.table.currentPage,
							pageSize: this.table.pageSize,
							searchWord: this.searchName,
							companyId: this.companyId,
						},
						"get"
					)
					.then((res) => {
						this.table.list = res.data.content;
						this.table.total = Number(res.data.totalElements);
					})
					.finally((res) => {
						this.loading = false;
					});
			},
			remove(info, row) {
				this.$confirm("确认删除吗！！！", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.$axiosReq(
							'/archives/server/escort/web/violate/' + row.id,
							null,
							null,
							"delete"
						).then((res) => {
							this.$message.success(res.msg);
							this.getList();
						});
					})
					.catch(() => {
						this.$message.info("取消了删除！！！");
					});
			},
			removeAll() {
				var that_ = this;
				if (that_.userIds.length < 1) {
					this.$message.error("请勾选需要删除的选项");
					return false;
				}
				this.$confirm("确认批量删除吗！！！", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.$axiosReq(
							'/archives/server/escort/web/violate',
							that_.userIds,
							null,
							"delete"
						).then((res) => {
							//console.log(JSON.stringify(res))
							that_.$message.success("批量删除成功");
							that_.userIds = [];
							that_.getList();
						});
					})
					.catch(() => {
						that_.$message.info("取消了批量删除！！！");
					});
			},
			handleSizeChange(pageSize) {
				this.table.pageSize = pageSize;
				this.table.currentPage = 1;
				this.table.list = [];
				this.getList();
			},
			handleCurrentChange(currentPage) {
				this.table.list = [];
				this.table.currentPage = currentPage;
				this.getList();
			},
			refreshDialog() {
				this.dialog.refresh = false;
				this.dialog.refresh2 = false;
				// this.getList();
			},
			handleSelectionChange(val) {
				const data = val.records;
				this.userIds = [];
				for (let i = 0; i < data.length; i++) {
					this.userIds.push(data[i].id);
				}
			},
			getParentCode(id) {
				this.$axiosReq('/data/server/escort/web/dict/parentCode/' + id, null, null, 'get').then(res => {
					this.serviceType = res.data
				})
			},
			exportList() {
				this.loading = true;
				this.$axiosReq('/archives/server/escort/web/violate/export', null, {
						currentPage: this.table.currentPage,
						pageSize: this.table.pageSize,
						violateConfigIds: this.violateConfigIds,
						violateType: this.violateType,
						startTime: this.startTime,
						searchName: this.searchName,
						endTime: this.endTime,
						companyId: this.companyId,
						departId: this.departId,
						positionId: this.positionId,
					}, "get")
					.then((res) => {
						bytesToFile(res.data.bytes, res.data.fileName);
					})
					.finally((e) => {
						this.loading = false;
					});
			},
		},
	};
</script>

<style scoped></style>
