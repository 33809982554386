<template>
	<div class="operateClassN">
		<div class="headerT">状态测评</div>
		<div class="rowCent rowCentRl mgt-10">
			<div style="width: 49%">
				<div class="rowCent rowCentRl">
					<div class="navList1">
						<div class="titleBg">
							<el-row type="flex" align="middle">
								<div class="titleBox"></div>
								<div class="pl-1">本月测评板块</div>
							</el-row>
						</div>
						<div class="testBox">
							<div v-for="(item, index) in mentalTypeCheckVos" :key="index" class="testList" :class="{ active: indexNow == item.isCheck }">
								{{ item.mentalTypeName }}
							</div>
						</div>
					</div>
					<div class="navList">
						<div class="rowCent rowCentRl">
							<div>测评参与率</div>
							<div>
								排名：<span class="col-00E0FD">{{
                                    list.ranking
                                }}</span>
								<span>/{{
								    list.rankingTotal
								}}</span>
							</div>
						</div>
						<div style="
                                border-top: 2px solid rgba(255, 255, 255, 0.2);
                                margin-top: 20px;
                                margin-bottom: 15px;
                            "></div>
						<div class="rowCent rowCentRl">
							<div style="position: relative">
								<el-progress type="circle" :percentage="
                                        list.mentalCompletionRate
                                            ? list.mentalCompletionRate
                                            : 0
                                    "
								 :stroke-width="12" color="#984FF8" :show-text="false"></el-progress>
								<div class="positionText">
									{{
                                        list.mentalCompletionRate
                                            ? list.mentalCompletionRate
                                            : 0
                                    }}%
								</div>
							</div>
							<div>
								<div class="rowCent">
									<div>月同比</div>
									<div v-if="!list.monthlyYoy" style="padding: 0 10px">
										{{
                                            list.monthlyYoy === 0 ? "0%" : "--"
                                        }}
									</div>
									<div v-if="list.monthlyYoy > 0" style="color: #15dd8f">
										<i class="el-icon-caret-top" style="padding: 0 5px"></i>{{ list.monthlyYoy }}%
									</div>
									<div v-if="list.monthlyYoy < 0" style="color: #f7333d">
										<i class="el-icon-caret-bottom" style="padding: 0 5px"></i>{{ list.monthlyYoy }}%
									</div>
								</div>
								<div style="margin-top: 20px"></div>
								<div class="rowCent">
									<div>月环比</div>
									<div v-if="!list.monthlyMom" style="padding: 0 10px">
										{{
                                            list.monthlyMom === 0 ? "0%" : "--"
                                        }}
									</div>
									<div v-if="list.monthlyMom > 0" style="color: #15dd8f">
										<i class="el-icon-caret-top" style="padding: 0 5px"></i>{{ list.monthlyMom }}%
									</div>
									<div v-if="list.monthlyMom < 0" style="color: #f7333d">
										<i class="el-icon-caret-bottom" style="padding: 0 5px"></i>{{ list.monthlyMom }}%
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="leftZ mgt-10">
					<div class="titleBg">
						<el-row type="flex" align="middle">
							<div class="titleBox"></div>
							<div class="pl-1">测评参与率走势</div>
						</el-row>
					</div>
					<div id="main2" style="
                            width: 800px;
                            height: 270px;
                            display: block;
                            margin: 0 auto;
                        "></div>
				</div>
				<div class="leftD mgt-10">
					<div class="titleBg">
						<el-row type="flex" align="middle">
							<div class="titleBox"></div>
							<div class="pl-1">重点关注名单</div>
						</el-row>
					</div>
					<div class="tableClass">
						<div class="rowCent w100Bg mgt-10">
							<div class="w30">姓名</div>
							<div class="w30">电话</div>
							<div class="w70">负面倾向维度</div>
							<div class="w30">操作</div>
						</div>
						<div class="tableOver">
							<div class="rowCent w100" v-for="(item, index) in table.list" :key="index">
								<div class="w30">{{ item.userName }}</div>
								<div class="w30">{{ item.phone }}</div>
								<div class="w70">
									{{ item.negativeMentalType }}
								</div>
								<div class="w30">
									<span @click="openEvaluation(item.id)" style="color: #02D7EE;border-bottom: 1px solid #02D7EE;">查看测评情况</span>
								</div>
							</div>
							<div v-if="table.list.length < 1" class="noData"></div>
						</div>
						<el-pagination :current-page="table.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]" :page-size="table.pageSize"
						 layout="total, ->, prev, pager, next" :total="table.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
					</div>
				</div>
			</div>
			<div style="width: 49%">
				<div class="rightT">
					<div class="titleBg">
						<el-row type="flex" align="middle">
							<div class="titleBox"></div>
							<div class="pl-1">测评结果概述</div>
						</el-row>
					</div>
					<div class="textOver col-00E0FD">
						{{ dateTime }}月份测试板块为安全驾驶态度， 将从{{
                            proportionVos.length
                        }}个维度进行测评，即
						<span v-for="(item, index) in proportionVos" :key="index">
							{{ item.mentalTypeName }},
						</span>
						<span v-for="(item, index) in proportionVos" :key="index">
							<span v-for="(
                                    items, indexs
                                ) in item.feedbackResultVos"
							 :key="indexs">
								<span v-if="items.isNegative === 0">
									{{ items.feedbackResult }}
									<span style="font-size: 20px">
										{{
                                            items.peopleNumber
                                                ? items.peopleNumber
                                                : 0
                                        }}人
									</span>
								</span>
							</span>
						</span>
					</div>
				</div>
				<div class="rightD mgt-10" style="position: relative;">
					<div class="titleBg">
						<el-row type="flex" align="middle">
							<div class="titleBox"></div>
							<div class="pl-1">各个测评维度驾驶员的占比情况</div>
						</el-row>
					</div>
					<el-carousel height="400px" :autoplay="false">
						<el-carousel-item>
							<div class="rowCent">
								<div id="main3" style="
					                    width: 270px;
					                    height: 370px;
					                    display: block;
					                    margin: 0 auto;
					                "></div>
								<div id="main4" style="
					                    width: 270px;
					                    height: 370px;
					                    display: block;
					                    margin: 0 auto;
					                "></div>
								<div id="main5" style="
					                    width: 270px;
					                    height: 370px;
					                    display: block;
					                    margin: 0 auto;
					                "></div>
							</div>
						</el-carousel-item>
						<el-carousel-item>
							<div class="rowCent rowJCent">
								<div id="mainTwo1" style="
						                width: 270px;
						                height: 370px;
						                display: block;
						                margin: 0 auto;
						            "></div>
								<div id="mainTwo2" style="
						                width: 270px;
						                height: 370px;
						                display: block;
						                margin: 0 auto;
						            "></div>
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
		</div>
		<div v-if="evaluation" class="lookDetails" @click="closeAllBox($event)">
			<div class="lookDetailsBox">
				<el-row :gutter="20">
					<el-col :span="1">
						<div style="width: 1px;height: 1px;"></div>
					</el-col>
					<el-col :span="22">
						<div class="text-center" style="color: #5CDCFC;font-size: 18px;font-weight: bold;">测评情况</div>
					</el-col>
					<el-col :span="1">
						<i @click="closeLearning" class="el-icon-error" style="font-size: 22px;color: #5CDCFC;cursor: pointer;"></i>
					</el-col>
				</el-row>
				<div class="lookDetailsCont" style="font-size: 16px;">
					<div style="border-left: 3px solid #5CDCFC;color: #5CDCFC;height: 16px;line-height: 16px;padding-left: 5px;font-size: 16px;">测评结果</div>
					<el-row :gutter="22" style="padding-top: 15px;">
						<el-col :span="3">
							<div style="height: 30px;line-height: 30px;color: #5CDCFC;">测评量表：</div>
						</el-col>
						<el-col :span="9">
							<div style="height: 30px;line-height: 30px;">{{testPaper.mentalTypeName ? testPaper.mentalTypeName : '--'}}</div>
						</el-col>
						<el-col :span="3">
							<div style="height: 30px;line-height: 30px;color: #5CDCFC;">真实性：</div>
						</el-col>
						<el-col :span="9">
							<div style="height: 30px;line-height: 30px;">{{testPaper.lieFeedbackResults ? testPaper.lieFeedbackResults : '--'}}</div>
						</el-col>
					</el-row>
					<el-row :gutter="22">
						<el-col :span="3">
							<div style="height: 30px;line-height: 30px;color: #5CDCFC;">作答用户：</div>
						</el-col>
						<el-col :span="9">
							<div style="height: 30px;line-height: 30px;">{{testPaper.realName ? testPaper.realName : '--'}}</div>
						</el-col>
						<el-col :span="3">
							<div style="height: 30px;line-height: 30px;color: #5CDCFC;">测评得分：</div>
						</el-col>
						<el-col :span="9">
							<div style="height: 30px;line-height: 30px;">{{testPaper.detectionScore ? testPaper.detectionScore + '分' : '--'}}</div>
						</el-col>
					</el-row>
					<el-row :gutter="22">
						<el-col :span="3">
							<div style="height: 30px;line-height: 30px;color: #5CDCFC;">作答时长：</div>
						</el-col>
						<el-col :span="9">
							<div style="height: 30px;line-height: 30px;">{{testPaper.answerTime ? testPaper.answerTime + '/分钟' : '--'}}</div>
						</el-col>
						<el-col :span="3">
							<div style="height: 30px;line-height: 30px;color: #5CDCFC;">提交时间：</div>
						</el-col>
						<el-col :span="9">
							<div style="height: 30px;line-height: 30px;">{{testPaper.submitTime ? testPaper.submitTime : '--'}}</div>
						</el-col>
					</el-row>
					<el-row :gutter="22" style="padding-bottom: 15px;">
						<el-col :span="3">
							<div style="height: 30px;line-height: 30px;color: #5CDCFC;">测评结果：</div>
						</el-col>
						<el-col :span="21">
							<span v-for="(item,index) in testPaper.evaluationResultsVos" :key="index" style="line-height: 25px;">
								<span style="color: #5CDCFC;">【{{item.feedbackResults}}】</span>
								<span>{{item.feedbackScheme}}</span>
							</span>
						</el-col>
					</el-row>
					<div style="border-left: 3px solid #5CDCFC;color: #5CDCFC;height: 16px;line-height: 16px;padding-left: 5px;font-size: 16px;">作答情况</div>
					<div style="padding-top: 10px;" v-if="subjects.length < 1">未参加考试</div>
					<div style="padding-top: 10px;line-height: 22px;" v-if="subjects.length > 0">
						{{testPaper.description}}
					</div>
					<div v-for="(item,index) in subjects" :key="index">
						<div style="line-height: 22px;padding-top: 10px;padding-bottom: 10px;">{{index + 1}}, {{item.topicTitle}}</div>
						<div class="optionClass">
							<div style="padding: 0 10px;" v-for="(items,indexs) in item.sos" :key="indexs">
								<div :class="{colActive : items.isChoose === 0}">
									<span class="xuanxiang" :class="{borderActive : items.isChoose === 0}">{{items.topicOptions}}</span>
									{{items.content}}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	const echarts = require("echarts/lib/echarts");
	require("echarts/lib/chart/pie");
	require("echarts/lib/chart/line");
	require("echarts/lib/component/tooltip");
	require("echarts/lib/component/title");
	require("echarts/lib/component/dataZoom");
	require("echarts/lib/component/legend");
	export default {
		name: "Operate",
		props: {
			row: {
				type: Object,
				default: () => {},
			},
			info: {
				type: Object,
				default: () => {},
			},
		},
		data() {
			return {
				loading: false,
				dateTime: "",
				table: {
					list: [],
					total: 0,
					currentPage: 1,
					pageSize: 10,
				},
				indexNow: 1,
				list: {},
				list1: {},
				mentalTypeCheckVos: [],
				proportionVos: [],
				evaluation: false,
				testPaper: {},
				subjects: [],
			};
		},
		created() {},
		mounted() {},
		methods: {
			openEvaluation(id) {
				this.evaluation = true;
				this.getSubjects(id);
				this.getTestPaper(id);
			},
			closeLearning() {
				this.evaluation = false;
			},
			closeAllBox(o) {
				if (o.target.className == 'lookDetails') {
					this.evaluation = false;
				}
			},
			getTestPaper(id) {
				let that_ = this;
				that_.testPaper = {};
				this.$axiosReq(
					"/course/server/escort/web/mentalUser/testPaper/" + id,
					null, null,
					"get"
				).then((res) => {
					if (res.data) {
						that_.testPaper = res.data;
					}
				});
			},
			getSubjects(id) {
				let that_ = this;
				that_.subjects = [];
				this.$axiosReq(
					"/course/server/escort/web/mentalUser/subjects/" + id,
					null, null,
					"get"
				).then((res) => {
					if (
						res.data &&
						res.data.length > 0
					) {
						that_.subjects = res.data;
					}
				});
			},
			handleParentClick(v) {
				this.dateTime = v;
				this.table.currentPage = 1;
				this.table.list = [];
				this.mentalTypeCheckVos = [];
				this.proportionVos = [];
				this.monthMentalBaseInfo();
				this.monthMentalFocusDriver();
				this.monthMentalTrendChart();
				this.monthMentalProportion();
			},
			monthMentalTrendChart() {
				let that_ = this;
				let arr = [];
				let arr1 = [];
				this.$axiosReq(
					"/course/server/escort/web/departCreditMentalUser/monthMentalTrendChart",
					null, {
						date: this.dateTime,
						companyId: this.info.companyId,
						departId: this.row.departId,
					},
					"get"
				).then((res) => {
					if (
						res.data &&
						res.data.length > 0
					) {
						res.data.forEach(function(item) {
							arr.push(item.mentalCompletionRate);
							arr1.push(item.submitTime);
						});
						that_.echartsM(arr, arr1);
					} else {
						that_.echartsM([], []);
					}
				});
			},
			monthMentalBaseInfo() {
				let that_ = this;
				this.$axiosReq(
					"/course/server/escort/web/departCreditMentalUser/monthMentalBaseInfo",
					null, {
						date: this.dateTime,
						companyId: this.info.companyId,
						departId: this.row.departId,
					},
					"get"
				).then((res) => {
					that_.list = res.data;
					if (
						res.data.mentalTypeCheckVos &&
						res.data.mentalTypeCheckVos.length > 0
					) {
						that_.mentalTypeCheckVos = res.data.mentalTypeCheckVos;
					}
				});
			},
			monthMentalFocusDriver() {
				let that_ = this;
				this.$axiosReq(
					"/course/server/escort/web/departCreditMentalUser/monthMentalFocusDriver",
					null, {
						pageSize: this.table.pageSize,
						currentPage: this.table.currentPage,
						date: this.dateTime,
						companyId: this.info.companyId,
						departId: this.row.departId,
					},
					"get"
				).then((res) => {
					if (
						res.data.content &&
						res.data.content.length > 0
					) {
						that_.table.list = res.data.content;
						that_.table.total = Number(
							res.data.totalElements
						);
					}
				});
			},
			monthMentalProportion() {
				let that_ = this;
				let arr2 = [];
				let arr3 = [];
				let arr4 = [];
				let arr5 = [];
				let arr6 = [];
				let mentalTypeName = "";
				let mentalTypeName1 = "";
				let mentalTypeName2 = "";
				let mentalTypeName3 = "";
				let mentalTypeName4 = "";
				this.$axiosReq(
					"/course/server/escort/web/departCreditMentalUser/monthMentalProportion",
					null, {
						date: this.dateTime,
						companyId: this.info.companyId,
						departId: this.row.departId,
					},
					"get"
				).then((res) => {
					// console.log(JSON.stringify(res))
					// that_.list1 = res.data;
					if (
						res.data &&
						res.data.length > 0
					) {
						that_.proportionVos = res.data;
						res.data.forEach(function(item, index) {
							if (index === 0) {
								mentalTypeName = item.mentalTypeName;
								item.feedbackResultVos.forEach(function(items) {
									arr2.push({
										value: items.peopleNumber,
										name: items.feedbackResult,
									});
								});
							} else if (index === 1) {
								mentalTypeName1 = item.mentalTypeName;
								item.feedbackResultVos.forEach(function(items) {
									arr3.push({
										value: items.peopleNumber,
										name: items.feedbackResult,
									});
								});
							} else if (index === 2) {
								mentalTypeName2 = item.mentalTypeName;
								item.feedbackResultVos.forEach(function(items) {
									arr4.push({
										value: items.peopleNumber,
										name: items.feedbackResult,
									});
								});
							} else if (index === 3) {
								mentalTypeName3 = item.mentalTypeName;
								item.feedbackResultVos.forEach(function(items) {
									arr5.push({
										value: items.peopleNumber,
										name: items.feedbackResult,
									});
								});
							} else if (index === 4) {
								mentalTypeName4 = item.mentalTypeName;
								item.feedbackResultVos.forEach(function(items) {
									arr6.push({
										value: items.peopleNumber,
										name: items.feedbackResult,
									});
								});
							}
						});
						this.echartsMain("main3", mentalTypeName, arr2);
						this.echartsMain("main4", mentalTypeName1, arr3);
						this.echartsMain("main5", mentalTypeName2, arr4);
						this.echartsMain("mainTwo1", mentalTypeName3, arr5);
						this.echartsMain("mainTwo2", mentalTypeName4, arr6);
					} else {
						this.echartsMain("main3", mentalTypeName, arr2);
						this.echartsMain("main4", mentalTypeName1, arr3);
						this.echartsMain("main5", mentalTypeName2, arr4);
						this.echartsMain("mainTwo1", mentalTypeName3, arr5);
						this.echartsMain("mainTwo2", mentalTypeName4, arr6);
					}
				});
			},
			clickIndex(i) {
				this.indexNow = i;
			},
			handleSizeChange(pageSize) {
				this.table.pageSize = pageSize;
				this.table.currentPage = 1;
				this.monthMentalFocusDriver();
			},
			handleCurrentChange(currentPage) {
				this.table.currentPage = currentPage;
				this.monthMentalFocusDriver();
			},
			echartsM(list, xname) {
				var chartDom = document.getElementById("main2");
				var myChart = echarts.init(chartDom);
				var option;

				option = {
					tooltip: {
						trigger: "axis",
						axisPointer: {
							type: "cross",
							crossStyle: {
								color: "#fff",
							},
						},
						formatter: function(params) {
							let str =
								params[0].name +
								"<br />" +
								params[0].marker +
								params[0].value +
								"%";
							return str;
						},
					},
					xAxis: {
						name: "日期",
						type: "category",
						nameTextStyle: {
							color: "#ffffff",
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: "#ffffff",
							},
						},
						axisLine: {
							lineStyle: {
								color: "rgba(255,255,255,0.5)",
							},
						},
						data: xname,
					},
					yAxis: {
						name: "百分比",
						type: "value",
						nameTextStyle: {
							color: "#ffffff",
						},
						axisLine: {
							lineStyle: {
								color: "rgba(255,255,255,0.5)",
							},
						},
						axisLabel: {
							textStyle: {
								color: "#ffffff",
							},
							formatter: '{value}%'
						}
					},
					series: [{
						data: list,
						type: "line",
						stack: "Total",
						smooth: true,
						lineStyle: {
							width: 5,
							color: new echarts.graphic.LinearGradient(
								0,
								0,
								0,
								1,
								[{
										offset: 0,
										color: "#0092EB",
									},
									{
										offset: 1,
										color: "#00E8FF",
									},
								]
							),
						},
						areaStyle: {
							normal: {
								color: new echarts.graphic.LinearGradient(
									0,
									0,
									0,
									1,
									[{
											offset: 0,
											color: "#0092EB",
										},
										{
											offset: 1,
											color: "#00E8FF",
										},
									]
								),
							},
						},
						itemStyle: {
							normal: {
								label: {
									show: true,
								},
								color: "#ffffff",
							},
						},
					}, ],
				};

				option && myChart.setOption(option);
			},
			echartsMain(name, tname, list) {
				let chartDom = document.getElementById(name);
				let myChart = echarts.init(chartDom);
				let option;
				let that_ = this;

				option = {
					title: {
						text: tname,
						textStyle: {
							color: "#00E8FF",
							fontWeight: "bold",
							fontSize: 16,
						},
						x: "center",
						y: "top",
						top: 10,
					},
					legend: {
						show: true,
						bottom: 0,
						x: "center",
						icon: "circle",
						textStyle: {
							fontSize: 14,
							color: "#fff",
						},
						formatter: function(name) {
							let target = "";
							for (var i = 0, l = list.length; i < l; i++) {
								if (list[i].name == name) {
									target = "     " + list[i].value + "人";
								}
							}
							return name + target;
						},
					},
					color: ["#2788F9", "#00E8FF", "#0085aa", "#3434F9", "#f2637b"],
					series: [{
						name: tname,
						type: "pie",
						radius: ["65%", "80%"],
						avoidLabelOverlap: false,
						emphasis: {
							label: {
								show: true,
								fontSize: "18",
								fontWeight: "bold",
								color: "#fff",
								position: "center",
								formatter: function(params) {
									return (
										params.name +
										"\r\n" +
										params.value +
										"人"
									);
								},
							},
						},
						label: {
							normal: {
								show: false,
								fontSize: 18,
								fontWeight: "bold",
								position: "center",
							},
						},
						labelLine: {
							show: false,
						},
						data: list,
					}, ],
				};

				option && myChart.setOption(option);
			},
		},
	};
</script>

<style scoped>
	.col-00E0FD {
		color: #00e0fd;
	}

	.col-42F3F9 {
		color: #42f3f9;
	}

	.operateClassN {
		width: 100%;
		height: 100%;
	}

	.headerT {
		color: #42f3f9;
		font-size: 20px;
		padding-left: 10px;
		border-left: 3px solid #42f3f9;
		margin-top: 10px;
	}

	.mgt-10 {
		margin-top: 10px;
	}

	.rowCent {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		box-sizing: border-box;
	}

	.rowCentRl {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.rowJCent {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.titleBg {
		min-width: 100px;
		height: 25px;
		background-color: rgba(105, 246, 175, 0.12);
		padding: 0 25px 0 15px;
		border-radius: 10px 0 20px 0;
		line-height: 25px;
		font-size: 15px;
		font-weight: 600;
		display: inline-block;
	}

	.titleBox {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: #00ff33;
	}

	.textOver {
		height: 150px;
		padding-top: 10px;
		line-height: 25px;
		letter-spacing: 3px;
		overflow-y: scroll;
		overflow-x: hidden;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
		box-sizing: border-box;
	}

	.textOver::-webkit-scrollbar {
		display: none;
	}

	.navList {
		width: 32%;
		height: 220px;
		background-image: url(../bgAll.png);
		background-size: 100% 100%;
		padding: 20px;
		box-sizing: border-box;
	}

	.navList1 {
		width: 65%;
		height: 220px;
		background-image: url(../bgAll.png);
		background-size: 100% 100%;
		padding: 20px;
		box-sizing: border-box;
	}

	.positionText {
		position: absolute;
		left: 0;
		top: 49px;
		width: 100%;
		text-align: center;
		font-size: 22px;
	}

	.positionText1 {
		position: absolute;
		left: 0;
		top: 40px;
		width: 100%;
		text-align: center;
		font-size: 22px;
	}

	.timeBg {
		width: 77px;
		height: 78px;
		background-image: url(../timeBg.png);
		background-size: 100% 100%;
		margin-right: 30px;
	}

	.leftZ {
		width: 100%;
		height: 290px;
		background-image: url(../bgAll.png);
		background-size: 100% 100%;
		padding: 20px;
		box-sizing: border-box;
	}

	.leftD {
		width: 100%;
		height: 255px;
		background-image: url(../bgAll.png);
		background-size: 100% 100%;
		padding: 20px;
		box-sizing: border-box;
	}

	.rightT {
		width: 100%;
		height: 210px;
		background-image: url(../bgAll.png);
		background-size: 100% 100%;
		padding: 20px;
		box-sizing: border-box;
	}

	.rightD {
		width: 100%;
		height: 565px;
		background-image: url(../bgAll.png);
		background-size: 100% 100%;
		padding: 20px;
		box-sizing: border-box;
	}

	.w100 {
		width: 100%;
		text-align: center;
		height: 30px;
	}

	.w100Bg {
		width: 100%;
		text-align: center;
		height: 30px;
		background-color: rgba(0, 232, 255, 0.08);
	}

	.w70 {
		width: 40%;
		height: 30px;
		line-height: 30px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.w30 {
		width: 20%;
		height: 30px;
		line-height: 30px;
	}

	.tableOver {
		width: 100%;
		height: 90px;
		overflow-y: scroll;
		overflow-x: hidden;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
		box-sizing: border-box;
	}

	.tableOver::-webkit-scrollbar {
		display: none;
	}

	.testBox {
		padding-top: 10px;
		letter-spacing: 2px;
		width: 100%;
		height: 155px;
		overflow-y: scroll;
		overflow-x: hidden;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
		box-sizing: border-box;
	}

	.testBox::-webkit-scrollbar {
		display: none;
	}

	.testList {
		width: 386px;
		height: 27px;
		line-height: 27px;
		color: rgba(255, 255, 255, 0.2);
		border-radius: 14px;
		margin: 0 auto;
		text-align: center;
		padding: 0 10px;
		box-sizing: border-box;
	}

	.active {
		background-color: rgba(255, 255, 255, 0.05);
		color: #00e0fd;
		font-size: 18px;
	}

	.noData {
		width: 200px;
		height: 130px;
		background-image: url(../noData.png);
		background-size: 100% 100%;
		margin: 0 auto;
	}

	.lookDetails {
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		position: fixed;
		left: 0;
		top: 0;
		z-index: 999;
	}

	.lookDetailsBox {
		width: 50%;
		height: 80vh;
		padding: 20px;
		background-color: #1B3662;
		margin: 0 auto;
		border-radius: 10px;
		overflow-y: scroll;
		overflow-x: hidden;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
	}

	.lookDetailsBox::-webkit-scrollbar {
		display: none;
	}

	.lookDetailsCont {
		width: 90%;
		margin: 10px auto 0;
		padding-top: 10px;
	}

	.optionClass {
		width: 100%;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		justify-content: space-around;
	}

	.xuanxiang {
		display: inline-block;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		border: 1px solid #fff;
		text-align: center;
		line-height: 20px;
	}

	.colActive {
		color: #1ACE1A;
	}

	.borderActive {
		border: 1px solid #1ACE1A !important;
	}

	.circleDot {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: rgba(255, 255, 255, 0.2);
		margin: 0 5px;
	}

	.circleActive {
		background-color: rgba(255, 255, 255, 0.7) !important;
	}
</style>
