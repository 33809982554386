<template>
	<div class="operateClassN">
		<el-row justify="space-between">
			<el-col :span="12">
				<div class="headerT">基本信息</div>
			</el-col>
			<el-col :span="12">
				<div class="centTopBox">
					<el-date-picker style="display: inline-block; width: 165px" v-model="dateTime" type="month" value-format="yyyy-MM"
					 :picker-options="pickerOptions" :clearable="false" placeholder="选择月" @change="changeTime"></el-date-picker>
					<span style="padding-left: 10px;color: #42f3f9;font-size: 17px;font-weight: bold;">{{ row.lineName }}信用评价</span>
				</div>
			</el-col>
		</el-row>
		<div class="rowCent rowCentRl">
			<!-- <div class="navList">
				<div class="rowCent">
					<div class="navListOne"></div>
					<div style="padding-left: 10px;">
						<div style="color: #42F3F9;font-size: 26px; padding-bottom: 8px;">{{list.driverNum ? list.driverNum : 0}}人</div>
						<div>驾驶员总数</div>
					</div>
				</div>
				<div style="height: 70px;width: 100%;"></div>
				<div style="border-top: 2px solid rgba(92,220,252,0.1);"></div>
			</div> -->
			<div class="navList">
				<div class="rowCent">
					<div class="navListOne">
						<img src="../navListOne.png" />
					</div>
					<div style="padding-left: 10px;">
						<div style="color: #42F3F9;font-size: 26px; padding-bottom: 8px;">{{list.courseCompletionRate ? list.courseCompletionRate : '0'}}%</div>
						<div>课程完成率</div>
					</div>
				</div>
				<div style="width: 100%;padding: 27px;">
					<el-progress :text-inside="true" :stroke-width="15" :percentage="list.courseCompletionRate ? list.courseCompletionRate : 0"></el-progress>
				</div>
				<div style="border-top: 2px solid rgba(92,220,252,0.1);"></div>
				<div class="rowCent rowCentRl">
					<div class="rowCent">
						<div>月同比</div>
						<div v-if="!list.courseYoy" style="padding: 0 10px;">{{list.courseYoy === 0 ? '0%' : '--'}}</div>
						<div v-if="list.courseYoy > 0" style="color: #15DD8F;"><i class="el-icon-caret-top" style="padding: 0 5px;"></i>{{list.courseYoy}}%</div>
						<div v-if="list.courseYoy < 0" style="color: #F7333D;"><i class="el-icon-caret-bottom" style="padding: 0 5px;"></i>{{list.courseYoy}}%</div>
					</div>
					<div class="rowCent">
						<div>月环比</div>
						<div v-if="!list.courseMom" style="padding: 0 10px;">{{list.courseMom === 0 ? '0%' : '--'}}</div>
						<div v-if="list.courseMom > 0" style="color: #15DD8F;"><i class="el-icon-caret-top" style="padding: 0 5px;"></i>{{list.courseMom}}%</div>
						<div v-if="list.courseMom < 0" style="color: #F7333D;"><i class="el-icon-caret-bottom" style="padding: 0 5px;"></i>{{list.courseMom}}%</div>
					</div>
				</div>
			</div>
			<div class="navList">
				<div class="rowCent">
					<div class="navListOne"><img src="../ceping.png" /></div>
					<div style="padding-left: 10px;">
						<div style="color: #42F3F9;font-size: 26px; padding-bottom: 8px;">{{list.mentalCompletionRate ? list.mentalCompletionRate : '0'}}%</div>
						<div>测评参与率</div>
					</div>
				</div>
				<div style="width: 100%;padding: 27px;">
					<el-progress :text-inside="true" :stroke-width="15" :percentage="list.mentalCompletionRate ? list.mentalCompletionRate : 0"></el-progress>
				</div>
				<div style="border-top: 2px solid rgba(92,220,252,0.1);"></div>
				<div class="rowCent rowCentRl">
					<div class="rowCent">
						<div>月同比</div>
						<div v-if="!list.mentalYoy" style="padding: 0 10px;">{{list.mentalYoy === 0 ? '0%' : '--'}}</div>
						<div v-if="list.mentalYoy > 0" style="color: #15DD8F;"><i class="el-icon-caret-top" style="padding: 0 5px;"></i>{{list.mentalYoy}}%</div>
						<div v-if="list.mentalYoy < 0" style="color: #F7333D;"><i class="el-icon-caret-bottom" style="padding: 0 5px;"></i>{{list.mentalYoy}}%</div>
					</div>
					<div class="rowCent">
						<div>月环比</div>
						<div v-if="!list.mentalMom" style="padding: 0 10px;">{{list.mentalMom === 0 ? '0%' : '--'}}</div>
						<div v-if="list.mentalMom > 0" style="color: #15DD8F;"><i class="el-icon-caret-top" style="padding: 0 5px;"></i>{{list.mentalMom}}%</div>
						<div v-if="list.mentalMom < 0" style="color: #F7333D;"><i class="el-icon-caret-bottom" style="padding: 0 5px;"></i>{{list.mentalMom}}%</div>
					</div>
				</div>
			</div>
			<div class="navList">
				<div class="rowCent">
					<div class="navListOne"><img src="../quanqing.png" /></div>
					<div style="padding-left: 10px;">
						<div style="color: #42F3F9;font-size: 26px; padding-bottom: 8px;">{{list.allDutyRate ? list.allDutyRate : '0'}}%</div>
						<div>全勤率</div>
					</div>
				</div>
				<div style="width: 100%;padding: 27px;">
					<el-progress :text-inside="true" :stroke-width="15" :percentage="list.allDutyRate ? list.allDutyRate : 0"></el-progress>
				</div>
				<div style="border-top: 2px solid rgba(92,220,252,0.1);"></div>
				<div class="rowCent rowCentRl">
					<div class="rowCent">
						<div>月同比</div>
						<div v-if="!list.allDutyRateYoy" style="padding: 0 10px;">{{list.allDutyRateYoy === 0 ? '0%' : '--'}}</div>
						<div v-if="list.allDutyRateYoy > 0" style="color: #15DD8F;"><i class="el-icon-caret-top" style="padding: 0 5px;"></i>{{list.allDutyRateYoy}}%</div>
						<div v-if="list.allDutyRateYoy < 0" style="color: #F7333D;"><i class="el-icon-caret-bottom" style="padding: 0 5px;"></i>{{list.allDutyRateYoy}}%</div>
					</div>
					<div class="rowCent">
						<div>月环比</div>
						<div v-if="!list.allDutyRateMom" style="padding: 0 10px;">{{list.allDutyRateMom === 0 ? '0%' : '--'}}</div>
						<div v-if="list.allDutyRateMom > 0" style="color: #15DD8F;"><i class="el-icon-caret-top" style="padding: 0 5px;"></i>{{list.allDutyRateMom}}%</div>
						<div v-if="list.allDutyRateMom < 0" style="color: #F7333D;"><i class="el-icon-caret-bottom" style="padding: 0 5px;"></i>{{list.allDutyRateMom}}%</div>
					</div>
				</div>
			</div>
			<div class="navList">
				<div class="rowCent">
					<div class="navListOne"><img src="../jingbao.png" /></div>
					<div style="padding-left: 10px;">
						<div style="color: #42F3F9;font-size: 26px; padding-bottom: 8px;">{{list.drivingBehaviorWarningNum ? list.drivingBehaviorWarningNum : '0'}}次</div>
						<div>驾驶行为预警次数</div>
					</div>
				</div>
				<div style="padding: 21px;width: 100%;">
					<img src="../yujing.png" style="width: 90%;height: 27px;display: block;">
				</div>
				<div style="border-top: 2px solid rgba(92,220,252,0.1);"></div>
				<div class="rowCent rowCentRl">
					<div class="rowCent">
						<div>月同比</div>
						<div v-if="!list.warningYoy" style="padding: 0 10px;">{{list.warningYoy === 0 ? '0%' : '--'}}</div>
						<div v-if="list.warningYoy > 0" style="color: #15DD8F;"><i class="el-icon-caret-top" style="padding: 0 5px;"></i>{{list.warningYoy}}%</div>
						<div v-if="list.warningYoy < 0" style="color: #F7333D;"><i class="el-icon-caret-bottom" style="padding: 0 5px;"></i>{{list.warningYoy}}%</div>
					</div>
					<div class="rowCent">
						<div>月环比</div>
						<div v-if="!list.warningMom" style="padding: 0 10px;">{{list.warningMom === 0 ? '0%' : '--'}}</div>
						<div v-if="list.warningMom > 0" style="color: #15DD8F;"><i class="el-icon-caret-top" style="padding: 0 5px;"></i>{{list.warningMom}}%</div>
						<div v-if="list.warningMom < 0" style="color: #F7333D;"><i class="el-icon-caret-bottom" style="padding: 0 5px;"></i>{{list.warningMom}}%</div>
					</div>
				</div>
			</div>
			<div class="navList">
				<div class="rowCent rowCentRl">
					<div>
						<div style="color: #42F3F9;font-size: 26px; padding-bottom: 8px;">{{list.violateNum ? list.violateNum : '0'}}次</div>
						<div>违法违规次数</div>
					</div>
					<div>
						<div class="rowCent">
							<div>月同比</div>
							<div v-if="!list.violateNumYoy" style="padding: 0 10px;">{{list.violateNumYoy === 0 ? '0%' : '--'}}</div>
							<div v-if="list.violateNumYoy > 0" style="color: #15DD8F;"><i class="el-icon-caret-top" style="padding: 0 5px;"></i>{{list.violateNumYoy}}%</div>
							<div v-if="list.violateNumYoy < 0" style="color: #F7333D;"><i class="el-icon-caret-bottom" style="padding: 0 5px;"></i>{{list.violateNumYoy}}%</div>
						</div>
						<div class="rowCent">
							<div>月环比</div>
							<div v-if="!list.violateNumMom" style="padding: 0 10px;">{{list.violateNumMom === 0 ? '0%' : '--'}}</div>
							<div v-if="list.violateNumMom > 0" style="color: #15DD8F;"><i class="el-icon-caret-top" style="padding: 0 5px;"></i>{{list.violateNumMom}}%</div>
							<div v-if="list.violateNumMom < 0" style="color: #F7333D;"><i class="el-icon-caret-bottom" style="padding: 0 5px;"></i>{{list.violateNumMom}}%</div>
						</div>
					</div>
				</div>
				<div style="border-top: 2px solid rgba(92,220,252,0.1);margin: 15px 0 20px;"></div>
				<div class="rowCent rowCentRl">
					<div>
						<div style="color: #42F3F9;font-size: 26px; padding-bottom: 8px;">{{list.serviceQualityNum ? list.serviceQualityNum : '0'}}次</div>
						<div>服务质量投诉次数</div>
					</div>
					<div>
						<div class="rowCent">
							<div>月同比</div>
							<div v-if="!list.serviceQualityNumYoy" style="padding: 0 10px;">{{list.serviceQualityNumYoy === 0 ? '0%' : '--'}}</div>
							<div v-if="list.serviceQualityNumYoy > 0" style="color: #15DD8F;"><i class="el-icon-caret-top" style="padding: 0 5px;"></i>{{list.serviceQualityNumYoy}}%</div>
							<div v-if="list.serviceQualityNumYoy < 0" style="color: #F7333D;"><i class="el-icon-caret-bottom" style="padding: 0 5px;"></i>{{list.serviceQualityNumYoy}}%</div>
						</div>
						<div class="rowCent">
							<div>月环比</div>
							<div v-if="!list.serviceQualityNumMom" style="padding: 0 10px;">{{list.serviceQualityNumMom === 0 ? '0%' : '--'}}</div>
							<div v-if="list.serviceQualityNumMom > 0" style="color: #15DD8F;"><i class="el-icon-caret-top" style="padding: 0 5px;"></i>{{list.serviceQualityNumMom}}%</div>
							<div v-if="list.serviceQualityNumMom < 0" style="color: #F7333D;"><i class="el-icon-caret-bottom" style="padding: 0 5px;"></i>{{list.serviceQualityNumMom}}%</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="rowCent rowCentRl">
			<div style="width: 40.4%;">
				<div class="summary">
					<div class="titleBg">
						<el-row type="flex" align="middle">
							<div class="titleBox"></div>
							<div class="pl-1">信用评价概述</div>
						</el-row>
					</div>
					<div class="col-00E0FD textOver">
						驾驶员信用评价报告根据六大维度进行综合评价，包括安全培训、违法违规、状态测评、驾驶行为、
						服务质量和考勤情况。{{row.lineName}}共有<span style="font-size: 20px;">{{list.driverNum ? list.driverNum : '0'}}</span>名驾驶员，
						其中处于AAA等级有<span style="font-size: 20px;">{{list1.aaaNum ? list1.aaaNum : '0'}}</span>人，AA等级的有<span style="font-size: 20px;">{{list1.aaNum ? list1.aaNum : '0'}}</span>人，
						A等级的人有<span style="font-size: 20px;">{{list1.aNum ? list1.aNum : '0'}}</span>人，B等级的人有<span style="font-size: 20px;">{{list1.bNum ? list1.bNum : '0'}}</span>人，
						F等级<span style="font-size: 20px;">{{list1.fNum ? list1.fNum : '0'}}</span>人。综合评分低于<span style="font-size: 20px;">80</span>分的驾驶员，建议对其加强安全教育与日常管理，以降低安全风险等级
					</div>
				</div>
				<div class="grade">
					<div class="titleBg">
						<el-row type="flex" align="middle">
							<div class="titleBox"></div>
							<div class="pl-1">驾驶员信用评价等级分布情况</div>
						</el-row>
					</div>
					<div id="main" style="width: 600px;height: 250px;display: block;margin-top: 20px;"></div>
				</div>
			</div>
			<div style="width: 59%;" class="follow">
				<div class="titleBg">
					<el-row type="flex" align="middle">
						<div class="titleBox"></div>
						<div class="pl-1">重点关注名单</div>
					</el-row>
				</div>
				<div style="width: 1px;height: 15px;"></div>
				<div class="tableClass">
					<vxe-table ref="table" border resizable :auto-resize="true" align="center" class="vxe-table-element" height="410"
					 row-id="ID" :sort-config="{remote: true}" :filter-config="{remote: true}" :checkbox-config="{reserve: true}"
					 :data="table.list" :loading="loading">
						<vxe-table-column title="姓名" field="realName" />
						<vxe-table-column title="电话" field="phone" />
						<vxe-table-column title="信用评分">
							<template v-slot="{ row }">
								{{row.creditScore ? row.creditScore : 0}}分
							</template>
						</vxe-table-column>
						<vxe-table-column title="课程完成率">
							<template v-slot="{ row }">
								<span @click="openLearning(row.userId)" style="color: #42f3f9;border-bottom: 1px solid #42f3f9;cursor: pointer;">
									{{
									    row.courseCompletionRate
									        ? row.courseCompletionRate
									        : "0%"
									}}
								</span>
							</template>
						</vxe-table-column>
						<vxe-table-column title="状态测评得分" show-overflow="title">
							<template v-slot="{ row }">
								<span @click="openEvaluation(row.id)" style="color: #42f3f9;border-bottom: 1px solid #42f3f9;cursor: pointer;">
									{{
									    row.detectionScore ? row.detectionScore : '0分'
									}}
								</span>
							</template>
						</vxe-table-column>
						<vxe-table-column title="驾驶行为预警次数" width="150">
							<template v-slot="{ row }">
								{{row.drivingBehaviorWarningNum ? row.drivingBehaviorWarningNum : 0}}次
							</template>
						</vxe-table-column>
						<vxe-table-column title="违法违规次数">
							<template v-slot="{ row }">
								{{row.violateNum ? row.violateNum : 0}}次
							</template>
						</vxe-table-column>
						<vxe-table-column title="服务质量投诉">
							<template v-slot="{ row }">
								{{row.serviceQualityNum ? row.serviceQualityNum : 0}}次
							</template>
						</vxe-table-column>
						<vxe-table-column title="考勤情况">
							<template v-slot="{ row }">
								<span v-if="row.isAttendance === 0">全勤</span>
								<span v-if="row.isAttendance == 1">未全勤</span>
								<span v-if="row.isAttendance == -1">无考勤</span>
							</template>
						</vxe-table-column>
					</vxe-table>
					<div style="height: 20px" />
					<el-pagination :current-page="table.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]" :page-size="table.pageSize"
					 layout="total, ->, prev, pager, next" :total="table.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
				</div>
			</div>
		</div>
		<div v-if="learningSituation" class="lookDetails" @click="closeAllBox($event)">
			<div class="lookDetailsBox">
				<el-row :gutter="20">
					<el-col :span="1">
						<div style="width: 1px;height: 1px;"></div>
					</el-col>
					<el-col :span="22">
						<div class="text-center" style="font-size: 18px;font-weight: bold;">学习情况</div>
					</el-col>
					<el-col :span="1">
						<i @click="closeLearning" class="el-icon-error" style="font-size: 22px;cursor: pointer;"></i>
					</el-col>
				</el-row>
				<div style="width: 90%;margin: 0 auto;font-size: 16px;">
					<el-row :gutter="22" style="padding-top: 10px;">
						<el-col :span="24">
							<div style="height: 25px;line-height: 25px;border-left: 3px solid #5CDCFC;padding-left: 6px;color: #5CDCFC;">基本情况</div>
						</el-col>
					</el-row>
					<el-row :gutter="22" style="padding-top: 10px;">
						<el-col :span="3">
							<div style="height: 30px;line-height: 30px;color: #5CDCFC;">姓 名:</div>
						</el-col>
						<el-col :span="9">
							<div style="height: 30px;line-height: 30px;">{{detailList.userName ? detailList.userName : '--'}}</div>
						</el-col>
						<el-col :span="3">
							<div style="height: 30px;line-height: 30px;color: #5CDCFC;">部 门:</div>
						</el-col>
						<el-col :span="9">
							<div style="height: 30px;line-height: 30px;">{{detailList.departName ? detailList.departName : '--'}}</div>
						</el-col>
					</el-row>
					<el-row :gutter="22" style="padding-top: 10px;">
						<el-col :span="3">
							<div style="height: 30px;line-height: 30px;color: #5CDCFC;">学习时长:</div>
						</el-col>
						<el-col :span="9">
							<div style="height: 30px;line-height: 30px;">{{detailList.studyLength ? detailList.studyLength : '--'}}</div>
						</el-col>
						<el-col :span="3">
							<div style="height: 30px;line-height: 30px;color: #5CDCFC;">课程完成率:</div>
						</el-col>
						<el-col :span="9">
							<div style="height: 30px;line-height: 30px;">{{detailList.complateRate ? detailList.complateRate : '--'}}%</div>
						</el-col>
					</el-row>
					<div v-for="(item,index) in safeCourses" :key="index">
						<el-row :gutter="22" style="padding-top: 10px;border-top: 1px solid rgba(255,255,255,0.3);">
							<el-col :span="3">
								<div style="height: 30px;line-height: 30px;color: #5CDCFC;">课程名称:</div>
							</el-col>
							<el-col :span="21">
								<div style="line-height: 30px;">{{item.courseName ? item.courseName : '--'}}</div>
							</el-col>
						</el-row>
						<el-row :gutter="22" style="padding-top: 10px;">
							<el-col :span="3">
								<div style="height: 30px;line-height: 30px;color: #5CDCFC;">考试成绩:</div>
							</el-col>
							<el-col :span="9">
								<div style="height: 30px;line-height: 30px;">{{item.testScore ? item.testScore : '--'}}</div>
							</el-col>
							<el-col :span="3">
								<div style="height: 30px;line-height: 30px;color: #5CDCFC;">完成时间:</div>
							</el-col>
							<el-col :span="9">
								<div style="height: 30px;line-height: 30px;">{{item.complateTime ? item.complateTime : '--'}}</div>
							</el-col>
						</el-row>
						<el-row :gutter="22" style="padding-top: 10px;">
							<el-col :span="3">
								<div style="height: 30px;line-height: 30px;color: #5CDCFC;">学习内容:</div>
							</el-col>
							<el-col :span="21">
								<div style="line-height: 30px;">{{item.courseIntroduce ? item.courseIntroduce : '--'}}</div>
							</el-col>
						</el-row>
					</div>
					<el-row :gutter="22" style="padding-top: 10px;">
						<el-col>
							<div style="height: 25px;line-height: 25px;border-left: 3px solid #5CDCFC;padding-left: 6px;color: #5CDCFC;">答题情况</div>
						</el-col>
					</el-row>
					<div v-if="listTopic1.length > 0" style="font-size: 16px;padding-top: 15px;color: #5CDCFC;">● 单选题</div>
					<div v-for="(item,index) in listTopic1" :key="index">
						<div style="padding-top: 15px;line-height: 25px;">
							<span style="color: #00be00;" v-if="JSON.stringify(item.correctOptions) === JSON.stringify(item.userOptions)">（√）</span>
							<span style="color: #ff5500;" v-if="JSON.stringify(item.correctOptions) !== JSON.stringify(item.userOptions)">（×）</span>
							{{item.topicTitle}}
						</div>
						<div class="optionClass">
							<div style="padding: 0 10px;line-height: 25px;" v-for="(items,indexs) in item.baseTopicOptions" :key="indexs">
								{{items.options}}, {{items.remarks}}
								<!-- <span class="xuanxiang" :class="{borderActive : items.isChoose === 0}">{{items.options}}</span>
							{{items.remarks}} -->
							</div>
						</div>
						<div style="color: #5CDCFC;padding-top: 20px;">
							正确答案：<span v-for="(items,indexs) in item.correctOptions" :key="indexs">{{items}}</span>
							您的答案：<span v-for="(items,indexs) in item.userOptions" :key="indexs">{{items}}</span>
						</div>
					</div>
					<div v-if="listTopic3.length > 0" style="font-size: 16px;padding-top: 15px;color: #5CDCFC;">● 判断题</div>
					<div v-for="(item,index) in listTopic3" :key="index">
						<div style="padding-top: 15px;line-height: 25px;">
							<span style="color: #00be00;" v-if="JSON.stringify(item.correctOptions) === JSON.stringify(item.userOptions)">（√）</span>
							<span style="color: #ff5500;" v-if="JSON.stringify(item.correctOptions) !== JSON.stringify(item.userOptions)">（×）</span>
							{{item.topicTitle}}
						</div>
						<div class="optionClass">
							<div style="padding: 0 10px;line-height: 25px;" v-for="(items,indexs) in item.baseTopicOptions" :key="indexs">
								{{items.options}}, {{items.remarks}}
							</div>
						</div>
						<div style="color: #5CDCFC;padding-top: 20px;">
							正确答案：<span v-for="(items,indexs) in item.correctOptions" :key="indexs">{{items}}</span>
							您的答案：<span v-for="(items,indexs) in item.userOptions" :key="indexs">{{items}}</span>
						</div>
					</div>
					<div v-if="listTopic2.length > 0" style="font-size: 16px;padding-top: 15px;color: #5CDCFC;">● 多选题</div>
					<div v-for="(item,index) in listTopic2" :key="index">
						<div style="padding-top: 15px;line-height: 25px;">
							<span style="color: #00be00;" v-if="JSON.stringify(item.correctOptions) === JSON.stringify(item.userOptions)">（√）</span>
							<span style="color: #ff5500;" v-if="JSON.stringify(item.correctOptions) !== JSON.stringify(item.userOptions)">（×）</span>
							{{item.topicTitle}}
						</div>
						<div class="optionClass">
							<div style="padding: 0 10px;line-height: 25px;" v-for="(items,indexs) in item.baseTopicOptions" :key="indexs">
								{{items.options}}, {{items.remarks}}
							</div>
						</div>
						<div style="color: #5CDCFC;padding-top: 20px;">
							正确答案：<span v-for="(items,indexs) in item.correctOptions" :key="indexs">{{items}}</span>
							您的答案：<span v-for="(items,indexs) in item.userOptions" :key="indexs">{{items}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="evaluation" class="lookDetails" @click="closeAllBox($event)">
			<div class="lookDetailsBox">
				<el-row :gutter="20">
					<el-col :span="1">
						<div style="width: 1px;height: 1px;"></div>
					</el-col>
					<el-col :span="22">
						<div class="text-center" style="color: #5CDCFC;font-size: 18px;font-weight: bold;">测评情况</div>
					</el-col>
					<el-col :span="1">
						<i @click="closeLearning" class="el-icon-error" style="font-size: 22px;color: #5CDCFC;cursor: pointer;"></i>
					</el-col>
				</el-row>
				<div class="lookDetailsCont" style="font-size: 16px;">
					<div style="border-left: 3px solid #5CDCFC;color: #5CDCFC;height: 16px;line-height: 16px;padding-left: 5px;font-size: 16px;">测评结果</div>
					<el-row :gutter="22" style="padding-top: 15px;">
						<el-col :span="3">
							<div style="height: 30px;line-height: 30px;color: #5CDCFC;">测评量表：</div>
						</el-col>
						<el-col :span="9">
							<div style="height: 30px;line-height: 30px;">{{testPaper.mentalTypeName ? testPaper.mentalTypeName : '--'}}</div>
						</el-col>
						<el-col :span="3">
							<div style="height: 30px;line-height: 30px;color: #5CDCFC;">真实性：</div>
						</el-col>
						<el-col :span="9">
							<div style="height: 30px;line-height: 30px;">{{testPaper.lieFeedbackResults ? testPaper.lieFeedbackResults : '--'}}</div>
						</el-col>
					</el-row>
					<el-row :gutter="22">
						<el-col :span="3">
							<div style="height: 30px;line-height: 30px;color: #5CDCFC;">作答用户：</div>
						</el-col>
						<el-col :span="9">
							<div style="height: 30px;line-height: 30px;">{{testPaper.realName ? testPaper.realName : '--'}}</div>
						</el-col>
						<el-col :span="3">
							<div style="height: 30px;line-height: 30px;color: #5CDCFC;">测评得分：</div>
						</el-col>
						<el-col :span="9">
							<div style="height: 30px;line-height: 30px;">{{testPaper.detectionScore ? testPaper.detectionScore + '分' : '--'}}</div>
						</el-col>
					</el-row>
					<el-row :gutter="22">
						<el-col :span="3">
							<div style="height: 30px;line-height: 30px;color: #5CDCFC;">作答时长：</div>
						</el-col>
						<el-col :span="9">
							<div style="height: 30px;line-height: 30px;">{{testPaper.answerTime ? testPaper.answerTime + '/分钟' : '--'}}</div>
						</el-col>
						<el-col :span="3">
							<div style="height: 30px;line-height: 30px;color: #5CDCFC;">提交时间：</div>
						</el-col>
						<el-col :span="9">
							<div style="height: 30px;line-height: 30px;">{{testPaper.submitTime ? testPaper.submitTime : '--'}}</div>
						</el-col>
					</el-row>
					<el-row :gutter="22" style="padding-bottom: 15px;">
						<el-col :span="3">
							<div style="height: 30px;line-height: 30px;color: #5CDCFC;">测评结果：</div>
						</el-col>
						<el-col :span="21">
							<span v-for="(item,index) in testPaper.evaluationResultsVos" :key="index" style="line-height: 25px;">
								<span style="color: #5CDCFC;">【{{item.feedbackResults}}】</span>
								<span>{{item.feedbackScheme}}</span>
							</span>
						</el-col>
					</el-row>
					<div style="border-left: 3px solid #5CDCFC;color: #5CDCFC;height: 16px;line-height: 16px;padding-left: 5px;font-size: 16px;">作答情况</div>
					<div style="padding-top: 10px;" v-if="subjects.length < 1">未参加测评</div>
					<div style="padding-top: 10px;line-height: 22px;" v-if="subjects.length > 0">
						{{testPaper.description}}
					</div>
					<div v-for="(item,index) in subjects" :key="index">
						<div style="line-height: 22px;padding-top: 10px;padding-bottom: 10px;">{{index + 1}}, {{item.topicTitle}}</div>
						<div class="optionClass">
							<div style="padding: 0 10px;" v-for="(items,indexs) in item.sos" :key="indexs">
								<div :class="{colActive : items.isChoose === 0}">
									<span class="xuanxiang" :class="{borderActive : items.isChoose === 0}">{{items.topicOptions}}</span>
									{{items.content}}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		bytesToFile
	} from "@/utils/handleByte";
	import {
		getYear,
		getMonth
	} from '@/utils/getDate'
	const echarts = require('echarts/lib/echarts');
	require('echarts/lib/chart/pie');
	require('echarts/lib/component/tooltip');
	require('echarts/lib/component/title');
	require('echarts/lib/component/dataZoom');
	require('echarts/lib/component/legend');
	export default {
		name: 'Operate',
		props: {
			row: {
				type: Object,
				default: () => {}
			},
			info: {
				type: Object,
				default: () => {}
			}
		},
		data() {
			return {
				pickerOptions: {
					disabledDate(v) {
						return v.getTime() > new Date().getTime() - 86400000;
					},
				},
				loading: false,
				dateTime: "",
				table: {
					list: [],
					total: 0,
					currentPage: 1,
					pageSize: 10,
				},
				list: {},
				list1: {},
				learningSituation: false,
				evaluation: false,
				testPaper: {},
				subjects: [],
				detailList: {},
				listTopic: [],
				listTopic1: [],
				listTopic2: [],
				listTopic3: [],
				safeCourses: []
			}
		},
		created() {
			const year = getYear();
			const month = getMonth();
			if (month > 0) {
				this.dateTime = year + '-' + month;
			} else {
				this.dateTime = (year - 1) + '-12';
			}
		},
		mounted() {
			this.monthCreditBaseInfo();
			this.monthFocusDriverVo();
			this.monthGradeDistribution();
		},
		methods: {
			openEvaluation(id) {
				if (!id) {
					this.$message.error('暂无状态测评');
					return false
				}
				this.evaluation = true;
				this.getSubjects(id);
				this.getTestPaper(id);
			},
			openLearning(id) {
				this.learningSituation = true;
				this.openBox(id)
			},
			closeLearning() {
				this.learningSituation = false;
				this.evaluation = false;
			},
			closeAllBox(o) {
				if (o.target.className == 'lookDetails') {
					this.learningSituation = false;
					this.evaluation = false;
				}
			},
			openBox(id) {
				this.listTopic = [];
				this.listTopic1 = [];
				this.listTopic2 = [];
				this.listTopic3 = [];
				this.safeCourses = [];
				this.$axiosReq('/course/server/escort/web/statistics/safe/score/detail', null, {
					queryTime: this.dateTime,
					userId: id,
				}, 'get').then(res => {
					if (res.data) {
						this.detailList = res.data;
					}
					if (res.data.safeCourses && res.data.safeCourses.length > 0) {
						this.safeCourses = res.data.safeCourses;
					} else {
						this.safeCourses = [];
					}
					if (res.data.topicInfos && res.data.topicInfos.length > 0) {
						this.listTopic = res.data.topicInfos;
						this.listTopic.forEach((item) => {
							if (item.topicType === 0) {
								this.listTopic1.push(item);
							} else if (item.topicType === 1) {
								this.listTopic2.push(item);
							} else if (item.topicType === 2) {
								this.listTopic3.push(item);
							}
						});
					} else {
						this.listTopic = [];
					}
				}).finally(e => {
					this.loading = false
				})
			},
			getTestPaper(id) {
				let that_ = this;
				that_.testPaper = {};
				this.$axiosReq(
					"/course/server/escort/web/mentalUser/testPaper/" + id,
					null, null,
					"get"
				).then((res) => {
					if (res.data) {
						that_.testPaper = res.data;
					}
				});
			},
			getSubjects(id) {
				let that_ = this;
				that_.subjects = [];
				this.$axiosReq(
					"/course/server/escort/web/mentalUser/subjects/" + id,
					null, null,
					"get"
				).then((res) => {
					if (
						res.data &&
						res.data.length > 0
					) {
						that_.subjects = res.data;
					}
				});
			},
			exportList() {
				this.loading = true;
				this.$axiosReq('/archives/server/escort/web/lineCredit/monthLineExport', null, {
						date: this.dateTime,
						companyId: this.info.companyId,
						lineId: this.row.lineId,
					}, "get")
					.then((res) => {
						bytesToFile(res.data.bytes, res.data.fileName);
					})
					.finally((e) => {
						this.loading = false;
					});
			},
			changeTime(v) {
				this.$emit('childByValue', v);
				this.table.currentPage = 1;
				this.table.total = 0;
				this.table.list = [];
				this.monthCreditBaseInfo();
				this.monthFocusDriverVo();
				this.monthGradeDistribution();
			},
			monthCreditBaseInfo() {
				let that_ = this;
				this.$axiosReq(
					"/archives/server/escort/web/lineCredit/monthCreditBaseInfo",
					null, {
						date: this.dateTime,
						companyId: this.info.companyId,
						lineId: this.row.lineId,
					},
					"get"
				).then((res) => {
					that_.list = res.data;
					if (res.data.driverNum) {
						this.$emit('childDepartNum', res.data.driverNum)
					} else {
						this.$emit('childDepartNum', 0)
					}
				});
			},
			monthFocusDriverVo() {
				let that_ = this;
				this.$axiosReq(
					"/archives/server/escort/web/lineCredit/monthFocusDriverVo",
					null, {
						pageSize: this.table.pageSize,
						currentPage: this.table.currentPage,
						date: this.dateTime,
						companyId: this.info.companyId,
						lineId: this.row.lineId,
					},
					"get"
				).then((res) => {
					if (
						res.data.content &&
						res.data.content.length > 0
					) {
						that_.table.list = res.data.content;
						that_.table.total = Number(
							res.data.totalElements
						);
					}
				});
			},
			monthGradeDistribution() {
				let that_ = this;
				let arr = [];
				this.$axiosReq('/archives/server/escort/web/lineCredit/monthGradeDistribution', null, {
					date: this.dateTime,
					companyId: this.info.companyId,
					lineId: this.row.lineId,
				}, 'get').then(res => {
					that_.list1 = res.data;
					arr.push({
						value: res.data.aaaNum ? res.data.aaaNum : 0,
						name: 'AAA',
						percentage: res.data.aaaNumRatio ? res.data.aaaNumRatio + '%' : '0%'
					})
					arr.push({
						value: res.data.aaNum ? res.data.aaNum : 0,
						name: 'AA',
						percentage: res.data.aaNumRatio ? res.data.aaNumRatio + '%' : '0%'
					})
					arr.push({
						value: res.data.aNum ? res.data.aNum : 0,
						name: 'A',
						percentage: res.data.aNumRatio ? res.data.aNumRatio + '%' : '0%'
					})
					arr.push({
						value: res.data.bNum ? res.data.bNum : 0,
						name: 'B',
						percentage: res.data.bNumRatio ? res.data.bNumRatio + '%' : '0%'
					})
					arr.push({
						value: res.data.fNum ? res.data.fNum : 0,
						name: 'F',
						percentage: res.data.fNumRatio ? res.data.fNumRatio + '%' : '0%'
					})
					this.echartsMain(arr);
				})
			},
			handleSizeChange(pageSize) {
				this.table.pageSize = pageSize
				this.table.currentPage = 1
				this.monthFocusDriverVo()
			},
			handleCurrentChange(currentPage) {
				this.table.currentPage = currentPage
				this.monthFocusDriverVo()
			},
			echartsMain(list) {
				let chartDom = document.getElementById('main');
				let myChart = echarts.init(chartDom);
				let option;
				let that_ = this;

				option = {
					legend: {
						show: true,
						orient: 'vertical',
						right: 0,
						y: 'center',
						icon: 'circle',
						textStyle: {
							fontSize: 14,
							color: '#fff'
						},
						formatter: function(name) {
							let target;
							for (var i = 0, l = list.length; i < l; i++) {
								if (list[i].name == name) {
									target = ' | ' + list[i].percentage + '  ' + list[i].value + '人';
								}
							}
							return name + '等级' + target
						},
					},
					color: ['#2788F9', '#00E8FF', '#00C6FF', '#3434F9', '#f2637b'],
					series: [{
						name: '评价等级分布情况',
						type: 'pie',
						radius: ['63%', '80%'],
						avoidLabelOverlap: false,
						emphasis: {
							label: {
								show: true,
								fontSize: '18',
								fontWeight: 'bold',
								color: '#fff',
								position: 'center',
								formatter: function(params) {
									return params.name + '等级' + '\r\n' + params.value + '人';
								}
							}
						},
						label: {
							normal: {
								show: false,
								fontSize: 18,
								fontWeight: 'bold',
								position: 'center',
							}
						},
						labelLine: {
							show: false
						},
						data: list
					}]
				};

				option && myChart.setOption(option);
			},
			handleParentClick(v) {
				this.dateTime = v;
				this.table.currentPage = 1
				this.table.total = 0;
				this.table.list = [];
				this.monthCreditBaseInfo();
				this.monthFocusDriverVo();
				this.monthGradeDistribution();
			}
		}
	}
</script>

<style scoped>
	.col-00E0FD {
		color: #00E0FD;
	}

	.operateClassN {
		width: 100%;
		height: 100%;
	}

	.headerT {
		color: #42F3F9;
		font-size: 20px;
		padding-left: 10px;
		border-left: 3px solid #42F3F9;
		margin-top: 10px;
	}

	.rowCent {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		margin-top: 10px;
		box-sizing: border-box;
	}

	.rowCentRl {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.navList {
		width: 19.5%;
		height: 210px;
		background-image: url(../bgAll.png);
		background-size: 100% 100%;
		padding: 20px;
		box-sizing: border-box;
	}

	.navListOne {
		width: 59px;
		height: 59px;
	}

	.navListOne img {
		width: 100%;
		height: 100%;
		display: block;
	}

	.summary {
		width: 100%;
		height: 153px;
		background-image: url(../bgAll.png);
		background-size: 100% 100%;
		padding: 10px;
		box-sizing: border-box;
	}

	.grade {
		width: 100%;
		height: 437px;
		background-image: url(../bgAll.png);
		background-size: 100% 100%;
		margin-top: 10px;
		padding: 10px;
		box-sizing: border-box;
	}

	.follow {
		width: 100%;
		height: 600px;
		background-image: url(../bgAll.png);
		background-size: 100% 100%;
		padding: 10px;
		box-sizing: border-box;
	}

	.titleBg {
		min-width: 100px;
		height: 25px;
		background-color: rgba(105, 246, 175, 0.12);
		padding: 0 25px 0 15px;
		border-radius: 10px 0 20px 0;
		line-height: 25px;
		font-size: 15px;
		font-weight: 600;
		display: inline-block;
	}

	.titleBox {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: #00FF33;
	}

	.textOver {
		height: 106px;
		padding-top: 10px;
		line-height: 25px;
		letter-spacing: 0.5px;
		overflow-y: scroll;
		overflow-x: hidden;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
	}

	.textOver::-webkit-scrollbar {
		display: none;
	}

	.noData {
		width: 200px;
		height: 130px;
		background-image: url(../noData.png);
		background-size: 100% 100%;
		margin: 0 auto;
	}

	.centTopBox {
		text-align: right;
	}

	.lookDetails {
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		position: fixed;
		left: 0;
		top: 0;
		z-index: 999;
	}

	.lookDetailsBox {
		width: 60%;
		height: 80vh;
		padding: 20px;
		background-color: #1B3662;
		margin: 0 auto;
		border-radius: 10px;
		overflow-y: scroll;
		overflow-x: hidden;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
	}

	.lookDetailsBox::-webkit-scrollbar {
		display: none;
	}

	.lookDetailsCont {
		width: 90%;
		margin: 10px auto 0;
		padding-top: 10px;
	}

	.optionClass {
		width: 100%;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		justify-content: space-around;
	}

	.xuanxiang {
		display: inline-block;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		border: 1px solid #fff;
		text-align: center;
		line-height: 20px;
	}

	.colActive {
		color: #1ACE1A;
	}

	.borderActive {
		border: 1px solid #1ACE1A !important;
	}
</style>
