<template>
	<div class="operateClass">
		<div style="position: fixed;right: 6px;top: 6px;z-index: 100;color: #FFFFFF;font-size: 25px;" @click="close"><i class="el-icon-error"></i></div>
		<div class="headerBg"></div>
		<el-row type="flex" justify="space-between" style="color: #FFFFFF;">
			<el-col :span="8">
				<div class="bgBox">
					<div class="titleBg">
						<el-row type="flex" align="middle">
							<div class="titleBox"></div>
							<div class="pl-1">综合评分趋势</div>
						</el-row>
					</div>
					<div id="main" style="width: 90%;height: 300px;display: block;margin: 0 auto;"></div>
				</div>
				<div class="bgBox">
					<div class="titleBg">
						<el-row type="flex" align="middle">
							<div class="titleBox"></div>
							<div class="pl-1">安全风险详解-服务质量</div>
						</el-row>
					</div>
					<el-row type="flex" align="middle">
						<div style="background-color: #0E426C;padding: 10px;margin: 10px 20px;color: #69F1F6;">
							<span style="font-size: 30px;">{{complaintCountSum}}</span>
							<span>次</span>
						</div>
						<div style="color: #69F1F6;">服务质量发生次数</div>
					</el-row>
					<div id="main2" style="width: 100%;height: 235px;display: block;margin: 0 auto;"></div>
					<div style="text-align: center;">服务质量投诉事件分布</div>
				</div>
				<div class="bgBox">
					<div class="titleBg">
						<el-row type="flex" align="middle">
							<div class="titleBox"></div>
							<div class="pl-1">安全风险详解-状态测评</div>
						</el-row>
					</div>
					<div id="main3" style="width: 100%;height: 235px;display: block;margin: 0 auto;"></div>
					<div style="text-align: center;">状态测评得分走势</div>
				</div>
			</el-col>
			<el-col :span="8">
				<div class="centTopBox">
					<el-row type="flex" justify="space-between" align="middle" style="padding: 30px 0;">
						<el-col :span="4" style="color: yellow;"></el-col>
						<el-col :span="16">
							<span>时间：</span>
							<el-date-picker style="width: 290px;background-color: rgba(255, 255, 255, 0.2);" v-model="dateTime" type="monthrange"
							 class="input searchInput" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :unlink-panels="true"
							 :picker-options="pickerOptions" value-format="yyyy-MM" :clearable="false" @change="changeCondition" />
						</el-col>
						<el-col :span="4"></el-col>
					</el-row>
					<el-row type="flex" justify="space-between" align="middle" style="font-size: 16px;">
						<el-col :span="8">
							<div style="background-color: #1B386C;width: 100px;height: 110px;margin: 0 auto;">
								<div style="font-size: 30px;color: #00BFF5;padding-top: 20px;">{{drivingExperience}}年</div>
								<div style="color: #477EDF;padding-top: 10px;">驾龄</div>
							</div>
						</el-col>
						<el-col :span="8">
							<div v-if="headUrl" class="centTopBoxImg"><img :src="$fileUrl + headUrl"></div>
							<div v-if="!headUrl" class="centTopBoxImg"><img src="../../../../assets/user.png"></div>
							<div style="font-size: 25px;color: #00BFF5;font-weight: 530;padding-top: 10px;">{{realName}}</div>
						</el-col>
						<el-col :span="8">
							<div style="background-color: #1B386C;width: 100px;height: 110px;margin: 0 auto;">
								<div style="font-size: 30px;color: #00BFF5;padding-top: 20px;">{{busDriver}}年</div>
								<div style="color: #477EDF;padding-top: 10px;">公交驾龄</div>
							</div>
						</el-col>
					</el-row>
					<el-row type="flex" justify="space-between" align="middle" style="padding: 80px 0 0;font-size: 16px;">
						<el-col :span="8">
							<span style="color: #477EDF;">部门：</span>
							<span style="color: #00BFF5;font-weight: 600;">{{departName ? departName : '--'}}</span>
						</el-col>
						<el-col :span="8">
							<span style="color: #477EDF;font-size: 16px;">电话：</span>
							<span style="color: #00BFF5;font-weight: 600;">{{phone ? phone : '--'}}</span>
						</el-col>
						<el-col :span="8">
							<span style="color: #477EDF;font-size: 16px;">岗位：</span>
							<span style="color: #00BFF5;font-weight: 600;">{{positionName ? positionName : '--'}}</span>
						</el-col>
					</el-row>
				</div>
				<div class="bgBox1">
					<div class="titleBg">
						<el-row type="flex" align="middle">
							<div class="titleBox"></div>
							<div class="pl-1">安全风险详解-驾驶行为</div>
						</el-row>
					</div>
					<!-- <div id="main4" style="width: 100%;height: 580px;display: block;margin: 0 auto;"></div> -->
					<div style="width: 10%;height: 100px;"></div>
					<div class="noData"></div>
					<div style="width: 10%;height: 360px;"></div>
				</div>
			</el-col>
			<el-col :span="8">
				<div class="bgBox2">
					<div class="titleBg">
						<el-row type="flex" align="middle">
							<div class="titleBox"></div>
							<div class="pl-1">安全风险详解-安全培训</div>
						</el-row>
					</div>
					<el-row type="flex" align="middle">
						<div style="background-color: #0E426C;padding: 10px;margin: 10px 20px;color: #69F1F6;">
							<span style="font-size: 30px;">{{countTime}}</span>
							<span>h</span>
						</div>
						<div style="color: #69F1F6;">{{startTime}}月至{{endTime}}月学习总时长</div>
					</el-row>
					<div id="main1" style="width: 100%;height: 210px;display: block;margin: 0 auto;"></div>
				</div>
				<div class="bgBox2">
					<div class="titleBg">
						<el-row type="flex" align="middle">
							<div class="titleBox"></div>
							<div class="pl-1">安全风险详解-违法违规</div>
						</el-row>
					</div>
					<el-row type="flex" align="middle" style="margin: 8px 0;">
						<div class="navBg">
							<div>
								<div style="color: #00BFF5;">
									<span style="font-size: 26px;font-weight: 500;">{{violateCount}}</span>
									<span>次</span>
								</div>
								<div style="color: #477EDF;padding-top: 10px;">累计违法违规次数</div>
							</div>
						</div>
						<div class="navBg">
							<div>
								<div style="color: #00BFF5;">
									<span style="font-size: 26px;font-weight: 500;">{{deductSafeKilometerSum}}</span>
									<span>公里</span>
								</div>
								<div style="color: #477EDF;padding-top: 10px;">累计扣除安全公里数</div>
							</div>
						</div>
					</el-row>
					<el-row type="flex" align="middle" style="text-align: center;background-color: #0E426C;height: 35px;color: #69F1F6;margin-bottom: 5px;">
						<el-col :span="6">时间</el-col>
						<el-col :span="6">类型</el-col>
						<el-col :span="12">事件</el-col>
					</el-row>
					<div class="tableBox" @mouseenter="clearAryTime" @mouseleave="changeArr">
						<div v-if="showNow" v-for="(item,index) in tableData1" :key="index">
							<el-row type="flex" align="middle" style="text-align: center;min-height: 35px;background-color: #0E426C;" v-if="index%2 != 0">
								<el-col :span="6">{{item.violateTime}}</el-col>
								<el-col :span="6">{{item.violateTypeName}}</el-col>
								<el-col :span="12">
									<div style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis;" @mouseover="mouseoverNow(index)">{{item.violateEvent}}</div>
									<div v-if="testShowNum === index" @mouseout="changeArr" class="testShowClass">{{item.violateEvent}}</div>
								</el-col>
							</el-row>
							<el-row type="flex" align="middle" style="text-align: center;min-height: 35px;" v-if="index%2 == 0">
								<el-col :span="6">{{item.violateTime}}</el-col>
								<el-col :span="6">{{item.violateTypeName}}</el-col>
								<el-col :span="12">
									<div style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis;" @mouseover="mouseoverNow(index)">{{item.violateEvent}}</div>
									<div v-if="testShowNum === index" @mouseout="changeArr" class="testShowClass">{{item.violateEvent}}</div>
								</el-col>
							</el-row>
						</div>
						<div v-if="!showNow" v-for="(item,index) in tableData1" :key="index">
							<el-row type="flex" align="middle" style="text-align: center;min-height: 35px;" v-if="index%2 != 0">
								<el-col :span="6">{{item.violateTime}}</el-col>
								<el-col :span="6">{{item.violateTypeName}}</el-col>
								<el-col :span="12">
									<div style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis;" @mouseover="mouseoverNow(index)">{{item.violateEvent}}</div>
									<div v-if="testShowNum === index" @mouseout="changeArr" class="testShowClass">{{item.violateEvent}}</div>
								</el-col>
							</el-row>
							<el-row type="flex" align="middle" style="text-align: center;min-height: 35px;background-color: #0E426C;" v-if="index%2 == 0">
								<el-col :span="6">{{item.violateTime}}</el-col>
								<el-col :span="6">{{item.violateTypeName}}</el-col>
								<el-col :span="12">
									<div style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis;" @mouseover="mouseoverNow(index)">{{item.violateEvent}}</div>
									<div v-if="testShowNum === index" @mouseout="changeArr" class="testShowClass">{{item.violateEvent}}</div>
								</el-col>
							</el-row>
						</div>
						<div v-if="tableData1.length < 1" class="noData"></div>
					</div>
				</div>
				<div class="bgBox2">
					<div class="titleBg">
						<el-row type="flex" align="middle">
							<div class="titleBox"></div>
							<div class="pl-1">安全风险详解-奖惩情况</div>
						</el-row>
					</div>
					<el-row type="flex" align="middle" style="margin: 8px 0;">
						<div class="navBg">
							<div>
								<div style="color: #00BFF5;">
									<span style="font-size: 26px;font-weight: 500;">{{totalPunishment}}</span>
									<span>次</span>
								</div>
								<div style="color: #477EDF;padding-top: 10px;">累计处罚情况</div>
							</div>
						</div>
						<div class="navBg">
							<div>
								<div style="color: #00BFF5;">
									<span style="font-size: 26px;font-weight: 500;">{{totalReward}}</span>
									<span>次</span>
								</div>
								<div style="color: #477EDF;padding-top: 10px;">累计奖励情况</div>
							</div>
						</div>
					</el-row>
					<el-row type="flex" align="middle" style="text-align: center;background-color: #0E426C;height: 35px;color: #69F1F6;margin-bottom: 5px;">
						<el-col :span="6">时间</el-col>
						<el-col :span="6">类型</el-col>
						<el-col :span="12">事件</el-col>
					</el-row>
					<div class="tableBox" @mouseenter="clearAryTime1" @mouseleave="changeArr1">
						<div v-if="showNow1" v-for="(item,index) in tableData3" :key="index">
							<el-row type="flex" align="middle" style="text-align: center;min-height: 35px;background-color: #0E426C;" v-if="index%2 != 0">
								<el-col :span="6">{{item.rewardPunishmentTime}}</el-col>
								<el-col :span="12">{{item.rewardPunishmentTypeName}}</el-col>
								<el-col :span="6">
									<div style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis;" @mouseover="mouseoverNow1(index)">{{item.event}}</div>
									<div v-if="testShowNum1 === index" @mouseout="changeArr1" class="testShowClass">{{item.event}}</div>
								</el-col>
							</el-row>
							<el-row type="flex" align="middle" style="text-align: center;min-height: 35px;" v-if="index%2 == 0">
								<el-col :span="6">{{item.rewardPunishmentTime}}</el-col>
								<el-col :span="12">{{item.rewardPunishmentTypeName}}</el-col>
								<el-col :span="6">
									<div style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis;" @mouseover="mouseoverNow1(index)">{{item.event}}</div>
									<div v-if="testShowNum1 === index" @mouseout="changeArr1" class="testShowClass">{{item.event}}</div>
								</el-col>
							</el-row>
						</div>
						<div v-if="!showNow1" v-for="(item,index) in tableData3" :key="index">
							<el-row type="flex" align="middle" style="text-align: center;min-height: 35px;" v-if="index%2 != 0">
								<el-col :span="6">{{item.rewardPunishmentTime}}</el-col>
								<el-col :span="12">{{item.rewardPunishmentTypeName}}</el-col>
								<el-col :span="6">
									<div style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis;" @mouseover="mouseoverNow1(index)">{{item.event}}</div>
									<div v-if="testShowNum1 === index" @mouseout="changeArr1" class="testShowClass">{{item.event}}</div>
								</el-col>
							</el-row>
							<el-row type="flex" align="middle" style="text-align: center;min-height: 35px;background-color: #0E426C;" v-if="index%2 == 0">
								<el-col :span="6">{{item.rewardPunishmentTime}}</el-col>
								<el-col :span="12">{{item.rewardPunishmentTypeName}}</el-col>
								<el-col :span="6">
									<div style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis;" @mouseover="mouseoverNow1(index)">{{item.event}}</div>
									<div v-if="testShowNum1 === index" @mouseout="changeArr1" class="testShowClass">{{item.event}}</div>
								</el-col>
							</el-row>
						</div>
						<div v-if="tableData3.length < 1" class="noData"></div>
					</div>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	const echarts = require('echarts/lib/echarts');
	require('echarts/lib/chart/line');
	require('echarts/lib/chart/bar');
	require('echarts/lib/component/tooltip');
	require('echarts/lib/component/title');
	require('echarts/lib/component/dataZoom');
	import {
		company,
	} from '@/api'
	import {
		getYear,
		getMonth
	} from '@/utils/getDate'
	export default {
		name: 'Operate',
		props: {
			row: {
				type: Object,
				default: () => {}
			}
		},
		data() {
			return {
				pickerOptions: {
					disabledDate(v) {
						return v.getTime() > new Date().getTime() - 86400000;
					}
				},
				dateTime: [],
				startTime: "",
				endTime: "",
				tableData: [],
				tableData1: [],
				busDriver: '0',
				departName: '',
				driverLever: '',
				drivingExperience: '0',
				phone: '',
				positionName: '',
				realName: '',
				countTime: '0',
				headUrl: '',
				testShowNum: null,
				testShowNum1: null,
				totalPunishment: '0',
				totalReward: '0',
				tableData3: [],
				deductSafeKilometerSum: '0',
				violateCount: '0',
				aryTime: null,
				showNow: true,
				aryTime1: null,
				showNow1: true,
				complaintCountSum: '0',
				// wspath: "ws://192.168.88.59:18088/ws",
				wspath: "wss://www.zdwy.online/ws",
				wsUserId: "",
			}
		},
		created() {
			this.wsUserId = JSON.parse(sessionStorage.UserDetail).id;
			const year = getYear()
			const month = getMonth();
			if (month > 0) {
				this.dateTime = [year + '-' + month, year + '-' + month];
				this.startTime = year + '-' + month;
				this.endTime = year + '-' + month;
			} else {
				this.dateTime = [(year - 1) + '-12', (year - 1) + '-12'];
				this.startTime = (year-1) + '-12';
				this.endTime = (year-1) + '-12';
			}
		},
		mounted() {
			this.getWebSocket();
			this.singleCreditEvaluate();
			this.getViolate();
			this.getCredit();
			this.getMonthlyMental();
			this.getCount();
			this.safeTrainingTopics();
			this.pageCrossMonthRewardPunishment();
			// this.cccccc('main4')
		},
		methods: {
			getWebSocket() {
			    var that_ = this;
			    if (typeof WebSocket === "undefined") {
			        this.$message.warning("您的浏览器不支持socket");
			    } else {
			        // 实例化socket
			        this.socket = new WebSocket(that_.wspath);
			        // 监听socket连接
			        this.socket.onopen = this.open;
			        // 监听socket错误信息
			        this.socket.onerror = this.error;
			        // 监听socket消息
			        this.socket.onmessage = this.getMessage;
			        // 监听socket关闭
			        // this.socket.onclose = this.close
			    }
			},
			open() {
			    this.send();
			},
			error() {
			    console.log("WebSocket连接错误");
			},
			getMessage(msg) {
			    var dataList = JSON.parse(msg.data);
			    console.log(dataList);
			    if (dataList.data && dataList.data.type == 998) {
			        // this.$message.success(dataList.data.content);
					let listName = [];
					let listData = [];
					if (dataList.data.content && dataList.data.content.length > 0) {
						dataList.data.content.forEach(item => {
							listName.push(item.creditDate);
							listData.push(item.totalScore);
						})
						this.aaaaa('main', "#14CCA4", null, listName, listData, '分', '20,204,164')
					} else {
						this.aaaaa('main', "#14CCA4", null, [], [], '分', '20,204,164')
					}
			    }
			},
			send() {
			    var params = {
			        userId: this.wsUserId,
			        type: "web",
			    };
			    this.socket.send(JSON.stringify(params));
			    console.log("WebSocket连接");
			},
			close() {
			    console.log("socket已经关闭");
			    this.getWebSocket();
			},
			mouseoverNow(num) {
				this.testShowNum = num;
			},
			mouseoverNow1(num) {
				this.testShowNum1 = num;
			},
			changeCondition() {
				this.startTime = '';
				this.endTime = '';
				if (this.dateTime) {
					this.startTime = this.dateTime[0];
					this.endTime = this.dateTime[1];
				}
				this.singleCreditEvaluate();
				this.getViolate();
				this.getCredit();
				this.getMonthlyMental();
				this.getCount();
				this.safeTrainingTopics();
				this.pageCrossMonthRewardPunishment();
			},
			pageCrossMonthRewardPunishment() {
				this.$axiosReq('/archives/server/escort/web/rewardPunishment/pageCrossMonthRewardPunishment', null, {
					userId: this.row.userId,
					startDate: this.startTime,
					endDate: this.endTime,
					pageSize: 1000
				}, 'get').then(res => {
					// console.log(JSON.stringify(res))
					this.totalPunishment = res.data.totalPunishment ? res.data.totalPunishment : '0';
					this.totalReward = res.data.totalReward ? res.data.totalReward : '0';
					if (res.data.baseRewardPunishments && res.data.baseRewardPunishments.content.length > 0) {
						this.tableData3 = res.data.baseRewardPunishments.content;
						if (this.tableData3.length > 3) {
							this.changeArr1();
						}
					}
				})
			},
			singleCreditEvaluate() {
				let listName = [];
				let listData = [];
				this.$axiosReq('/archives/server/escort/web/creditEvaluate/acrossCreditEvaluate', null, {
					userId: this.row.userId,
					startMonth: this.startTime,
					endMonth: this.endTime,
				}, 'get').then(res => {
					if (res.data && res.data.length > 0) {
						res.data.forEach(item => {
							listName.push(item.creditDate);
							listData.push(item.totalScore);
						})
						this.aaaaa('main', "#14CCA4", null, listName, listData, '分', '20,204,164')
					} else {
						this.aaaaa('main', "#14CCA4", null, [], [], '分', '20,204,164')
					}
				})
			},
			getViolate() {
				this.$axiosReq('/archives/server/escort/web/violate/credit', null, {
					userId: this.row.userId,
					startMonth: this.startTime,
					endMonth: this.endTime,
					pageSize: 1000
				}, 'get').then(res => {
					this.deductSafeKilometerSum = res.data.deductSafeKilometerSum ? res.data.deductSafeKilometerSum : '0';
					this.violateCount = res.data.violateCount ? res.data.violateCount : '0';
					if (res.data.pageVo && res.data.pageVo.content && res.data.pageVo.content.length > 0) {
						this.tableData1 = res.data.pageVo.content;
						if (this.tableData1.length > 3) {
							this.changeArr();
						}
					} else {
						this.tableData1 = [];
					}
				})
			},
			getCredit() {
				this.$axiosReq('/archives/server/escort/web/archives/credit/' + this.row.userId, null, null, 'get').then(res => {
					this.busDriver = res.data.busDriver ? res.data.busDriver : '0';
					this.departName = res.data.departName ? res.data.departName : '';
					this.drivingExperience = res.data.drivingExperience ? res.data.drivingExperience : '0';
					this.phone = res.data.phone ? res.data.phone : '';
					this.realName = res.data.realName ? res.data.realName : '';
					this.headUrl = res.data.headUrl ? res.data.headUrl : '';
					this.positionName = res.data.positionName ? res.data.positionName : '';
				})
			},
			getMonthlyMental() {
				let listName = [];
				let listData = [];
				this.$axiosReq('/course/server/escort/web/mentalUser/getMonthlyMental', null, {
					userId: this.row.userId,
					startMonth: this.startTime,
					endMonth: this.endTime,
				}, 'get').then(res => {
					if (res.data && res.data.length > 0) {
						res.data.forEach(item => {
							listName.push(item.testTime);
							listData.push(item.detectionScore);
						})
						this.bbbbbb('main3', "#3ED7F8", null, listName, listData, '分数')
					} else {
						this.bbbbbb('main3', "#3ED7F8", null, [], [], '分数')
					}
				})
			},
			getCount() {
				let listName = [];
				let listData = [];
				this.$axiosReq('/archives/server/escort/web/serviceQuality/manyMonth/getCount', null, {
					userId: this.row.userId,
					startMonth: this.startTime,
					endMonth: this.endTime,
				}, 'get').then(res => {
					this.complaintCountSum = res.data.complaintCountSum ? res.data.complaintCountSum : '0';
					if (res.data && res.data.serviceQualityCountVos && res.data.serviceQualityCountVos.length > 0) {
						res.data.serviceQualityCountVos.forEach(item => {
							listName.push(item.month);
							listData.push(item.complaintCount);
						})
						this.bbbbbb('main2', "#3E3EF8", null, listName, listData, '次数')
					} else {
						this.bbbbbb('main2', "#3E3EF8", null, [], [], '次数')
					}
				})
			},
			safeTrainingTopics() {
				let listName = [];
				let listData = [];
				this.$axiosReq('/course/server/escort/web/courseUser/safeTrainingTopics', null, {
					userId: this.row.userId,
					startDate: this.startTime,
					endDate: this.endTime,
				}, 'get').then(res => {
					this.countTime = res.data.countTime ? res.data.countTime : '0';
					if (res.data.sos && res.data.sos.length > 0) {
						res.data.sos.forEach(item => {
							listName.push(item.trainingTime);
							listData.push(item.schedulePercentage);
						})
						this.aaaaa('main1', "#F56999", true, listName, listData, '百分比', '245,105,153')
					} else {
						this.aaaaa('main1', "#F56999", true, [], [], '百分比', '245,105,153')
					}
				})
			},
			// 关闭模态框
			close() {
				let that = this;
				clearTimeout(that.aryTime);
				that.aryTime = null;
				clearTimeout(that.aryTime1);
				that.aryTime1 = null;
				this.$emit('refresh')
			},
			aaaaa(name, col, isShow, nameX, list, nameY, cols) {
				var chartDom = document.getElementById(name);
				var myChart = echarts.init(chartDom);
				var option;
				option = {
					xAxis: {
						name: '日期',
						type: 'category',
						nameTextStyle: {
							color: "#ffffff",
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: 'rgba(255, 255, 255, 0.2)'
							}
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#ffffff'
							}
						},
						data: nameX,
						splitLine: {
							lineStyle: {
								color: 'rgba(255, 255, 255, 0.2)'
							}
						}
					},
					yAxis: {
						name: nameY,
						type: 'value',
						nameTextStyle: {
							color: "#ffffff",
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: 'rgba(255, 255, 255, 0.2)'
							}
						},
						axisLabel: {
							textStyle: {
								color: "#ffffff",
							},
							formatter: '{value}%'
						},
						splitLine: {
							lineStyle: {
								color: 'rgba(255, 255, 255, 0.2)'
							}
						}
					},
					series: [{
						data: list,
						type: 'line',
						stack: 'Total',
						smooth: isShow ? true : false,
						lineStyle: {
							width: 3,
							color: col
						},
						areaStyle: {
							normal: {
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
									offset: 0,
									color: 'rgba(' + cols + ',0.6)'
								}, {
									offset: .34,
									color: 'rgba(' + cols + ',0.4)'
								}, {
									offset: 1,
									color: 'rgba(' + cols + ',0.00)'
								}])
							},
						},
						itemStyle: {
							normal: {
								label: {
									show: true
								},
								color: '#ffffff',
							}
						}
					}]
				};

				option && myChart.setOption(option);
			},
			bbbbbb(name, col, isShow, nameX, list, nameY) {
				var chartDom = document.getElementById(name);
				var myChart = echarts.init(chartDom);
				var option;

				option = {
					xAxis: {
						name: '日期',
						type: 'category',
						nameTextStyle: {
							color: "#ffffff",
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: 'rgba(255, 255, 255, 0.2)'
							}
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#ffffff'
							}
						},
						data: nameX,
						splitLine: {
							lineStyle: {
								color: 'rgba(255, 255, 255, 0.2)'
							}
						}
					},
					yAxis: {
						name: nameY,
						type: 'value',
						nameTextStyle: {
							color: "#ffffff",
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: 'rgba(255, 255, 255, 0.2)'
							}
						},
						splitLine: {
							lineStyle: {
								color: 'rgba(255, 255, 255, 0.2)'
							}
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#ffffff'
							}
						}
					},
					series: [{
						data: list,
						type: 'line',
						smooth: isShow ? true : false,
						lineStyle: {
							width: 3,
							color: col
						},
						itemStyle: {
							normal: {
								label: {
									show: true
								},
								color: '#ffffff',
							}
						}
					}]
				};

				option && myChart.setOption(option);
			},
			cccccc(name) {
				var chartDom = document.getElementById(name);
				var myChart = echarts.init(chartDom);
				var option;

				option = {
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						}
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '3%',
						containLabel: true
					},
					legend: {
						data: ['直接访问', '邮件营销', '联盟广告', '视频广告', '视频'],
						textStyle: {
							color: '#fff'
						}
					},
					xAxis: {
						name: 'cccc',
						type: 'value',
						nameTextStyle: {
							color: "#ffffff"
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#ffffff'
							}
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: 'rgba(255, 255, 255, 0.2)'
							}
						},
						splitLine: {
							lineStyle: {
								color: 'rgba(255, 255, 255, 0.2)'
							}
						},
					},
					yAxis: {
						name: '日期',
						type: 'category',
						nameTextStyle: {
							color: "#ffffff",
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#ffffff',
							}
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: 'rgba(255, 255, 255, 0.2)'
							}
						},
						splitLine: {
							lineStyle: {
								color: 'rgba(255, 255, 255, 0.2)'
							}
						},
						data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
					},
					series: [{
							name: '直接访问',
							type: 'bar',
							stack: 'total',
							label: {
								show: true
							},
							emphasis: {
								focus: 'series'
							},
							itemStyle: {
								normal: {
									color: '#ab78ba'
								}
							},
							data: [320, 302, 301, 334, 390, 330, 320]
						},
						{
							name: '邮件营销',
							type: 'bar',
							stack: 'total',
							label: {
								show: true
							},
							emphasis: {
								focus: 'series'
							},
							itemStyle: {
								normal: {
									color: '#55aaff'
								}
							},
							data: [120, 132, 101, 134, 90, 230, 210]
						},
						{
							name: '联盟广告',
							type: 'bar',
							stack: 'total',
							label: {
								show: true
							},
							emphasis: {
								focus: 'series'
							},
							itemStyle: {
								normal: {
									color: '#ff0000'
								}
							},
							data: [220, 182, 191, 234, 290, 330, 310]
						},
						{
							name: '视频广告',
							type: 'bar',
							stack: 'total',
							label: {
								show: true
							},
							emphasis: {
								focus: 'series'
							},
							itemStyle: {
								normal: {
									color: '#ff557f'
								}
							},
							data: [150, 212, 201, 154, 190, 330, 410]
						},
						{
							name: '视频',
							type: 'bar',
							stack: 'total',
							label: {
								show: true
							},
							emphasis: {
								focus: 'series'
							},
							itemStyle: {
								normal: {
									color: '#5555ff'
								}
							},
							data: [820, 832, 901, 934, 1290, 1330, 1320]
						}
					]
				};

				option && myChart.setOption(option);
			},
			changeArr() {
				let that = this;
				that.testShowNum = null;
				if (that.tableData1.length < 3) {
					return false
				}
				that.showNow = !that.showNow;
				that.tableData1.push(
					that.tableData1.shift()
				);
				// console.log(that.record.bridgeWarnMessageVos);
				that.aryTime = setTimeout(() => {
					that.changeArr();
				}, 2000);
			},
			clearAryTime() {
				let that = this;
				clearTimeout(that.aryTime);
				that.aryTime = null;
			},
			changeArr1() {
				let that = this;
				that.testShowNum1 = null;
				if (that.tableData3.length < 3) {
					return false
				}
				that.showNow1 = !that.showNow1;
				that.tableData3.push(
					that.tableData3.shift()
				);
				// console.log(that.record.bridgeWarnMessageVos);
				that.aryTime1 = setTimeout(() => {
					that.changeArr1();
				}, 2000);
			},
			clearAryTime1() {
				let that = this;
				clearTimeout(that.aryTime1);
				that.aryTime1 = null;
			},
		}
	}
</script>

<style scoped>
	.bgBox {
		width: 95%;
		background-image: url(./allBg.png);
		background-size: 100% 100%;
		display: block;
		margin-left: 5%;
		padding: 10px;
		margin-bottom: 15px;
	}

	.bgBox1 {
		width: 95%;
		background-image: url(./allBg.png);
		background-size: 100% 100%;
		display: block;
		padding: 10px;
		margin: 15px auto 0;
	}

	.bgBox2 {
		width: 95%;
		background-image: url(./allBg.png);
		background-size: 100% 100%;
		display: block;
		margin-right: 5%;
		padding: 10px;
		margin-bottom: 15px;
	}

	.operateClass {
		width: 100vw;
		height: 100vh;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 99;
		background-image: linear-gradient(#18265c, #0c1d3d);
		font-size: 14px;
		overflow-y: scroll;
		overflow-x: hidden;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
	}

	.operateClass::-webkit-scrollbar {
		display: none;
	}

	.headerBg {
		width: 97%;
		height: 60px;
		margin: 20px auto;
		box-sizing: border-box;
		background-image: url(./header.png);
		background-size: 100% 100%;
	}

	.titleBg {
		min-width: 100px;
		height: 25px;
		background-color: rgba(105, 246, 175, 0.12);
		padding: 0 25px 0 15px;
		border-radius: 10px 0 20px 0;
		line-height: 25px;
		font-size: 15px;
		font-weight: 600;
		display: inline-block;
	}

	.titleBox {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: #00FF33;
	}

	.centTopBox {
		width: 95%;
		height: 390px;
		background-image: url(./user.png);
		background-size: 100% 100%;
		text-align: center;
		margin: 0 auto;
	}

	.centTopBoxImg {
		width: 110px;
		height: 110px;
		border-radius: 50%;
		margin: auto;
	}

	.centTopBoxImg img {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		display: block;
	}

	.tableBox {
		height: 145px;
		overflow-y: scroll;
		overflow-x: hidden;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
		font-size: 12px;
	}

	.tableBox::-webkit-scrollbar {
		display: none;
	}

	.tableBox1 {
		height: 310px;
		overflow-y: scroll;
		overflow-x: hidden;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
	}

	.tableBox1::-webkit-scrollbar {
		display: none;
	}


    .el-range-editor{
        background-color: rgba(66, 243, 249, 0.1) !important;
        border: 0 solid transparent;
    }

	.huangquan {
		width: 128px;
		height: 120px;
		background-image: url(./huangquan.png);
		background-size: 100% 100%;
		line-height: 120px;
		text-align: center;
		color: #F0DC57;
	}

	.ziquan {
		width: 128px;
		height: 120px;
		background-image: url(./ziquan.png);
		background-size: 100% 100%;
		line-height: 120px;
		text-align: center;
		color: #CA42FC;
	}

	.testShowClass {
		position: absolute;
		left: 0;
		top: 0;
		background-color: #096A94;
		min-height: 35px;
		line-height: 35px;
		width: 100%;
		font-size: 10px;
		z-index: 999;
	}

	.navBg {
		width: 262px;
		height: 95px;
		background-image: url(./navBg.png);
		background-size: 100% 100%;
		margin-right: 10px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		text-align: center;
	}

	.noData {
		width: 200px;
		height: 130px;
		background-image: url(./noData.png);
		background-size: 100% 100%;
		margin: 0 auto;
	}
</style>
