<template>
	<div class="operateClassN">
		<div class="headerT">违法违规</div>
		<div class="rowCent rowCentRl">
			<div style="width: 59.4%">
				<div class="rowCent rowjaround mgt-10 leftT">
					<div class="navList rowCent rowCentRl">
						<div style="width: 50%">
							<div class="col-00E0FD" style="font-size: 30px">
								{{ list.violateNum ? list.violateNum : "0" }}次
							</div>
							<div class="mt-1">本月违法违规次数</div>
						</div>
						<div style="
                                background-color: rgba(255, 255, 255, 0.1);
                                width: 1px;
                                height: 76px;
                            "></div>
						<div>
							<div class="rowCent">
								<div>年环比</div>
								<div v-if="!list.violateNumMom" style="padding: 0 10px">
									{{ list.violateNumMom === 0 ? "0%" : "--" }}
								</div>
								<div v-if="list.violateNumMom > 0" style="color: #15dd8f">
									<i class="el-icon-caret-top" style="padding: 0 5px"></i>{{ list.violateNumMom }}%
								</div>
								<div v-if="list.violateNumMom < 0" style="color: #f7333d">
									<i class="el-icon-caret-bottom" style="padding: 0 5px"></i>{{ list.violateNumMom }}%
								</div>
							</div>
						</div>
					</div>
					<div class="navList rowCent rowCentRl">
						<div style="width: 50%">
							<div class="col-00E0FD" style="font-size: 30px">
								{{
                                    list.accidentNum ? list.accidentNum : "0"
                                }}次
							</div>
							<div class="mt-1">本月发生事故数</div>
						</div>
						<div style="
                                background-color: rgba(255, 255, 255, 0.1);
                                width: 1px;
                                height: 76px;
                            "></div>
						<div>
							<div class="rowCent">
								<div>年环比</div>
								<div v-if="!list.accidentNumMom" style="padding: 0 10px">
									{{
                                        list.accidentNumMom === 0 ? "0%" : "--"
                                    }}
								</div>
								<div v-if="list.accidentNumMom > 0" style="color: #15dd8f">
									<i class="el-icon-caret-top" style="padding: 0 5px"></i>{{ list.accidentNumMom }}%
								</div>
								<div v-if="list.accidentNumMom < 0" style="color: #f7333d">
									<i class="el-icon-caret-bottom" style="padding: 0 5px"></i>{{ list.accidentNumMom }}%
								</div>
							</div>
						</div>
					</div>
					<div class="navList rowCent rowCentRl">
						<div style="width: 50%">
							<div class="col-00E0FD" style="font-size: 30px">
								{{
                                    list.violateScore ? list.violateScore : "0"
                                }}分
							</div>
							<div class="mt-1">共记分</div>
						</div>
						<div style="
                                background-color: rgba(255, 255, 255, 0.1);
                                width: 1px;
                                height: 76px;
                            "></div>
						<div>
							<div class="rowCent">
								<div>年环比</div>
								<div v-if="!list.violateScoreMom" style="padding: 0 10px">
									{{
                                        list.violateScoreMom === 0 ? "0%" : "--"
                                    }}
								</div>
								<div v-if="list.violateScoreMom > 0" style="color: #15dd8f">
									<i class="el-icon-caret-top" style="padding: 0 5px"></i>{{ list.violateScoreMom }}%
								</div>
								<div v-if="list.violateScoreMom < 0" style="color: #f7333d">
									<i class="el-icon-caret-bottom" style="padding: 0 5px"></i>{{ list.violateScoreMom }}%
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="follow mgt-10">
					<div class="titleBg">
						<el-row type="flex" align="middle">
							<div class="titleBox"></div>
							<div class="pl-1">驾驶员违法违规情况</div>
						</el-row>
					</div>
					<div style="width: 1px; height: 15px"></div>
					<div class="tableClass">
						<div class="rowCent w100Bg mgt-10">
							<div class="w30">时间</div>
							<div class="w30">类型</div>
							<div class="w30">人员</div>
							<div class="w70">事件</div>
						</div>
						<div class="tableOver" @mouseleave="changeArr">
							<div class="rowCent w100" v-for="(item, index) in table.list" :key="index" style="position: relative">
								<div class="w30">{{ item.violateTime }}</div>
								<div class="w30">{{ item.violateType }}</div>
								<div class="w30">{{ item.userName }}</div>
								<div class="w70" @mouseover="mouseoverNow(index)">
									{{ item.violateEvent }}
								</div>
								<div v-if="testShowNum === index" @mouseout="changeArr" class="testShowClass">
									{{ item.violateEvent }}
								</div>
							</div>
							<div v-if="table.list.length < 1" class="noData"></div>
						</div>
						<el-pagination :current-page="table.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]" :page-size="table.pageSize"
						 layout="total, ->, prev, pager, next" :total="table.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
					</div>
				</div>
			</div>
			<div style="width: 40%">
				<div class="grade">
					<div class="titleBg">
						<el-row type="flex" align="middle">
							<div class="titleBox"></div>
							<div class="pl-1">驾驶员违法违规情况统计</div>
						</el-row>
					</div>
					<div v-if="mainShow">
						<div id="main6" style="
                                width: 600px;
                                height: 350px;
                                display: block;
                                margin: 0 auto;
                            "></div>
					</div>
					<div v-if="!mainShow" class="noData"></div>
				</div>
				<div class="grade mgt-10">
					<div class="titleBg">
						<el-row type="flex" align="middle">
							<div class="titleBox"></div>
							<div class="pl-1">各类事故情况统计</div>
						</el-row>
					</div>
					<div id="main11" style="
                            width: 600px;
                            height: 320px;
                            display: block;
                            margin: 0 auto;
                        "></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	const echarts = require("echarts/lib/echarts");
	require("echarts/lib/chart/bar");
	require("echarts/lib/chart/line");
	require("echarts/lib/component/tooltip");
	require("echarts/lib/component/title");
	require("echarts/lib/component/dataZoom");
	require("echarts/lib/component/legend");
	export default {
		name: "Operate",
		props: {
			row: {
				type: Object,
				default: () => {},
			},
			info: {
				type: Object,
				default: () => {},
			},
		},
		data() {
			return {
				loading: false,
				dateTime: "",
				table: {
					list: [],
					total: 0,
					currentPage: 1,
					pageSize: 10,
				},
				testShowNum: null,
				list: {},
				mainShow: true,
			};
		},
		created() {},
		mounted() {},
		methods: {
			handleParentClick(v) {
				this.dateTime = v;
				this.table.currentPage = 1;
				this.table.total = 0;
				this.table.list = [];
				this.yearViolateBaseInfo();
				this.yearDriverViolate();
				this.yearAccidentStatisticVo();
				this.yearViolateStatisticVo();
			},
			yearViolateBaseInfo() {
				let that_ = this;
				this.$axiosReq(
					"/archives/server/escort/web/companyCreditViolate/yearViolateBaseInfo",
					null, {
						date: this.dateTime + '-01',
						companyId: this.info.companyId,
					},
					"get"
				).then((res) => {
					that_.list = res.data;
				});
			},
			yearDriverViolate() {
				let that_ = this;
				this.$axiosReq(
					"/archives/server/escort/web/companyCreditViolate/yearDriverViolate",
					null, {
						pageSize: this.table.pageSize,
						currentPage: this.table.currentPage,
						date: this.dateTime + '-01',
						companyId: this.info.companyId,
					},
					"get"
				).then((res) => {
					if (
						res.data.content &&
						res.data.content.length > 0
					) {
						that_.table.list = res.data.content;
						that_.table.total = Number(
							res.data.totalElements
						);
					}
				});
			},
			yearAccidentStatisticVo() {
				let that_ = this;
				let arr1 = [];
				let arr2 = [];
				let arr3 = [];
				this.$axiosReq(
					"/archives/server/escort/web/companyCreditViolate/yearAccidentStatisticVo",
					null, {
						date: this.dateTime + '-01',
						companyId: this.info.companyId,
					},
					"get"
				).then((res) => {
					if (
						res.data &&
						res.data.length > 0
					) {
						res.data.forEach(function(item) {
							arr1.push(item.month);
							if (item.accidentStatisticsVos && item.accidentStatisticsVos.length > 0) {
								item.accidentStatisticsVos.forEach(function(items) {
									if (items.accidentType === "0") {
										arr2.push({
											value: items.accidentNum ?
												items.accidentNum :
												0,
											incrementMom: items.accidentMomIncrement ?
												items.accidentMomIncrement :
												0,
											mom: items.accidentMom === 0 ?
												"0%" :
												items.accidentMom ?
												items.accidentMom + "%" :
												"--",
											incrementYoy: items.accidentYoyIncrement ?
												items.accidentYoyIncrement :
												0,
											yoy: items.accidentYoy === 0 ?
												"0%" :
												items.accidentYoy ?
												items.accidentYoy + "%" :
												"--",
										});
									} else if (items.accidentType == 1) {
										arr3.push({
											value: items.accidentNum ?
												items.accidentNum :
												0,
											incrementMom: items.accidentMomIncrement ?
												items.accidentMomIncrement :
												0,
											mom: items.accidentMom === 0 ?
												"0%" :
												items.accidentMom ?
												items.accidentMom + "%" :
												"--",
											incrementYoy: items.accidentYoyIncrement ?
												items.accidentYoyIncrement :
												0,
											yoy: items.accidentYoy === 0 ?
												"0%" :
												items.accidentYoy ?
												items.accidentYoy + "%" :
												"--",
										});
									}
								});
							} else {
								arr2.push({
									value: 0,
									incrementMom: 0,
									mom: "--",
									incrementYoy: 0,
									yoy: "--",
								});
								arr3.push({
									value: 0,
									incrementMom: 0,
									mom: "--",
									incrementYoy: 0,
									yoy: "--",
								});
							}
						});
						this.echartsMain("main11", arr1, arr2, arr3);
					} else {
						this.echartsMain("main11", [], [], []);
					}
				});
			},
			yearViolateStatisticVo() {
				let that_ = this;
				let arr = [];
				this.$axiosReq(
					"/archives/server/escort/web/companyCreditViolate/yearViolateStatisticVo",
					null, {
						date: this.dateTime + '-01',
						companyId: this.info.companyId,
					},
					"get"
				).then((res) => {
					if (
						res.data &&
						res.data.length > 0
					) {
						res.data.forEach(function(
							item,
							index
						) {
							arr.push({
								name: item.departName,
								data: [],
								type: "line",
							});
							item.violateStatisticVos.forEach(function(items) {
								arr[index].data.push(items.violateNum);
							});
						});
						that_.mainShow = true;
						setTimeout(function() {
							that_.echartsM("main6", arr);
						}, 200);
					} else {
						that_.mainShow = false;
					}

				});
			},
			changeArr() {
				let that = this;
				that.testShowNum = null;
			},
			mouseoverNow(num) {
				this.testShowNum = num;
			},
			handleSizeChange(pageSize) {
				this.table.pageSize = pageSize;
				this.table.currentPage = 1;
				this.yearDriverViolate();
			},
			handleCurrentChange(currentPage) {
				this.table.currentPage = currentPage;
				this.yearDriverViolate();
			},
			echartsM(name, list) {
				var chartDom = document.getElementById(name);
				var myChart = echarts.init(chartDom);
				var option;

				option = {
					tooltip: {
						trigger: "axis",
						axisPointer: {
							type: "shadow",
							crossStyle: {
								color: "#fff",
							},
						},
						formatter: function(params) {
							let str = params[0].name + "<br />";
							params.forEach(function(items) {
								str +=
									items.marker +
									items.seriesName +
									":" +
									items.data +
									"次" +
									"<br />";
							});
							return str;
						},
					},
					// legend: {
					// 	textStyle: {
					// 		fontSize: 14,
					// 		color: "#fff",
					// 	},
					// 	formatter: function(name) {
					// 		return name;
					// 	},
					// },
					xAxis: {
						name: "月份",
						type: "category",
						nameTextStyle: {
							color: "#ffffff",
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: "#ffffff",
							},
						},
						axisLine: {
							lineStyle: {
								color: "rgba(255,255,255,0.5)",
							},
						},
						data: [
							"1月",
							"2月",
							"3月",
							"4月",
							"5月",
							"6月",
							"7月",
							"8月",
							"9月",
							"10月",
							"11月",
							"12月",
						],
					},
					yAxis: {
						name: "次数",
						type: "value",
						nameTextStyle: {
							color: "#ffffff",
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: "#ffffff",
							},
						},
						axisLine: {
							lineStyle: {
								color: "rgba(255,255,255,0.5)",
							},
						},
					},
					color: ["#2788F9", "#00E8FF", "#00C6FF", "#3434F9", "#f2637b"],
					series: list,
				};

				option && myChart.setOption(option);
			},
			echartsMain(name, list, list1, list2) {
				let chartDom = document.getElementById(name);
				let myChart = echarts.init(chartDom);
				let option;
				option = {
					tooltip: {
						trigger: "axis",
						axisPointer: {
							type: "cross",
							crossStyle: {
								color: "#fff",
							},
						},
						formatter: function(params) {
							let str = null;
							if (params.length > 1) {
								str =
									params[0].name +
									"<br />" +
									params[0].marker +
									params[0].seriesName +
									"<br />" +
									"同比增长率: " +
									params[0].data.yoy +
									"<br />" +
									"同比增长量: " +
									params[0].data.incrementYoy +
									"<br />" +
									"环比增长率: " +
									params[0].data.mom +
									"<br />" +
									"环比增长量: " +
									params[0].data.incrementMom +
									"<br />" +
									params[1].marker +
									params[1].seriesName +
									"<br />" +
									"同比增长率: " +
									params[1].data.yoy +
									"<br />" +
									"同比增长量: " +
									params[1].data.incrementYoy +
									"<br />" +
									"环比增长率: " +
									params[1].data.mom +
									"<br />" +
									"环比增长量: " +
									params[1].data.incrementMom;
							} else {
								str =
									params[0].name +
									"<br />" +
									params[0].marker +
									params[0].seriesName +
									"<br />" +
									"同比增长率： " +
									params[0].data.yoy +
									"<br />" +
									"同比增长量： " +
									params[0].data.incrementYoy +
									"<br />" +
									"环比增长率： " +
									params[0].data.mom +
									"<br />" +
									"环比增长量： " +
									params[0].data.incrementMom;
							}
							return str;
						},
					},
					toolbox: {
						feature: {
							dataView: {
								show: true,
								readOnly: false,
							},
							magicType: {
								show: true,
								type: ["line", "bar"],
							},
							restore: {
								show: true,
							},
							saveAsImage: {
								show: true,
							},
						},
					},
					legend: {
						textStyle: {
							fontSize: 14,
							color: "#fff",
						},
						formatter: function(name) {
							return name;
						},
					},
					color: ["#ECE528", "#00aaff ", "#4149F3", "#55ff00", "#f2637b"],
					xAxis: [{
						name: "月份",
						data: list,
						axisPointer: {
							type: "shadow",
						},
						axisLine: {
							lineStyle: {
								color: "rgba(255,255,255,0.5)",
							},
						},
						splitLine: {
							show: false,
						},
					}, ],
					yAxis: [{
						type: "value",
						name: "人数",
						axisLine: {
							lineStyle: {
								color: "rgba(255,255,255,0.5)",
							},
						},
						splitLine: {
							lineStyle: {
								color: "rgba(255,255,255,0.5)",
							},
						},
					}, ],
					series: [{
							name: "门客伤事故",
							type: "bar",
							data: list1,
						},
						{
							name: "一般行车事故",
							type: "bar",
							data: list2,
						},
					],
				};

				option && myChart.setOption(option);
			},
		},
	};
</script>

<style scoped>
	.col-00E0FD {
		color: #00e0fd;
	}

	.operateClassN {
		width: 100%;
		height: 100%;
	}

	.headerT {
		color: #42f3f9;
		font-size: 20px;
		padding-left: 10px;
		border-left: 3px solid #42f3f9;
		margin-top: 10px;
	}

	.mgt-10 {
		margin-top: 10px;
	}

	.rowCent {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		box-sizing: border-box;
	}

	.rowjaround {
		justify-content: space-around;
	}

	.rowCentRl {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.navList {
		width: 30%;
		height: 136px;
		background-image: url(../borderBg.png);
		background-size: 100% 100%;
		padding: 20px;
		box-sizing: border-box;
		text-align: center;
	}

	.navListOne {
		width: 59px;
		height: 59px;
		background-image: url(../navListOne.png);
		background-size: 100% 100%;
	}

	.summary {
		width: 100%;
		height: 233px;
		background-image: url(../bgAll.png);
		background-size: 100% 100%;
		padding: 10px;
		box-sizing: border-box;
	}

	.grade {
		width: 100%;
		height: 400px;
		background-image: url(../bgAll.png);
		background-size: 100% 100%;
		padding: 10px;
		box-sizing: border-box;
	}

	.follow {
		width: 100%;
		height: 600px;
		background-image: url(../bgAll.png);
		background-size: 100% 100%;
		padding: 10px;
		box-sizing: border-box;
	}

	.titleBg {
		min-width: 100px;
		height: 25px;
		background-color: rgba(105, 246, 175, 0.12);
		padding: 0 25px 0 15px;
		border-radius: 10px 0 20px 0;
		line-height: 25px;
		font-size: 15px;
		font-weight: 600;
		display: inline-block;
	}

	.titleBox {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: #00ff33;
	}

	.textOver {
		height: 180px;
		padding-top: 10px;
		line-height: 20px;
		overflow-y: scroll;
		overflow-x: hidden;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
	}

	.textOver::-webkit-scrollbar {
		display: none;
	}

	.tableOver {
		width: 100%;
		height: 370px;
		overflow-y: scroll;
		overflow-x: hidden;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
		box-sizing: border-box;
	}

	.tableOver::-webkit-scrollbar {
		display: none;
	}

	.w70 {
		width: 40%;
		height: 35px;
		line-height: 35px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.w30 {
		width: 20%;
		height: 35px;
		line-height: 35px;
	}

	.w100 {
		width: 100%;
		text-align: center;
		height: 35px;
	}

	.w100Bg {
		width: 100%;
		text-align: center;
		height: 35px;
		background-color: rgba(0, 232, 255, 0.08);
	}

	.leftT {
		height: 210px;
		background-image: url(../bgAll.png);
		background-size: 100% 100%;
	}

	.testShowClass {
		position: absolute;
		left: 0;
		top: 0;
		background-color: #096a94;
		min-height: 35px;
		line-height: 35px;
		width: 100%;
		font-size: 10px;
		z-index: 999;
	}

	.noData {
		width: 200px;
		height: 130px;
		background-image: url(../noData.png);
		background-size: 100% 100%;
		margin: 0 auto;
	}
</style>
