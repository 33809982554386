<template>
	<div class="operateClass">
		<div style="
                position: fixed;
                right: 6px;
                top: 6px;
                z-index: 100;
                color: #ffffff;
                font-size: 25px;
            "
		 @click="close">
			<i class="el-icon-error"></i>
		</div>
		<div class="headerBg"></div>
		<el-row justify="space-between" style="color: #ffffff; width: 97%; margin: 0 auto" v-if="indexNav !== 0">
			<el-col>
				<div class="centTopBox">
					<el-date-picker style="display: inline-block; width: 165px" v-model="dateTime" type="year" placeholder="选择年"
					 value-format="yyyy" :picker-options="pickerOptions" :clearable="false" @change="changeCondition"></el-date-picker>
					<span class="col-42F3F9 font-22" style="padding-left: 10px">{{ row.lineName }}信用评价</span>
				</div>
			</el-col>
		</el-row>
		<div class="rowCent rowCentRl" v-if="indexNav === 0" style="color: #ffffff; width: 97%; margin: 0 auto 3px">
			<div></div>
			<div class="rowCent">
				<div class="jbxxImg"></div>
				<div class="col-42F3F9 font-22 ml-1">{{driverNum}}人</div>
				<div style="padding-top: 5px;padding-left: 10px;">驾驶员总数</div>
			</div>
			<div>
				<el-button v-if="driverNum" type="primary" size="mini" @click="changeBtn">一键导出</el-button>
				<div v-if="!driverNum" style="width: 109px;height: 30px;line-height: 30px;text-align: center;border-radius: 4px;background-color: #00A8F0;opacity: 0.5;">一键导出</div>
			</div>
		</div>
		<div style="
                width: 97%;
                display: block;
                height: 800px;
                margin: 0 auto;
                color: #ffffff;
            "
		 class="swiperClass">
			<el-carousel :loop="false" :autoplay="false" indicator-position="outside" height="775px" style="width: 100%; color: #ffffff"
			 @change="changeNav">
				<el-carousel-item style="width: 100%; height: 100%">
					<Basic ref="childMethod" :row="row" :info="info" v-on:childByValue="childByValue" v-on:childDepartNum="childDepartNum" />
				</el-carousel-item>
				<el-carousel-item style="width: 100%; height: 100%">
					<Security v-if="modalShow" ref="childMethod1" :row="row" :info="info" />
				</el-carousel-item>
				<el-carousel-item style="width: 100%; height: 100%">
					<State v-if="modalShow1" ref="childMethod2" :row="row" :info="info" />
				</el-carousel-item>
				<el-carousel-item style="width: 100%; height: 100%">
					<Violation v-if="modalShow2" ref="childMethod3" :row="row" :info="info" />
				</el-carousel-item>
				<el-carousel-item style="width: 100%; height: 100%">
					<Service v-if="modalShow3" ref="childMethod4" :row="row" :info="info" />
				</el-carousel-item>
				<el-carousel-item style="width: 100%; height: 100%">
					<Attendance v-if="modalShow4" ref="childMethod5" :row="row" :info="info" />
				</el-carousel-item>
				<el-carousel-item style="width: 100%; height: 100%">
					<Punishments v-if="modalShow5" ref="childMethod6" :row="row" :info="info" />
				</el-carousel-item>
			</el-carousel>
		</div>
	</div>
</template>

<script>
	import Basic from "./year/Basic.vue";
	import Security from "./year/Security.vue";
	import State from "./year/State.vue";
	import Violation from "./year/Violation.vue";
	import Service from "./year/Service.vue";
	import Attendance from "./year/Attendance.vue";
	import Punishments from "./year/Punishments.vue";
	import {
		getYear
	} from "@/utils/getDate";
	export default {
		name: "Operate",
		components: {
			Basic,
			Security,
			State,
			Service,
			Violation,
			Punishments,
			Attendance,
		},
		props: {
			row: {
				type: Object,
				default: () => {},
			},
			info: {
				type: Object,
				default: () => {},
			},
		},
		data() {
			return {
				pickerOptions: {
					disabledDate(v) {
						return v.getTime() > new Date().getTime() - 86400000;
					},
				},
				dateTime: "",
				modalShow: false,
				modalShow1: false,
				modalShow2: false,
				modalShow3: false,
				modalShow4: false,
				modalShow5: false,
				indexNav: 0,
				driverNum: 0
			};
		},
		created() {
			this.dateTime = getYear() + "";
		},
		mounted() {},
		methods: {
			// 关闭模态框
			close() {
				this.$emit("refresh");
			},
			changeNav(i) {
				let that_ = this;
				this.indexNav = i;
				if (i == 1) {
					this.modalShow = true;
					setTimeout(() => {
						that_.$refs.childMethod1.handleParentClick(that_.dateTime);
					}, 500);
				} else if (i == 2) {
					this.modalShow1 = true;
					setTimeout(() => {
						that_.$refs.childMethod2.handleParentClick(that_.dateTime);
					}, 500);
				} else if (i == 3) {
					this.modalShow2 = true;
					setTimeout(() => {
						that_.$refs.childMethod3.handleParentClick(that_.dateTime);
					}, 500);
				} else if (i == 4) {
					this.modalShow3 = true;
					setTimeout(() => {
						this.$refs.childMethod4.handleParentClick(that_.dateTime);
					}, 500);
				} else if (i == 5) {
					this.modalShow4 = true;
					setTimeout(() => {
						that_.$refs.childMethod5.handleParentClick(that_.dateTime);
					}, 500);
				} else if (i == 6) {
					this.modalShow5 = true;
					setTimeout(() => {
						that_.$refs.childMethod6.handleParentClick(that_.dateTime);
					}, 500);
				} else if (i === 0) {
					setTimeout(() => {
						that_.$refs.childMethod.handleParentClick(that_.dateTime);
					}, 500);
				}
			},
			changeCondition(v) {
				if (this.indexNav == 1) {
					this.$refs.childMethod1.handleParentClick(v);
				} else if (this.indexNav == 2) {
					this.$refs.childMethod2.handleParentClick(v);
				} else if (this.indexNav == 3) {
					this.$refs.childMethod3.handleParentClick(v);
				} else if (this.indexNav == 4) {
					this.$refs.childMethod4.handleParentClick(v);
				} else if (this.indexNav == 5) {
					this.$refs.childMethod5.handleParentClick(v);
				} else if (this.indexNav == 6) {
					this.$refs.childMethod6.handleParentClick(v);
				} else if (this.indexNav === 0) {
					this.$refs.childMethod.handleParentClick(v);
				}
			},
			changeBtn() {
				this.$refs.childMethod.exportList();
			},
			childByValue (childValue) {
				this.dateTime = childValue;
			},
			childDepartNum (n) {
				this.driverNum = n;
			}
		},
	};
</script>

<style scoped>
	.col-42F3F9 {
		color: #42f3f9;
	}

	.font-16 {
		font-size: 16px;
	}

	.font-22 {
		font-size: 22px;
	}

	.font-30 {
		font-size: 30px;
	}

	.operateClass {
		width: 100vw;
		height: 100vh;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 99;
		background-image: linear-gradient(#18265c, #0c1d3d);
		font-size: 14px;
	}

	.headerBg {
		width: 97%;
		height: 60px;
		margin: 20px auto;
		box-sizing: border-box;
		background-image: url(./header.png);
		background-size: 100% 100%;
	}

	.rowCent {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.rowCentRl {
	    -webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	    justify-content: space-between;
	}

	.jbxxImg{
		width: 35px;
		height: 35px;
		background-image: url(./jbxxH.png);
		background-size: 100% 100%;
	}
</style>
