<template>
	<div class="dashboard-container">
		<Operate v-if="dialog.refresh" :row="dialog.row" @refresh="refreshDialog" />
		<BatchExport v-if="dialog.refresh2" :row="dialog.row" @refresh="refreshDialog" />
		<el-card>
			<el-row type="flex" justify="space-between">
				<el-col v-if="$admin" :span="6">
					<span class="text-primary text-pad-right">公司：</span>
					<el-select v-model="companyId" size="mini" class="input searchInput" filterable clearable @change="changeCondition('2')">
						<el-option v-for="(v, i) in company" :key="i" :value="v.id" :label="v.companyName" />
					</el-select>
				</el-col>
				<el-col :span="6">
					<span class="text-primary text-pad-right">部门：</span>
					<el-cascader
					    ref="dept"
					    v-model="departId"
					    :options="departList"
					    class="searchInput"
					    size="mini"
					    :props="{
					        expandTrigger: 'hover',
					        value: 'id',
					        children: 'sons',
					        label: 'departName',
					        emitPath: false,
					        checkStrictly: true,
					    }"
					    filterable
					    clearable
					    @change="changeCondition('3')"
					/>
				</el-col>
				<el-col :span="6">
					<el-input style="width: 280px;" v-model="searchName" class="input searchInput" size="mini" suffix-icon="el-icon-search"
					 placeholder="搜索" @input="changeCondition('0')" />
				</el-col>
				<el-col :span="8"></el-col>
				<el-col :span="2">
					<!-- <vxe-button @click="getListNow">刷新</vxe-button> -->
				</el-col>
			</el-row>
		</el-card>
		<el-card>
			<el-row>
				<vxe-table ref="table" border resizable :auto-resize="true" :header-row-style="headerStyle" :row-style="rowStyle"
				 align="center" class="vxe-table-element" height="580" row-id="company_id" :sort-config="{ remote: true }"
				 :filter-config="{ remote: true }" :data="table.list" :loading="loading">
					<vxe-table-column type="seq" width="60" />
					<vxe-table-column field="userName" title="姓名" />
					<vxe-table-column field="phone" title="手机号码" />
					<vxe-table-column field="departName" title="部门" />
					<vxe-table-column field="positionName" title="岗位" />
					<vxe-table-column field="driverAge" title="驾龄" />
					<vxe-table-column field="busDriver" title="公交驾龄" />
					<vxe-table-column title="月度报告">
						<template v-slot="{ row }">
							<el-link type="primary" @click="openDialog({
										title: '月度报告',
										addOr: 'add',
									}, row)">查看</el-link>
						</template>
					</vxe-table-column>
					<vxe-table-column title="年度报告">
						<template v-slot="{ row }">
							<el-link type="primary" @click="openExport({
										title: '年度报告',
										addOr: 'add',
									}, row)">查看</el-link>
						</template>
					</vxe-table-column>
					<!-- <vxe-table-column title="操作">
						<template v-slot="{ row }">
							<el-dropdown size="small" type="primary">
								<el-button size="small" type="primary">
									操作<i class="el-icon-arrow-down el-icon--right" />
								</el-button>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item @click.native="
										openDialog({
                                            title: '单月查询',
                                            addOr: 'add',
                                        }, row)">单月查询</el-dropdown-item>
									<el-dropdown-item @click.native="
											openExport({
												title: '跨月查询',
												addOr: 'add',
											}, row)
										">跨月查询</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</vxe-table-column> -->
				</vxe-table>
				<div style="height: 20px"></div>
				<el-pagination background :current-page="table.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]"
				 :page-size="table.pageSize" layout="total, ->, sizes, prev, pager, next, jumper" :total="table.total" @size-change="handleSizeChange"
				 @current-change="handleCurrentChange" />
			</el-row>
		</el-card>
	</div>
</template>

<script>
	import {
		rowStyle,
		headerStyle
	} from "@/utils/tableStyleJs";
	import Operate from "./Operate";
	import BatchExport from "./BatchExport";
	import {
		company,
		depart
	} from '@/api'
	export default {
		name: "Index",
		components: {
			Operate,
			BatchExport,
		},
		data() {
			return {
				loading: false,
				table: {
					list: [],
					total: 0,
					currentPage: 1,
					pageSize: 10,
					sortName: "",
					sortBy: "",
				},
				dialog: {
					row: Object,
					info: {},
					refresh: false, // 每次刷新DOM
					refresh2: false, // 每次刷新DOM
					refresh3: false, // 每次刷新DOM
					refreshRole: false, // 每次刷新DOM
					show: false,
					show2: false,
					show3: false,
					showRole: false,
				},
				companyId: this.$admin ? '' : this.$companyId,
				company: [],
				searchName: '',
				departId: '',
				departList: []
			};
		},
		created() {
			if (this.$admin) {
				this.getCompany();
			} else{
				this.getDepart('2');
			}
		},
		methods: {
			rowStyle,
			headerStyle,
			// 打开dialog
			openDialog(info,row) {
				this.dialog.refresh = true;
				this.dialog.show = true;
				this.dialog.info = info;
				this.dialog.row = row;
			},
			// 打开批量导入
			openExport(info,row) {
				this.dialog.refresh2 = true;
				this.dialog.show2 = true;
				this.dialog.info = info;
				this.dialog.row = row;
			},
			getListNow(){
				this.table.currentPage = 1
				this.searchName = '';
				this.companyId = this.company[0].id;
				this.getList();
			},
			getCompany() {
				this.$axiosReq(company, null, {
					pageSize: 10000
				}, 'get').then(res => {
					this.company = res.data.content;
					if (this.company && this.company.length > 0) {
						this.companyId = this.company[0].id;
					}
					this.getDepart('1');
				})
			},
			getDepart(num) {
			    this.$axiosReq(
			        depart,
			        null,
			        {
			            companyId: this.companyId,
			            type: 1,
			        },
			        "get"
			    ).then((res) => {
			        this.departList = res.data;
					if (this.departList && this.departList.length > 0 && num == 2) {
						this.departId = this.departList[0].id;
					}
					this.getList();
			    });
			},
			changeCondition(num) {
				this.table.currentPage = 1
				if (num == 2) {
					this.getDepart('1');
				} else{
					this.getList();
				}
			},
			getList() {
				this.loading = true;
				this.$axiosReq(
						'/archives/server/escort/web/creditEvaluate/pageUserCreditEvaluates',
						null, {
							currentPage: this.table.currentPage,
							pageSize: this.table.pageSize,
							companyId: this.companyId,
							departId: this.departId,
							keyword: this.searchName,
						},
						"get"
					)
					.then((res) => {
						this.table.list = res.data.content;
						this.table.total = Number(res.data.totalElements);
					})
					.finally((res) => {
						this.loading = false;
					});
			},
			handleSizeChange(pageSize) {
				this.table.pageSize = pageSize;
				this.table.currentPage = 1;
				this.table.list = [];
				this.getList();
			},
			handleCurrentChange(currentPage) {
				this.table.currentPage = currentPage;
				this.table.list = [];
				this.getList();
			},
			refreshDialog() {
				this.dialog.refresh = false;
				this.dialog.refresh2 = false;
				// this.getList();
			},
		},
	};
</script>

<style scoped></style>
