<template>
	<div class="operateClassN">
		<el-row justify="space-between">
			<el-col :span="12">
				<div class="headerT">基本信息</div>
			</el-col>
			<el-col :span="12">
				<div class="centTopBox">
					<el-date-picker style="display: inline-block; width: 135px" type="year" :picker-options="pickerOptions" v-model="dateTime"
					 placeholder="选择年" value-format="yyyy" :clearable="false" @change="changeTime"></el-date-picker>
					<span style="padding-left: 10px;color: #42f3f9;font-size: 17px;font-weight: bold;">{{ row.lineName }}信用评价</span>
				</div>
			</el-col>
		</el-row>
		<div class="rowCent rowCentRl mgt-10">
			<!-- <div class="navList">
				<div class="rowCent mgt-10">
					<div class="navListOne"><img src="../renyuan.png" /></div>
					<div style="padding-left: 10px">
						<div style="
                                color: #42f3f9;
                                font-size: 26px;
                                padding-bottom: 8px;
                            ">
							{{ list.driverNum ? list.driverNum : 0 }}人
						</div>
						<div>驾驶员总数</div>
					</div>
				</div>
				<div style="height: 70px; width: 100%"></div>
				<div style="border-top: 2px solid rgba(92, 220, 252, 0.1)"></div>
				<div class="rowCent mgt-10" style="color: #31a8ec; margin-top: 20px">
					<div v-if="row.departName">{{ row.departName }}:</div>
					<div style="padding-left: 20px">{{ list.managerName }}</div>
				</div>
			</div> -->
			<div class="navList">
				<div class="rowCent mgt-10">
					<div class="navListOne">
						<img src="../navListOne.png" />
					</div>
					<div style="padding-left: 10px">
						<div style="
                                color: #42f3f9;
                                font-size: 26px;
                                padding-bottom: 8px;
                            ">
							{{
                                list.courseCompletionRate
                                    ? list.courseCompletionRate
                                    : 0
                            }}%
						</div>
						<div>课程月均完成率</div>
					</div>
				</div>
				<div style="width: 100%; padding: 27px">
					<el-progress :text-inside="true" :stroke-width="15" :percentage="
                            list.courseCompletionRate
                                ? list.courseCompletionRate
                                : 0
                        "></el-progress>
				</div>
				<div style="border-top: 2px solid rgba(92, 220, 252, 0.1)"></div>
				<div class="rowCent rowCentRl mgt-10">
					<div class="rowCent mgt-10"></div>
					<div class="rowCent mgt-10">
						<div>环比</div>
						<div v-if="!list.courseMom" style="padding: 0 10px">
							{{ list.courseMom === 0 ? "0%" : "--" }}
						</div>
						<div v-if="list.courseMom > 0" style="color: #15dd8f">
							<i class="el-icon-caret-top" style="padding: 0 5px"></i>{{ list.courseMom }}%
						</div>
						<div v-if="list.courseMom < 0" style="color: #f7333d">
							<i class="el-icon-caret-bottom" style="padding: 0 5px"></i>{{ list.courseMom }}%
						</div>
					</div>
				</div>
			</div>
			<div class="navList">
				<div class="rowCent mgt-10">
					<div class="navListOne">
						<img src="../ceping.png" />
					</div>
					<div style="padding-left: 10px">
						<div style="
                                color: #42f3f9;
                                font-size: 26px;
                                padding-bottom: 8px;
                            ">
							{{
                                list.mentalCompletionRate
                                    ? list.mentalCompletionRate
                                    : 0
                            }}%
						</div>
						<div>测评月均参与率</div>
					</div>
				</div>
				<div style="width: 100%; padding: 27px">
					<el-progress :text-inside="true" :stroke-width="15" :percentage="
                            list.mentalCompletionRate
                                ? list.mentalCompletionRate
                                : 0
                        "></el-progress>
				</div>
				<div style="border-top: 2px solid rgba(92, 220, 252, 0.1)"></div>
				<div class="rowCent rowCentRl mgt-10">
					<div class="rowCent"></div>
					<div class="rowCent mgt-10">
						<div>环比</div>
						<div v-if="!list.mentalMom" style="padding: 0 10px">
							{{ list.mentalMom === 0 ? "0%" : "--" }}
						</div>
						<div v-if="list.mentalMom > 0" style="color: #15dd8f">
							<i class="el-icon-caret-top" style="padding: 0 5px"></i>{{ list.mentalMom }}%
						</div>
						<div v-if="list.mentalMom < 0" style="color: #f7333d">
							<i class="el-icon-caret-bottom" style="padding: 0 5px"></i>{{ list.mentalMom }}%
						</div>
					</div>
				</div>
			</div>
			<div class="navList">
				<div class="rowCent mgt-10">
					<div class="navListOne"><img src="../quanqing.png" /></div>
					<div style="padding-left: 10px">
						<div style="
                                color: #42f3f9;
                                font-size: 26px;
                                padding-bottom: 8px;
                            ">
							{{ list.allDutyRate ? list.allDutyRate : 0 }}%
						</div>
						<div>月均全勤率</div>
					</div>
				</div>
				<div style="width: 100%; padding: 27px">
					<el-progress :text-inside="true" :stroke-width="15" :percentage="list.allDutyRate ? list.allDutyRate : 0"></el-progress>
				</div>
				<div style="border-top: 2px solid rgba(92, 220, 252, 0.1)"></div>
				<div class="rowCent rowCentRl mgt-10">
					<div class="rowCent mgt-10"></div>
					<div class="rowCent mgt-10">
						<div>环比</div>
						<div v-if="!list.allDutyRateMom" style="padding: 0 10px">
							{{ list.allDutyRateMom === 0 ? "0%" : "--" }}
						</div>
						<div v-if="list.allDutyRateMom > 0" style="color: #15dd8f">
							<i class="el-icon-caret-top" style="padding: 0 5px"></i>{{ list.allDutyRateMom }}%
						</div>
						<div v-if="list.allDutyRateMom < 0" style="color: #f7333d">
							<i class="el-icon-caret-bottom" style="padding: 0 5px"></i>{{ list.allDutyRateMom }}%
						</div>
					</div>
				</div>
			</div>
			<div class="navList">
				<div class="rowCent mgt-10">
					<div class="navListOne"><img src="../jingbao.png" /></div>
					<div style="padding-left: 10px">
						<div style="
                                color: #42f3f9;
                                font-size: 26px;
                                padding-bottom: 8px;
                            ">
							{{
                                list.drivingBehaviorWarningNum
                                    ? list.drivingBehaviorWarningNum
                                    : 0
                            }}次
						</div>
						<div>驾驶行为累计预警次数</div>
					</div>
				</div>
				<div style="padding: 21px; width: 100%">
					<img src="../yujing.png" style="width: 90%; height: 27px; display: block" />
				</div>
				<div style="border-top: 2px solid rgba(92, 220, 252, 0.1)"></div>
				<div class="rowCent rowCentRl mgt-10">
					<div class="rowCent"></div>
					<div class="rowCent mgt-10">
						<div>环比</div>
						<div v-if="!list.warningMom" style="padding: 0 10px">
							{{ list.warningMom === 0 ? "0%" : "--" }}
						</div>
						<div v-if="list.warningMom > 0" style="color: #15dd8f">
							<i class="el-icon-caret-top" style="padding: 0 5px"></i>{{ list.warningMom }}%
						</div>
						<div v-if="list.warningMom < 0" style="color: #f7333d">
							<i class="el-icon-caret-bottom" style="padding: 0 5px"></i>{{ list.warningMom }}%
						</div>
					</div>
				</div>
			</div>
			<div class="navList">
				<div class="rowCent rowCentRl mgt-10">
					<div>
						<div style="
                                color: #42f3f9;
                                font-size: 26px;
                                padding-bottom: 8px;
                            ">
							{{ list.violateNum ? list.violateNum : 0 }}次
						</div>
						<div style="font-size: 12px">累计违法违规次数</div>
					</div>
					<div>
						<div class="rowCent"></div>
						<div class="rowCent mgt-10">
							<div>环比</div>
							<div v-if="!list.violateNumMom" style="padding: 0 10px">
								{{ list.violateNumMom === 0 ? "0%" : "--" }}
							</div>
							<div v-if="list.violateNumMom > 0" style="color: #15dd8f">
								<i class="el-icon-caret-top" style="padding: 0 5px"></i>{{ list.violateNumMom }}%
							</div>
							<div v-if="list.violateNumMom < 0" style="color: #f7333d">
								<i class="el-icon-caret-bottom" style="padding: 0 5px"></i>{{ list.violateNumMom }}%
							</div>
						</div>
					</div>
				</div>
				<div style="
                        border-top: 2px solid rgba(92, 220, 252, 0.1);
                        margin: 15px 0 20px;
                    "></div>
				<div class="rowCent rowCentRl mgt-10">
					<div>
						<div style="
                                color: #42f3f9;
                                font-size: 26px;
                                padding-bottom: 8px;
                            ">
							{{
                                list.serviceQualityNum
                                    ? list.serviceQualityNum
                                    : 0
                            }}次
						</div>
						<div style="font-size: 12px">累计服务质量投诉次数</div>
					</div>
					<div>
						<div class="rowCent mgt-10"></div>
						<div class="rowCent mgt-10">
							<div>环比</div>
							<div v-if="!list.serviceQualityNumMom" style="padding: 0 10px">
								{{
                                    list.serviceQualityNumMom === 0
                                        ? "0%"
                                        : "--"
                                }}
							</div>
							<div v-if="list.serviceQualityNumMom > 0" style="color: #15dd8f">
								<i class="el-icon-caret-top" style="padding: 0 5px"></i>{{ list.serviceQualityNumMom }}%
							</div>
							<div v-if="list.serviceQualityNumMom < 0" style="color: #f7333d">
								<i class="el-icon-caret-bottom" style="padding: 0 5px"></i>{{ list.serviceQualityNumMom }}%
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="navList">
				<div class="rowCent rowCentRl mgt-10">
					<div>
						<div style="
                                color: #42f3f9;
                                font-size: 26px;
                                padding-bottom: 8px;
                            ">
							{{ list.punishmentNum ? list.punishmentNum : 0 }}次
						</div>
						<div style="font-size: 12px">累计处罚情况</div>
					</div>
					<div>
						<div class="rowCent mgt-10"></div>
						<div class="rowCent mgt-10">
							<div>环比</div>
							<div v-if="!list.punishmentNumMom" style="padding: 0 10px">
								{{ list.punishmentNumMom === 0 ? "0%" : "--" }}
							</div>
							<div v-if="list.punishmentNumMom > 0" style="color: #15dd8f">
								<i class="el-icon-caret-top" style="padding: 0 5px"></i>{{ list.punishmentNumMom }}%
							</div>
							<div v-if="list.punishmentNumMom < 0" style="color: #f7333d">
								<i class="el-icon-caret-bottom" style="padding: 0 5px"></i>{{ list.punishmentNumMom }}%
							</div>
						</div>
					</div>
				</div>
				<div style="
                        border-top: 2px solid rgba(92, 220, 252, 0.1);
                        margin: 15px 0 20px;
                    "></div>
				<div class="rowCent rowCentRl mgt-10">
					<div>
						<div style="
                                color: #42f3f9;
                                font-size: 26px;
                                padding-bottom: 8px;
                            ">
							{{ list.rewardNum ? list.rewardNum : 0 }}次
						</div>
						<div style="font-size: 12px">累计奖励情况</div>
					</div>
					<div>
						<div class="rowCent mgt-10"></div>
						<div class="rowCent mgt-10">
							<div>环比</div>
							<div v-if="!list.rewardNumMom" style="padding: 0 10px">
								{{ list.rewardNumMom === 0 ? "0%" : "--" }}
							</div>
							<div v-if="list.rewardNumMom > 0" style="color: #15dd8f">
								<i class="el-icon-caret-top" style="padding: 0 5px"></i>{{ list.rewardNumMom }}%
							</div>
							<div v-if="list.rewardNumMom < 0" style="color: #f7333d">
								<i class="el-icon-caret-bottom" style="padding: 0 5px"></i>{{ list.rewardNumMom }}%
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="rowCent rowCentRl mgt-10">
			<div style="width: 40.4%">
				<div class="summary">
					<div class="titleBg">
						<el-row type="flex" align="middle">
							<div class="titleBox"></div>
							<div class="pl-1">信用评价概述</div>
						</el-row>
					</div>
					<div class="col-00E0FD textOver">
						驾驶员信用评价报告根据六大维度进行综合评价，包括安全培训、违法违规、状态测评、驾驶行为、服务质量和考勤情况。
						{{ dateTime }}年{{ row.lineName }}驾驶员共有{{
                            list.driverNum
                        }}名，其中全年信用测评都处于AAA和AA等级分别有
						<span style="font-size: 20px">{{
                            list1.aaaNum ? list1.aaaNum : "0"
                        }}</span>人和
						<span style="font-size: 20px">{{
                            list1.aaNum ? list1.aaNum : "0"
                        }}</span>
						人； 至少有一次处于A、B和F等级分别有
						<span style="font-size: 20px">{{
                            list1.aNum ? list1.aNum : "0"
                        }}</span>人、
						<span style="font-size: 20px">{{
                            list1.bNum ? list1.bNum : "0"
                        }}</span>人和
						<span style="font-size: 20px">{{
                            list1.fNum ? list1.fNum : "0"
                        }}</span>
						人。综合信用平均得分低于80分的驾驶员，建议对其加强安全教育与日常管理，以降低安全风险等级。
					</div>
				</div>
				<div class="grade">
					<div class="titleBg">
						<el-row type="flex" align="middle">
							<div class="titleBox"></div>
							<div class="pl-1">驾驶员信用评价各类占比情况</div>
						</el-row>
					</div>
					<div class="rowCent rowAround mgt-15">
						<div style="position: relative">
							<div class="rowCent rowAround">
								<el-progress type="circle" :percentage="
                                        list1.aNumRatio ? list1.aNumRatio : 0
                                    "
								 :width="100" :height="100" :stroke-width="10" color="#00FF94" :show-text="false"></el-progress>
							</div>
							<div class="positionText">
								{{ list1.aNumRatio ? list1.aNumRatio : 0 }}%
							</div>
							<div style="
                                    color: #00ff94;
                                    text-align: center;
                                    padding-top: 10px;
                                ">
								至少有一次处于A等级
							</div>
						</div>
						<div style="position: relative">
							<div class="rowCent rowAround">
								<el-progress type="circle" :percentage="
                                        list1.bNumRatio ? list1.bNumRatio : 0
                                    "
								 :width="100" :height="100" :stroke-width="10" color="#37D1DF" :show-text="false"></el-progress>
							</div>
							<div class="positionText">
								{{ list1.bNumRatio ? list1.bNumRatio : 0 }}%
							</div>
							<div style="
                                    color: #37d1df;
                                    text-align: center;
                                    padding-top: 10px;
                                ">
								至少有一次处于B等级
							</div>
						</div>
						<div style="position: relative">
							<div class="rowCent rowAround">
								<el-progress type="circle" :percentage="
                                        list1.fNumRatio ? list1.fNumRatio : 0
                                    "
								 :width="100" :height="100" :stroke-width="10" color="#2788F9" :show-text="false"></el-progress>
							</div>
							<div class="positionText">
								{{ list1.fNumRatio ? list1.fNumRatio : 0 }}%
							</div>
							<div style="
                                    color: #2788f9;
                                    text-align: center;
                                    padding-top: 10px;
                                ">
								至少有一次处于F等级
							</div>
						</div>
					</div>
					<div class="rowCent rowAround mgt-15">
						<div style="position: relative">
							<div class="rowCent rowAround">
								<el-progress type="circle" :percentage="
                                        list1.aaaNumRatio ? list1.aaaNumRatio : 0
                                    "
								 :width="100" :height="100" :stroke-width="10" color="#F9AC27" :show-text="false"></el-progress>
							</div>
							<div class="positionText">
								{{ list1.aaaNumRatio ? list1.aaaNumRatio : 0 }}%
							</div>
							<div style="
                                    color: #f9ac27;
                                    text-align: center;
                                    padding-top: 10px;
                                ">
								都处于AAA等级
							</div>
						</div>
						<div style="position: relative">
							<div class="rowCent rowAround">
								<el-progress type="circle" :percentage="
                                        list1.aaNumRatio ? list1.aaNumRatio : 0
                                    "
								 :width="100" :height="100" :stroke-width="10" color="#D83B3B" :show-text="false"></el-progress>
							</div>
							<div class="positionText">
								{{ list1.aaNumRatio ? list1.aaNumRatio : 0 }}%
							</div>
							<div style="
                                    color: #d83b3b;
                                    text-align: center;
                                    padding-top: 10px;
                                ">
								都处于AA等级
							</div>
						</div>
					</div>
				</div>
			</div>
			<div style="width: 59%" class="follow">
				<div class="titleBg">
					<el-row type="flex" align="middle">
						<div class="titleBox"></div>
						<div class="pl-1">重点关注名单</div>
					</el-row>
				</div>
				<div style="width: 1px; height: 15px"></div>
				<div class="tableClass">
					<vxe-table ref="table" border resizable :auto-resize="true" align="center" class="vxe-table-element" height="410"
					 row-id="ID" :sort-config="{ remote: true }" :filter-config="{ remote: true }" :data="table.list" :loading="loading">
						<vxe-table-column title="姓名" field="realName" />
						<vxe-table-column title="电话" field="phone" />
						<vxe-table-column title="信用平均评分">
							<template v-slot="{ row }">
								{{ row.creditScore ? row.creditScore : "0" }}分
							</template>
						</vxe-table-column>
						<vxe-table-column title="课程月均完成率" width="140">
							<template v-slot="{ row }">
								{{
                                    row.courseCompletionRate
                                        ? row.courseCompletionRate
                                        : ""
                                }}
							</template>
						</vxe-table-column>
						<vxe-table-column title="测评月均参与率" width="140">
							<template v-slot="{ row }">
								{{
                                    row.mentalCompletionRate
                                        ? row.mentalCompletionRate
                                        : ""
                                }}
							</template>
						</vxe-table-column>
						<vxe-table-column title="驾驶行为预警次数" width="150">
							<template v-slot="{ row }">
								{{
                                    row.drivingBehaviorWarningNum
                                        ? row.drivingBehaviorWarningNum
                                        : "0"
                                }}次
							</template>
						</vxe-table-column>
						<vxe-table-column title="违法违规次数">
							<template v-slot="{ row }">
								{{ row.violateNum ? row.violateNum : "0" }}次
							</template>
						</vxe-table-column>
						<vxe-table-column title="服务质量投诉">
							<template v-slot="{ row }">
								{{
                                    row.serviceQualityNum
                                        ? row.serviceQualityNum
                                        : "0"
                                }}次
							</template>
						</vxe-table-column>
						<vxe-table-column title="处罚情况">
							<template v-slot="{ row }">
								{{
                                    row.punishmentNum ? row.punishmentNum : "0"
                                }}次
							</template>
						</vxe-table-column>
					</vxe-table>
					<div style="height: 20px" />
					<el-pagination :current-page="table.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]" :page-size="table.pageSize"
					 layout="total, ->, prev, pager, next" :total="table.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		bytesToFile
	} from "@/utils/handleByte";
	const echarts = require("echarts/lib/echarts");
	require("echarts/lib/chart/pie");
	require("echarts/lib/component/tooltip");
	require("echarts/lib/component/title");
	require("echarts/lib/component/dataZoom");
	require("echarts/lib/component/legend");
	import {
		getYear
	} from "@/utils/getDate";
	export default {
		name: "Operate",
		props: {
			row: {
				type: Object,
				default: () => {},
			},
			info: {
				type: Object,
				default: () => {},
			},
		},
		data() {
			return {
				pickerOptions: {
					disabledDate(v) {
						return v.getTime() > new Date().getTime() - 86400000;
					},
				},
				loading: false,
				dateTime: "",
				table: {
					list: [],
					total: 0,
					currentPage: 1,
					pageSize: 10,
				},
				list: {},
				list1: {},
			};
		},
		created() {
			this.dateTime = getYear() + '';
		},
		mounted() {
			this.yearCreditBaseInfo();
			this.yearFocusDriverVo();
			this.yearGradeDistribution();
		},
		methods: {
			handleParentClick(v) {
				this.dateTime = v;
				this.table.currentPage = 1;
				this.table.total = 0;
				this.table.list = [];
				this.yearCreditBaseInfo();
				this.yearFocusDriverVo();
				this.yearGradeDistribution();
			},
			exportList() {
				this.loading = true;
				this.$axiosReq(
						"/archives/server/escort/web/lineCredit/yearLineExport",
						null, {
							date: this.dateTime + '-01',
							companyId: this.info.companyId,
							lineId: this.row.lineId,
						},
						"get"
					)
					.then((res) => {
						bytesToFile(res.data.bytes, res.data.fileName);
					})
					.finally((e) => {
						this.loading = false;
					});
			},
			yearCreditBaseInfo() {
			    let that_ = this;
			    this.$axiosReq(
			        "/archives/server/escort/web/lineCredit/yearCreditBaseInfo",
			        null,
			        {
			            date: this.dateTime + '-01',
						companyId: this.info.companyId,
						lineId: this.row.lineId,
			        },
			        "get"
			    ).then((res) => {
			        that_.list = res.data;
					if (res.data.driverNum) {
						this.$emit('childDepartNum', res.data.driverNum)
					} else {
						this.$emit('childDepartNum', 0)
					}
			    });
			},
			yearFocusDriverVo() {
			    let that_ = this;
			    this.$axiosReq(
			        "/archives/server/escort/web/lineCredit/yearFocusDriverVo",
			        null,
			        {
			            pageSize: this.table.pageSize,
			            currentPage: this.table.currentPage,
						date: this.dateTime + '-01',
			            companyId: this.info.companyId,
			            lineId: this.row.lineId,
			        },
			        "get"
			    ).then((res) => {
			        if (
			            res.data.content &&
			            res.data.content.length > 0
			        ) {
			            that_.table.list = res.data.content;
			            that_.table.total = Number(
			                res.data.totalElements
			            );
			        }
			    });
			},
			yearGradeDistribution() {
				let that_ = this;
				let arr = [];
				this.$axiosReq(
					"/archives/server/escort/web/lineCredit/yearGradeDistribution",
					null, {
						date: this.dateTime + '-01',
						companyId: this.info.companyId,
						lineId: this.row.lineId,
					},
					"get"
				).then((res) => {
					that_.list1 = res.data;
				});
			},
			changeTime(v) {
				this.$emit('childByValue', v);
				this.table.currentPage = 1;
				this.table.total = 0;
				this.table.list = [];
				this.yearCreditBaseInfo();
				this.yearFocusDriverVo();
				this.yearGradeDistribution();
			},
			handleSizeChange(pageSize) {
				this.table.pageSize = pageSize;
				this.table.currentPage = 1;
				this.yearFocusDriverVo();
			},
			handleCurrentChange(currentPage) {
				this.table.currentPage = currentPage;
				this.yearFocusDriverVo();
			},
			echartsMain(name, list) {
				let chartDom = document.getElementById("main");
				let myChart = echarts.init(chartDom);
				let option;
				let that_ = this;

				option = {
					legend: {
						orient: "vertical",
						right: 0,
						y: "center",
						icon: "circle",
						textStyle: {
							fontSize: 14,
							color: "#fff",
						},
						data: [
							"直接访问",
							"邮件营销",
							"联盟广告",
							"视频广告",
							"搜索引擎",
						],
					},
					color: ["#2788F9", "#00E8FF", "#00C6FF", "#3434F9", "#f2637b"],
					series: [{
						name: "信用评价等级",
						type: "pie",
						radius: ["60%", "90%"],
						avoidLabelOverlap: false,
						emphasis: {
							label: {
								show: true,
								fontSize: "18",
								fontWeight: "bold",
								color: "#fff",
								position: "center",
								formatter: function(params) {
									return (
										params.name +
										"\r\n" +
										params.value +
										"人"
									);
								},
							},
						},
						label: {
							normal: {
								show: false,
								fontSize: 18,
								fontWeight: "bold",
								position: "center",
							},
						},
						labelLine: {
							show: false,
						},
						data: [{
								value: 1048,
								name: "直接访问",
							},
							{
								value: 735,
								name: "邮件营销",
							},
							{
								value: 580,
								name: "联盟广告",
							},
							{
								value: 484,
								name: "视频广告",
							},
							{
								value: 300,
								name: "搜索引擎",
							},
						],
					}, ],
				};

				option && myChart.setOption(option);
			},
		},
	};
</script>

<style scoped>
	.col-00E0FD {
		color: #00e0fd;
	}

	.mgt-10 {
		margin-top: 10px;
	}

	.mgt-15 {
		margin-top: 15px;
	}

	.operateClassN {
		width: 100%;
		height: 100%;
	}

	.headerT {
		color: #42f3f9;
		font-size: 20px;
		padding-left: 10px;
		border-left: 3px solid #42f3f9;
		margin-top: 10px;
	}

	.rowCent {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		/* margin-top: 10px; */
		box-sizing: border-box;
	}

	.rowCentRl {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.rowAround {
		justify-content: space-around;
	}

	.navList {
		width: 16.13%;
		height: 210px;
		background-image: url(../bgAll.png);
		background-size: 100% 100%;
		padding: 20px;
		box-sizing: border-box;
	}

	.navListOne {
		width: 59px;
		height: 59px;
	}

	.navListOne img {
		width: 100%;
		height: 100%;
		display: block;
	}

	.summary {
		width: 100%;
		height: 173px;
		background-image: url(../bgAll.png);
		background-size: 100% 100%;
		padding: 10px;
		box-sizing: border-box;
	}

	.grade {
		width: 100%;
		height: 417px;
		background-image: url(../bgAll.png);
		background-size: 100% 100%;
		margin-top: 10px;
		padding: 10px;
		box-sizing: border-box;
	}

	.follow {
		width: 100%;
		height: 600px;
		background-image: url(../bgAll.png);
		background-size: 100% 100%;
		padding: 10px;
		box-sizing: border-box;
	}

	.titleBg {
		min-width: 100px;
		height: 25px;
		background-color: rgba(105, 246, 175, 0.12);
		padding: 0 25px 0 15px;
		border-radius: 10px 0 20px 0;
		line-height: 25px;
		font-size: 15px;
		font-weight: 600;
		display: inline-block;
	}

	.titleBox {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: #00ff33;
	}

	.textOver {
		height: 120px;
		padding-top: 10px;
		line-height: 25px;
		letter-spacing: 2px;
		overflow-y: scroll;
		overflow-x: hidden;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
	}

	.textOver::-webkit-scrollbar {
		display: none;
	}

	.positionText {
		position: absolute;
		left: 0;
		top: 35px;
		width: 100%;
		text-align: center;
		font-size: 22px;
	}

	.centTopBox {
		text-align: right;
	}
</style>
