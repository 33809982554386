<template>
	<div class="operateClassN">
		<div class="headerT">安全培训</div>
		<div class="rowCent rowCentRl mgt-10">
			<div style="width: 49%">
				<div class="rowCent rowCentRl">
					<div class="navList">
						<div class="rowCent rowCentRl">
							<div>课程完成率</div>
							<!-- <div>排名：<span class="col-00E0FD">4</span>/6</div> -->
						</div>
						<div style="
                                border-top: 2px solid rgba(255, 255, 255, 0.2);
                                margin-top: 20px;
                            "></div>
						<div style="height: 190px" class="rowCent rowCentRl">
							<div style="position: relative">
								<el-progress type="circle" :percentage="
                                        list.completeRatio
                                            ? list.completeRatio
                                            : 0
                                    "
								 :stroke-width="12" color="#984FF8" :show-text="false"></el-progress>
								<div class="positionText">
									{{
                                        list.completeRatio
                                            ? list.completeRatio
                                            : 0
                                    }}%
								</div>
							</div>
							<div>
								<div class="rowCent">
									<div>月同比</div>
									<div v-if="!list.yoy" style="padding: 0 10px">
										{{ list.yoy === 0 ? "0%" : "--" }}
									</div>
									<div v-if="list.yoy > 0" style="color: #15dd8f">
										<i class="el-icon-caret-top" style="padding: 0 5px"></i>{{ list.yoy }}%
									</div>
									<div v-if="list.yoy < 0" style="color: #f7333d">
										<i class="el-icon-caret-top" style="padding: 0 5px"></i>{{ list.yoy }}%
									</div>
								</div>
								<div style="margin-top: 20px"></div>
								<div class="rowCent">
									<div>月环比</div>
									<div v-if="!list.mom" style="padding: 0 10px">
										{{ list.mom === 0 ? "0%" : "--" }}
									</div>
									<div v-if="list.mom > 0" style="color: #15dd8f">
										<i class="el-icon-caret-top" style="padding: 0 5px"></i>{{ list.mom }}%
									</div>
									<div v-if="list.mom < 0" style="color: #f7333d">
										<i class="el-icon-caret-top" style="padding: 0 5px"></i>{{ list.mom }}%
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="navList">
						<div>高频错题占比</div>
						<div style="
                                border-top: 2px solid rgba(255, 255, 255, 0.2);
                                margin-top: 20px;
                            "></div>
						<div style="position: relative">
							<dv-water-level-pond :config="config" style="
                                    width: 140px;
                                    height: 140px;
                                    margin: 25px auto;
                                " />
							<div class="positionText1">
								<div style="
                                        font-size: 14px;
                                        padding-bottom: 10px;
                                    ">
									高频错题占比
								</div>
								<div>
									{{
                                        list.wrongTopicRatio
                                            ? list.wrongTopicRatio
                                            : 0
                                    }}%
								</div>
							</div>
						</div>
					</div>
					<div class="navList">
						<div>学习时长</div>
						<div style="
                                border-top: 2px solid rgba(255, 255, 255, 0.2);
                                margin-top: 20px;
                            "></div>
						<div style="height: 190px" class="rowCent rowJCent">
							<div class="timeBg"></div>
							<div class="col-00E0FD">
								<span style="font-size: 40px">{{
                                    list.studyTime ? list.studyTime : 0
                                }}</span>分钟
							</div>
						</div>
					</div>
				</div>
				<div class="leftD mgt-10">
					<div class="titleBg">
						<el-row type="flex" align="middle">
							<div class="titleBox"></div>
							<div class="pl-1">各部门课程完成率排行榜</div>
						</el-row>
					</div>
					<div class="ranking">
						<div class="rowCent" style="margin-top: 20px; position: relative" v-for="(item, index) in companyCompleteSos"
						 :key="index">
							<div style="width: 80%">
								<el-progress :text-inside="true" :stroke-width="18" :percentage="
                                        item.completeRatio
                                            ? item.completeRatio
                                            : 0
                                    "
								 color="#4FACFE"></el-progress>
								<div class="rankingT">{{ index + 1 }}</div>
							</div>
							<div style="
                                    width: 20%;
                                    padding-left: 5px;
                                    box-sizing: border-box;
                                ">
								{{ item.departName }}{{ item.completeRatio ? ' ' + item.completeRatio + '%' : ''}}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div style="width: 50.4%">
				<div class="rightT">
					<div class="titleBg">
						<el-row type="flex" align="middle">
							<div class="titleBox"></div>
							<div class="pl-1">培训内容概述</div>
						</el-row>
					</div>
					<div class="textOver col-00E0FD">
						{{ dateTime }}月安全培训内容包括：
						<span v-for="(item, index) in courseNames" :key="index">
							{{ index + 1 }}.{{ item }},
						</span>
						本月考核中有{{
                            list.underRightRatio ? list.underRightRatio : 0
                        }}道题正确率低于75%，
						建议针对相关知识点，加强日常培训与学习，以提升安全知识的掌握程度。
					</div>
				</div>
				<div class="rightD mgt-10">
					<div class="titleBg">
						<el-row type="flex" align="middle">
							<div class="titleBox"></div>
							<div class="pl-1">高频错题</div>
						</el-row>
					</div>
					<div class="tableClass">
						<div class="rowCent w100Bg mgt-10">
							<div class="w70">高频错题</div>
							<div class="w70">易错选项</div>
							<div class="w30">正确率</div>
						</div>
						<div class="tableOver" @mouseleave="changeArr">
							<div class="rowCent w100" v-for="(item,index) in table.list" :key="index" style="position: relative;">
								<div class="w70" @mouseover="mouseoverNow(index,item.topicTitle)">{{item.topicTitle}}</div>
								<div class="w70" @mouseover="mouseoverNow(index,item.highErrorOption)">{{item.highErrorOption}}</div>
								<div class="w30">{{item.accuracy}}%</div>
								<div v-if="testShowNum === index" @mouseout="changeArr" class="testShowClass">{{testShowTest}}</div>
							</div>
							<div v-if="table.list.length < 1" class="noData"></div>
						</div>
						<div style="height: 20px" />
						<el-pagination :current-page="table.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]" :page-size="table.pageSize"
						 layout="total, ->, prev, pager, next" :total="table.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	const echarts = require("echarts/lib/echarts");
	require("echarts/lib/chart/pie");
	require("echarts/lib/component/tooltip");
	require("echarts/lib/component/title");
	require("echarts/lib/component/dataZoom");
	export default {
		name: "Operate",
		props: {
			row: {
				type: Object,
				default: () => {},
			},
			info: {
				type: Object,
				default: () => {},
			},
		},
		data() {
			return {
				loading: false,
				dateTime: "2021-10",
				table: {
					list: [],
					total: 0,
					currentPage: 1,
					pageSize: 10,
				},
				config: {
					data: [0],
					shape: "round",
					colors: ["#C30EF0", "#2B62E0", "#AC4BFC"],
					waveNum: 1,
					waveHeight: 15,
					formatter: "",
					waveOpacity: 1,
				},
				list: {},
				courseNames: [],
				companyCompleteSos: [],
				testShowNum: null,
				testShowTest: ''
			};
		},
		created() {},
		mounted() {},
		methods: {
			changeArr() {
				let that = this;
				that.testShowNum = null;
				that.testShowTest = '';
			},
			mouseoverNow(num,val) {
				if (!val) {
					return false
				}
				this.testShowNum = num;
				this.testShowTest = val;
			},
			handleParentClick(v) {
				this.dateTime = v;
				this.table.currentPage = 1;
				this.table.total = 0;
				this.table.list = [];
				this.courseNames = [];
				this.companyCompleteSos = [];
				this.monthDepartCredit();
				this.wrongTopicList();
				this.companyCourse();
			},
			monthDepartCredit() {
				let that_ = this;
				this.$axiosReq('/course/server/escort/web/courseUser/credit/month', null, {
					date: this.dateTime,
					companyId: this.info.companyId,
				}, 'get').then(res => {
					that_.list = res.data;
					if (res.data.wrongTopicRatio) {
						that_.config.data = res.data.wrongTopicRatio;
					} else {
						that_.config.data = 0
					}
					if (res.data.courseNames && res.data.courseNames.length > 0) {
						that_.courseNames = res.data.courseNames;
					}
				})
			},
			wrongTopicList() {
				let that_ = this;
				this.$axiosReq('/course/server/escort/web/courseUser/credit/month/wrongTopic', null, {
					pageSize: this.table.pageSize,
					currentPage: this.table.currentPage,
					date: this.dateTime,
					companyId: this.info.companyId,
				}, 'get').then(res => {
					if (res.data && res.data.content.length > 0) {
						that_.table.list = res.data.content;
						that_.table.total = Number(res.data.totalElements);
					}
				})
			},
			companyCourse() {
				let that_ = this;
				this.$axiosReq(
					"/course/server/escort/web/courseUser/credit/month/companyCourse",
					null, {
						date: this.dateTime,
						companyId: this.row.companyId,
					},
					"get"
				).then((res) => {
					if (
						res.data &&
						res.data.length > 0
					) {
						that_.companyCompleteSos = res.data;
					}
				});
			},
			handleSizeChange(pageSize) {
				this.table.pageSize = pageSize;
				this.table.currentPage = 1;
				this.wrongTopicList();
			},
			handleCurrentChange(currentPage) {
				this.table.currentPage = currentPage;
				this.wrongTopicList();
			},
			echartsM(name, col, isShow) {
				var chartDom = document.getElementById(name);
				var myChart = echarts.init(chartDom);
				var option;

				option = {
					tooltip: {
						trigger: "axis",
						axisPointer: {
							type: "shadow",
							crossStyle: {
								color: "#fff",
							},
						},
					},
					xAxis: {
						name: "日期",
						type: "category",
						nameTextStyle: {
							color: "#ffffff",
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: "#ffffff",
							},
						},
						axisLine: {
							lineStyle: {
								color: "rgba(255,255,255,0.5)",
							},
						},
						data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
					},
					yAxis: {
						name: "数量",
						type: "value",
						nameTextStyle: {
							color: "#ffffff",
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: "#ffffff",
							},
						},
						axisLine: {
							lineStyle: {
								color: "rgba(255,255,255,0.5)",
							},
						},
					},
					series: [{
						data: [300, 932, 2000, 934, 3000, 600, 650],
						type: "line",
						stack: "Total",
						smooth: true,
						lineStyle: {
							width: 5,
							color: new echarts.graphic.LinearGradient(
								0,
								0,
								0,
								1,
								[{
										offset: 0,
										color: "#0092EB",
									},
									{
										offset: 1,
										color: "#00E8FF",
									},
								]
							),
						},
						areaStyle: {
							normal: {
								color: new echarts.graphic.LinearGradient(
									0,
									0,
									0,
									1,
									[{
											offset: 0,
											color: "#0092EB",
										},
										{
											offset: 1,
											color: "#00E8FF",
										},
									]
								),
							},
						},
						itemStyle: {
							normal: {
								label: {
									show: true,
								},
								color: "#ffffff",
							},
						},
					}, ],
				};

				option && myChart.setOption(option);
			},
		},
	};
</script>

<style scoped>
	.col-00E0FD {
		color: #00e0fd;
	}

	.col-42F3F9 {
		color: #42f3f9;
	}

	.operateClassN {
		width: 100%;
		height: 100%;
	}

	.headerT {
		color: #42f3f9;
		font-size: 20px;
		padding-left: 10px;
		border-left: 3px solid #42f3f9;
		margin-top: 10px;
	}

	.mgt-10 {
		margin-top: 10px;
	}

	.rowCent {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		box-sizing: border-box;
	}

	.rowCentRl {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.rowJCent {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.titleBg {
		min-width: 100px;
		height: 25px;
		background-color: rgba(105, 246, 175, 0.12);
		padding: 0 25px 0 15px;
		border-radius: 10px 0 20px 0;
		line-height: 25px;
		font-size: 15px;
		font-weight: 600;
		display: inline-block;
	}

	.titleBox {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: #00ff33;
	}

	.textOver {
		height: 150px;
		padding-top: 10px;
		line-height: 20px;
		letter-spacing: 3px;
		overflow-y: scroll;
		overflow-x: hidden;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
		box-sizing: border-box;
	}

	.textOver::-webkit-scrollbar {
		display: none;
	}

	.navList {
		width: 32.4%;
		height: 275px;
		background-image: url(../bgAll.png);
		background-size: 100% 100%;
		padding: 20px;
		box-sizing: border-box;
	}

	.positionText {
		position: absolute;
		left: 0;
		top: 49px;
		width: 100%;
		text-align: center;
		font-size: 22px;
	}

	.positionText1 {
		position: absolute;
		left: 0;
		top: 40px;
		width: 100%;
		text-align: center;
		font-size: 22px;
	}

	.timeBg {
		width: 77px;
		height: 78px;
		background-image: url(../timeBg.png);
		background-size: 100% 100%;
		margin-right: 30px;
	}

	.leftD {
		width: 100%;
		height: 500px;
		background-image: url(../bgAll.png);
		background-size: 100% 100%;
		padding: 20px;
		box-sizing: border-box;
	}

	.rightT {
		width: 100%;
		height: 210px;
		background-image: url(../bgAll.png);
		background-size: 100% 100%;
		padding: 20px;
		box-sizing: border-box;
	}

	.rightD {
		width: 100%;
		height: 565px;
		background-image: url(../bgAll.png);
		background-size: 100% 100%;
		padding: 20px;
		box-sizing: border-box;
	}

	.w100 {
		width: 100%;
		text-align: center;
		height: 35px;
	}

	.w100Bg {
		width: 100%;
		text-align: center;
		height: 35px;
		background-color: rgba(0, 232, 255, 0.08);
	}

	.w70 {
		width: 40%;
		height: 35px;
		line-height: 35px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.w30 {
		width: 20%;
		height: 35px;
		line-height: 35px;
	}

	.tableOver {
		width: 100%;
		height: 360px;
		overflow-y: scroll;
		overflow-x: hidden;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
		box-sizing: border-box;
	}

	.tableOver::-webkit-scrollbar {
		display: none;
	}

	.ranking {
		width: 90%;
		height: 390px;
		overflow-y: scroll;
		overflow-x: hidden;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
		box-sizing: border-box;
		margin: 0 auto;
	}

	.ranking::-webkit-scrollbar {
		display: none;
	}

	.rankingT {
		width: 25px;
		height: 25px;
		border-radius: 50%;
		background-image: url(../rankingT.png);
		background-size: 100% 100%;
		position: absolute;
		left: 0;
		top: -4px;
		text-align: center;
		line-height: 25px;
		font-size: 12px;
	}

	.noData {
		width: 200px;
		height: 130px;
		background-image: url(../noData.png);
		background-size: 100% 100%;
		margin: 0 auto;
	}
	
	.testShowClass {
		position: absolute;
		left: 0;
		top: 0;
		background-color: #096A94;
		min-height: 35px;
		line-height: 35px;
		width: 100%;
		font-size: 10px;
		z-index: 999;
	}
</style>
