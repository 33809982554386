<template>
    <div class="operateClassN">
        <div class="headerT">驾驶行为&服务质量&考勤情况</div>
        <div class="rowCent rowCentRl mgt-10">
            <div style="width: 32%">
                <div class="rightD mgt-10">
                    <div class="titleBg">
                        <el-row type="flex" align="middle">
                            <div class="titleBox"></div>
                            <div class="pl-1">驾驶行为</div>
                        </el-row>
                    </div>
                    <div class="navList rowCent rowCentRl">
                        <div style="width: 50%">
                            <div class="col-00E0FD" style="font-size: 30px">
                                0次
                            </div>
                            <div class="mt-1">本月驾驶行为报警次数</div>
                        </div>
                        <div
                            style="
                                background-color: rgba(255, 255, 255, 0.1);
                                width: 1px;
                                height: 76px;
                            "
                        ></div>
                        <div>
                            <div class="rowCent">
                                <div>月同比</div>
                                <div style="padding: 0 10px">--</div>
                                <!-- <div v-if="!list.courseYoy" style="padding: 0 10px;">{{list.courseYoy === 0 ? '0%' : '--'}}</div> -->
                                <!-- <div v-if="list.courseYoy > 0" style="color: #15DD8F;"><i class="el-icon-caret-top" style="padding: 0 5px;"></i>{{list.courseYoy}}%</div>
								<div v-if="list.courseYoy < 0" style="color: #F7333D;"><i class="el-icon-caret-top" style="padding: 0 5px;"></i>{{list.courseYoy}}%</div> -->
                            </div>
                            <div style="margin-top: 20px"></div>
                            <div class="rowCent">
                                <div>月环比</div>
                                <div style="padding: 0 10px">--</div>
                                <!-- <div v-if="!list.courseYoy" style="padding: 0 10px;">{{list.courseYoy === 0 ? '0%' : '--'}}</div> -->
                                <!-- <div v-if="list.courseYoy > 0" style="color: #15DD8F;"><i class="el-icon-caret-top" style="padding: 0 5px;"></i>{{list.courseYoy}}%</div>
								<div v-if="list.courseYoy < 0" style="color: #F7333D;"><i class="el-icon-caret-top" style="padding: 0 5px;"></i>{{list.courseYoy}}%</div> -->
                            </div>
                        </div>
                    </div>
                    <div class="rowCent">
                        <div
                            id="main7"
                            style="
                                width: 280px;
                                height: 380px;
                                display: block;
                                margin: 0 auto;
                            "
                        ></div>
                    </div>
                </div>
            </div>
            <div style="width: 32%">
                <div class="rightD mgt-10">
                    <div class="titleBg">
                        <el-row type="flex" align="middle">
                            <div class="titleBox"></div>
                            <div class="pl-1">服务质量</div>
                        </el-row>
                    </div>
                    <div class="navList rowCent rowCentRl">
                        <div style="width: 50%">
                            <div class="col-00E0FD" style="font-size: 30px">
                                {{
                                    list.serviceQualityNum
                                        ? list.serviceQualityNum
                                        : 0
                                }}次
                            </div>
                            <div class="mt-1">本月服务质量投诉次数</div>
                        </div>
                        <div
                            style="
                                background-color: rgba(255, 255, 255, 0.1);
                                width: 1px;
                                height: 76px;
                            "
                        ></div>
                        <div>
                            <div class="rowCent">
                                <div>月同比</div>
                                <div
                                    v-if="!list.serviceQualityNumYoy"
                                    style="padding: 0 10px"
                                >
                                    {{
                                        list.serviceQualityNumYoy === 0
                                            ? "0%"
                                            : "--"
                                    }}
                                </div>
                                <div
                                    v-if="list.serviceQualityNumYoy > 0"
                                    style="color: #15dd8f"
                                >
                                    <i
                                        class="el-icon-caret-top"
                                        style="padding: 0 5px"
                                    ></i
                                    >{{ list.serviceQualityNumYoy }}%
                                </div>
                                <div
                                    v-if="list.serviceQualityNumYoy < 0"
                                    style="color: #f7333d"
                                >
                                    <i
                                        class="el-icon-caret-bottom"
                                        style="padding: 0 5px"
                                    ></i
                                    >{{ list.serviceQualityNumYoy }}%
                                </div>
                            </div>
                            <div style="margin-top: 20px"></div>
                            <div class="rowCent">
                                <div>月环比</div>
                                <div
                                    v-if="!list.serviceQualityNumMom"
                                    style="padding: 0 10px"
                                >
                                    {{
                                        list.serviceQualityNumMom === 0
                                            ? "0%"
                                            : "--"
                                    }}
                                </div>
                                <div
                                    v-if="list.serviceQualityNumMom > 0"
                                    style="color: #15dd8f"
                                >
                                    <i
                                        class="el-icon-caret-top"
                                        style="padding: 0 5px"
                                    ></i
                                    >{{ list.serviceQualityNumMom }}%
                                </div>
                                <div
                                    v-if="list.serviceQualityNumMom < 0"
                                    style="color: #f7333d"
                                >
                                    <i
                                        class="el-icon-caret-bottom"
                                        style="padding: 0 5px"
                                    ></i
                                    >{{ list.serviceQualityNumMom }}%
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="rowCent">
                        <div
                            id="main8"
                            style="
                                width: 280px;
                                height: 380px;
                                display: block;
                                margin: 0 auto;
                            "
                        ></div>
                    </div>
                </div>
            </div>
            <div style="width: 32%">
                <div class="rightD mgt-10">
                    <div class="titleBg">
                        <el-row type="flex" align="middle">
                            <div class="titleBox"></div>
                            <div class="pl-1">考勤情况</div>
                        </el-row>
                    </div>
                    <div class="navList rowCent rowCentRl">
                        <div style="width: 50%">
                            <div class="col-00E0FD" style="font-size: 30px">
                                {{
                                    list1.attendanceRatio
                                        ? list1.attendanceRatio
                                        : 0
                                }}%
                            </div>
                            <div class="mt-1">全勤率</div>
                        </div>
                        <div
                            style="
                                background-color: rgba(255, 255, 255, 0.1);
                                width: 1px;
                                height: 76px;
                            "
                        ></div>
                        <div>
                            <div class="rowCent">
                                <div>月同比</div>
                                <div v-if="!list1.yoy" style="padding: 0 10px">
                                    {{ list.yoy === 0 ? "0%" : "--" }}
                                </div>
                                <div
                                    v-if="list1.yoy > 0"
                                    style="color: #15dd8f"
                                >
                                    <i
                                        class="el-icon-caret-top"
                                        style="padding: 0 5px"
                                    ></i
                                    >{{ list1.yoy }}%
                                </div>
                                <div
                                    v-if="list1.yoy < 0"
                                    style="color: #f7333d"
                                >
                                    <i
                                        class="el-icon-caret-bottom"
                                        style="padding: 0 5px"
                                    ></i
                                    >{{ list1.yoy }}%
                                </div>
                            </div>
                            <div style="margin-top: 20px"></div>
                            <div class="rowCent">
                                <div>月环比</div>
                                <div v-if="!list1.mom" style="padding: 0 10px">
                                    {{ list.mom === 0 ? "0%" : "--" }}
                                </div>
                                <div
                                    v-if="list1.mom > 0"
                                    style="color: #15dd8f"
                                >
                                    <i
                                        class="el-icon-caret-top"
                                        style="padding: 0 5px"
                                    ></i
                                    >{{ list1.mom }}%
                                </div>
                                <div
                                    v-if="list1.mom < 0"
                                    style="color: #f7333d"
                                >
                                    <i
                                        class="el-icon-caret-bottom"
                                        style="padding: 0 5px"
                                    ></i
                                    >{{ list1.mom }}%
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="rowCent">
                        <div
                            id="main9"
                            style="
                                width: 280px;
                                height: 380px;
                                display: block;
                                margin: 0 auto;
                            "
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const echarts = require("echarts/lib/echarts");
require("echarts/lib/chart/pie");
require("echarts/lib/chart/line");
require("echarts/lib/component/tooltip");
require("echarts/lib/component/title");
require("echarts/lib/component/dataZoom");
require("echarts/lib/component/legend");
export default {
    name: "Operate",
    props: {
        row: {
            type: Object,
            default: () => {},
        },
        info: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            loading: false,
            dateTime: "",
            list: {},
            list1: {},
            list2: {},
        };
    },
    created() {},
    mounted() {
        // this.echartsMain('main7');
        // this.echartsMain('main8');
        // this.echartsMain('main9');
    },
    methods: {
        handleParentClick(v) {
            this.dateTime = v;
            this.getLineMonthlyServiceDetails();
            this.getDepartMonthWork();
        },
        getLineMonthlyServiceDetails() {
            let that_ = this;
            let arr = [];
            this.$axiosReq(
                "/archives/server/escort/web/serviceQuality/getLineMonthlyServiceDetails2",
                null,
                {
                    date: this.dateTime,
                    companyId: this.info.companyId,
                    lineId: this.row.lineId,
                },
                "get"
            ).then((res) => {
                // console.log(JSON.stringify(res))
                that_.list = res.data;
                if (res.data.ringDiagrams && res.data.ringDiagrams.length > 0) {
                    res.data.ringDiagrams.forEach(function (item) {
                        arr.push({
                            value: item.serviceQualityTypeNum,
                            name: item.serviceQualityTypeName,
                        });
                    });
                    this.echartsMain("main8", arr, "服务质量", "次");
                } else {
                    this.echartsMain("main8", [], "");
                }
            });
        },
        getDepartMonthWork() {
            let that_ = this;
            let arr = [];
            this.$axiosReq(
                "/archives/server/escort/web/attendance/credit/month/line",
                null,
                {
                    date: this.dateTime,
                    companyId: this.info.companyId,
                    id: this.row.lineId,
                },
                "get"
            ).then((res) => {
                // console.log(JSON.stringify(res))
                that_.list1 = res.data;
                if (res.data) {
                    arr.push({
                        value: res.data.attendanceNum
                            ? res.data.attendanceNum
                            : 0,
                        name: "全勤",
                    });
                    arr.push({
                        value: res.data.noAttendanceNum
                            ? res.data.noAttendanceNum
                            : 0,
                        name: "未全勤",
                    });
                    // arr.push({
                    //     value: res.data.withoutAttendance
                    //         ? res.data.withoutAttendance
                    //         : 0,
                    //     name: "无考勤",
                    // });
                    this.echartsMain("main9", arr, "考勤情况", "人");
                } else {
                    this.echartsMain("main9", [], "");
                }
            });
        },
        echartsMain(name, list, tname, unit) {
            let chartDom = document.getElementById(name);
            let myChart = echarts.init(chartDom);
            let option;

            option = {
                legend: {
                    // orient: 'vertical',
                    // right: 0,
                    // y: 'center',
                    show: true,
                    bottom: 0,
                    x: "center",
                    icon: "circle",
                    textStyle: {
                        fontSize: 14,
                        color: "#fff",
                    },
                    formatter: function (name) {
                        let target = "";
                        for (var i = 0, l = list.length; i < l; i++) {
                            if (list[i].name == name) {
                                target = "     " + list[i].value + unit;
                            }
                        }
                        return name + target;
                    },
                },
                color: ["#2788F9", "#00E8FF", "#02E98D", "#3434F9", "#f2637b"],
                series: [
                    {
                        name: tname,
                        type: "pie",
                        radius: ["55%", "70%"],
                        avoidLabelOverlap: false,
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: "18",
                                fontWeight: "bold",
                                color: "#fff",
                                position: "center",
                                formatter: function (params) {
                                    return (
                                        params.name +
                                        "\r\n" +
                                        params.value +
                                        unit
                                    );
                                },
                            },
                        },
                        label: {
                            normal: {
                                show: false,
                                fontSize: 18,
                                fontWeight: "bold",
                                position: "center",
                            },
                        },
                        labelLine: {
                            show: false,
                        },
                        data: list,
                    },
                ],
            };

            option && myChart.setOption(option);
        },
    },
};
</script>

<style scoped>
.col-00E0FD {
    color: #00e0fd;
}

.col-42F3F9 {
    color: #42f3f9;
}

.operateClassN {
    width: 100%;
    height: 100%;
}

.headerT {
    color: #42f3f9;
    font-size: 20px;
    padding-left: 10px;
    border-left: 3px solid #42f3f9;
    margin-top: 10px;
}

.mgt-10 {
    margin-top: 10px;
}

.rowCent {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    box-sizing: border-box;
}

.rowCentRl {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.rowJCent {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.titleBg {
    min-width: 100px;
    height: 25px;
    background-color: rgba(105, 246, 175, 0.12);
    padding: 0 25px 0 15px;
    border-radius: 10px 0 20px 0;
    line-height: 25px;
    font-size: 15px;
    font-weight: 600;
    display: inline-block;
}

.titleBox {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #00ff33;
}

.textOver {
    height: 150px;
    padding-top: 10px;
    line-height: 20px;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    box-sizing: border-box;
}

.textOver::-webkit-scrollbar {
    display: none;
}

.navList {
    width: 60%;
    height: 136px;
    background-image: url(../borderBg.png);
    background-size: 100% 100%;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    margin: 90px auto 30px;
}

.navList1 {
    width: 65%;
    height: 220px;
    background-image: url(../bgAll.png);
    background-size: 100% 100%;
    padding: 20px;
    box-sizing: border-box;
}

.positionText {
    position: absolute;
    left: 0;
    top: 49px;
    width: 100%;
    text-align: center;
    font-size: 22px;
}

.positionText1 {
    position: absolute;
    left: 0;
    top: 40px;
    width: 100%;
    text-align: center;
    font-size: 22px;
}

.timeBg {
    width: 77px;
    height: 78px;
    background-image: url(../timeBg.png);
    background-size: 100% 100%;
    margin-right: 30px;
}

.leftZ {
    width: 100%;
    height: 290px;
    background-image: url(../bgAll.png);
    background-size: 100% 100%;
    padding: 20px;
    box-sizing: border-box;
}

.leftD {
    width: 100%;
    height: 255px;
    background-image: url(../bgAll.png);
    background-size: 100% 100%;
    padding: 20px;
    box-sizing: border-box;
}

.rightT {
    width: 100%;
    height: 210px;
    background-image: url(../bgAll.png);
    background-size: 100% 100%;
    padding: 20px;
    box-sizing: border-box;
}

.rightD {
    width: 100%;
    height: 700px;
    background-image: url(../bgAll.png);
    background-size: 100% 100%;
    padding: 20px;
    box-sizing: border-box;
}

.w100 {
    width: 100%;
    text-align: center;
    height: 30px;
}

.w100Bg {
    width: 100%;
    text-align: center;
    height: 30px;
    background-color: rgba(0, 232, 255, 0.08);
}

.w70 {
    width: 60%;
    height: 30px;
    line-height: 30px;
}

.w30 {
    width: 20%;
    height: 30px;
    line-height: 30px;
}

.tableOver {
    width: 100%;
    height: 90px;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    box-sizing: border-box;
}

.tableOver::-webkit-scrollbar {
    display: none;
}

.testBox {
    padding-top: 10px;
    width: 100%;
    height: 155px;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    box-sizing: border-box;
}

.testBox::-webkit-scrollbar {
    display: none;
}

.testList {
    width: 386px;
    height: 27px;
    line-height: 27px;
    color: rgba(255, 255, 255, 0.2);
    border-radius: 14px;
    margin: 0 auto;
    text-align: center;
    padding: 0 10px;
    box-sizing: border-box;
}

.active {
    background-color: rgba(255, 255, 255, 0.05);
    color: #00e0fd;
    font-size: 18px;
}
</style>
