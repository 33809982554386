<template>
    <div class="operateClassN">
        <div class="headerT">考勤情况</div>
        <div class="rowCent rowCentRl mgt-10">
            <div style="width: 36%">
                <div class="rightD mgt-10">
                    <div class="titleBg">
                        <el-row type="flex" align="middle">
                            <div class="titleBox"></div>
                            <div class="pl-1">考勤情况</div>
                        </el-row>
                    </div>
                    <div class="navList rowCent rowCentRl">
                        <div style="width: 50%">
                            <div class="col-00E0FD" style="font-size: 30px">
                                {{
                                    list.attendanceRatio
                                        ? list.attendanceRatio
                                        : 0
                                }}%
                            </div>
                            <div class="mt-1">月均全勤率</div>
                        </div>
                        <div
                            style="
                                background-color: rgba(255, 255, 255, 0.1);
                                width: 1px;
                                height: 76px;
                            "
                        ></div>
                        <div>
                            <div class="rowCent">
                                <div>环比</div>
                                <div v-if="!list.yoy" style="padding: 0 10px">
                                    {{ list.yoy === 0 ? "0%" : "--" }}
                                </div>
                                <div v-if="list.yoy > 0" style="color: #15dd8f">
                                    <i
                                        class="el-icon-caret-top"
                                        style="padding: 0 5px"
                                    ></i
                                    >{{ list.yoy }}%
                                </div>
                                <div v-if="list.yoy < 0" style="color: #f7333d">
                                    <i
                                        class="el-icon-caret-bottom"
                                        style="padding: 0 5px"
                                    ></i
                                    >{{ list.yoy }}%
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="width: 62%">
                <div class="rightT mgt-10">
                    <div class="titleBg">
                        <el-row type="flex" align="middle">
                            <div class="titleBox"></div>
                            <div class="pl-1">驾驶员的考勤情况</div>
                        </el-row>
                    </div>
                    <div
                        id="main13"
                        style="
                            width: 1100px;
                            height: 310px;
                            display: block;
                            margin: 0 auto;
                        "
                    ></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const echarts = require("echarts/lib/echarts");
require("echarts/lib/chart/pie");
require("echarts/lib/chart/line");
require("echarts/lib/component/tooltip");
require("echarts/lib/component/title");
require("echarts/lib/component/dataZoom");
require("echarts/lib/component/legend");
export default {
    name: "Operate",
    props: {
        row: {
            type: Object,
            default: () => {},
        },
        info: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            loading: false,
            dateTime: "",
            table: {
                list: [],
                total: 0,
                currentPage: 1,
                pageSize: 10,
            },
            list: {},
        };
    },
    created() {},
    mounted() {},
    methods: {
        handleParentClick(v) {
            this.dateTime = v;
            this.getDepartYearAttendance();
        },
        getDepartYearAttendance() {
            let that_ = this;
            let arr = [];
            let arr1 = [];
            this.$axiosReq(
                "/archives/server/escort/web/attendance/credit/year/depart",
                null,
                {
                    date: this.dateTime + '-01',
                    companyId: this.info.companyId,
                    id: this.row.departId,
                },
                "get"
            ).then((res) => {
                // console.log(JSON.stringify(res))
                that_.list = res.data;
                if (res.data.sos && res.data.sos.length > 0) {
                    res.data.sos.forEach(function (item) {
                        arr.push(item.ratio);
                        arr1.push(item.month + "月");
                    });
                    that_.echartsM(arr, arr1);
                } else {
                    that_.echartsM(arr, arr1);
                }
            });
        },
        echartsM(list, xname) {
            var chartDom = document.getElementById("main13");
            var myChart = echarts.init(chartDom);
            var option;

            option = {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        crossStyle: {
                            color: "#fff",
                        },
                    },
                    formatter: function (params) {
                        let str =
                            params[0].name +
                            "<br />" +
                            params[0].marker +
                            params[0].data +
                            "%";
                        return str;
                    },
                },
                xAxis: {
                    name: "日期",
                    type: "category",
                    nameTextStyle: {
                        color: "#ffffff",
                    },
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: "#ffffff",
                        },
                    },
                    axisLine: {
                        lineStyle: {
                            color: "rgba(255,255,255,0.5)",
                        },
                    },
                    data: xname,
                },
                yAxis: {
                    name: "全勤率",
                    type: "value",
                    nameTextStyle: {
                        color: "#ffffff",
                    },
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: "#ffffff",
                        },
                    },
                    axisLine: {
                        lineStyle: {
                            color: "rgba(255,255,255,0.5)",
                        },
                    },
                },
                series: [
                    {
                        data: list,
                        type: "line",
                        stack: "Total",
                        smooth: true,
                        lineStyle: {
                            width: 5,
                            color: new echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [
                                    {
                                        offset: 0,
                                        color: "#0092EB",
                                    },
                                    {
                                        offset: 1,
                                        color: "#00E8FF",
                                    },
                                ]
                            ),
                        },
                        areaStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        {
                                            offset: 0,
                                            color: "#0092EB",
                                        },
                                        {
                                            offset: 1,
                                            color: "#00E8FF",
                                        },
                                    ]
                                ),
                            },
                        },
                        itemStyle: {
                            normal: {
                                label: {
                                    show: true,
                                },
                                color: "#ffffff",
                            },
                        },
                    },
                ],
            };

            option && myChart.setOption(option);
        },
    },
};
</script>

<style scoped>
.col-00E0FD {
    color: #00e0fd;
}

.col-42F3F9 {
    color: #42f3f9;
}

.operateClassN {
    width: 100%;
    height: 100%;
}

.headerT {
    color: #42f3f9;
    font-size: 20px;
    padding-left: 10px;
    border-left: 3px solid #42f3f9;
    margin-top: 10px;
}

.mgt-10 {
    margin-top: 10px;
}

.rowCent {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    box-sizing: border-box;
}

.rowCentRl {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.rowJCent {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.titleBg {
    min-width: 100px;
    height: 25px;
    background-color: rgba(105, 246, 175, 0.12);
    padding: 0 25px 0 15px;
    border-radius: 10px 0 20px 0;
    line-height: 25px;
    font-size: 15px;
    font-weight: 600;
    display: inline-block;
}

.titleBox {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #00ff33;
}

.textOver {
    height: 150px;
    padding-top: 10px;
    line-height: 20px;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    box-sizing: border-box;
}

.textOver::-webkit-scrollbar {
    display: none;
}

.navList {
    width: 60%;
    height: 136px;
    background-image: url(../borderBg.png);
    background-size: 100% 100%;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    margin: 90px auto 60px;
}

.navList1 {
    width: 65%;
    height: 220px;
    background-image: url(../bgAll.png);
    background-size: 100% 100%;
    padding: 20px;
    box-sizing: border-box;
}

.positionText {
    position: absolute;
    left: 0;
    top: 49px;
    width: 100%;
    text-align: center;
    font-size: 22px;
}

.positionText1 {
    position: absolute;
    left: 0;
    top: 40px;
    width: 100%;
    text-align: center;
    font-size: 22px;
}

.timeBg {
    width: 77px;
    height: 78px;
    background-image: url(../timeBg.png);
    background-size: 100% 100%;
    margin-right: 30px;
}

.leftZ {
    width: 100%;
    height: 290px;
    background-image: url(../bgAll.png);
    background-size: 100% 100%;
    padding: 20px;
    box-sizing: border-box;
}

.leftD {
    width: 100%;
    height: 255px;
    background-image: url(../bgAll.png);
    background-size: 100% 100%;
    padding: 20px;
    box-sizing: border-box;
}

.rightT {
    width: 100%;
    height: 370px;
    background-image: url(../bgAll.png);
    background-size: 100% 100%;
    padding: 20px;
    box-sizing: border-box;
}

.rightD {
    width: 100%;
    height: 370px;
    background-image: url(../bgAll.png);
    background-size: 100% 100%;
    padding: 20px;
    box-sizing: border-box;
}

.w100 {
    width: 100%;
    text-align: center;
    height: 30px;
}

.w100Bg {
    width: 100%;
    text-align: center;
    height: 30px;
    background-color: rgba(0, 232, 255, 0.08);
}

.w70 {
    width: 60%;
    height: 30px;
    line-height: 30px;
}

.w30 {
    width: 20%;
    height: 30px;
    line-height: 30px;
}

.tableOver {
    width: 100%;
    height: 90px;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    box-sizing: border-box;
}

.tableOver::-webkit-scrollbar {
    display: none;
}

.testBox {
    padding-top: 10px;
    width: 100%;
    height: 155px;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    box-sizing: border-box;
}

.testBox::-webkit-scrollbar {
    display: none;
}

.testList {
    width: 386px;
    height: 27px;
    line-height: 27px;
    color: rgba(255, 255, 255, 0.2);
    border-radius: 14px;
    margin: 0 auto;
    text-align: center;
    padding: 0 10px;
    box-sizing: border-box;
}

.active {
    background-color: rgba(255, 255, 255, 0.05);
    color: #00e0fd;
    font-size: 18px;
}
</style>
